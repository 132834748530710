import React from "react";
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme:any) => ({
  MarginBottom: {
    marginBottom: "24px",
  },
}));

function Alert(
  props: JSX.IntrinsicAttributes & import("@mui/material/Alert").AlertProps
) {
  const classes:any = useStyles();
  return (
    <MuiAlert
      elevation={6}
      severity={props.severity}
      variant="filled"
      action={props.action}
      {...props}
      className={classes.MarginBottom}
    />
  );
}

export default Alert;
