
let id: any = 0;

export const setActiveFormStep = (value: any) => {
  id = value;
}

export const getActiveFormStep = () => {
  return id;
}
