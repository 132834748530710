import React, { useEffect, useState } from "react";
import { Routes, Navigate, Route, BrowserRouter } from "react-router-dom";
import { Login } from "./containers/Auth/Login/Login";
import { Registration } from "./containers/Auth/Registration/Registration";
import { SessionContext } from "./context/session";
import { ApolloProvider } from "@apollo/client";
import ragqlClient from "./config/apolloclient";
import { ErrorHandler } from "./containers/ErrorHandler/ErrorHandler";
import { AuthRoutes } from "./AuthRoutes";
import { AdminRoutes } from "./AdminRoutes";
import styles from "./App.module.css";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import logout from "./containers/Auth/Logout/Logout";
import Cookies from "js-cookie";
import LogoutPage from "./containers/Auth/LogoutPage/LogoutPage";
import ThankYou from "./containers/ThankYou/ThankYou";
import Layout from "./components/UI/Layout/Layout";
import Tracker from '@openreplay/tracker/cjs';
import  OpenReplay  from '@openreplay/tracker';
import * as Sentry from "@sentry/react";

import { OR_ENABLE, OR_INGESTPOINT, OR_PROJECT_KEY } from "./config";
import { useErrorBoundary } from "react-error-boundary";

function App() {
  // const session = localStorage.getItem("session");
  const { showBoundary } = useErrorBoundary();
  const session = Cookies.getJSON("ob_session");
  const userdata = Cookies.getJSON("ob_user") || "";
  const user:any =
    typeof userdata === "object"
      ? userdata
      : userdata !== ""
      ? JSON.parse(userdata)
      : "";
  // const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [authenticated, setAuthenticated] = useState(session ? true : false);
  const values = {
    authenticated: authenticated,
    setAuthenticated: (value: any) => {
      setAuthenticated(value);
    },
  };
  const accessToken = session ? session : null;
  // const client = ragqlClient(accessToken);
  const client = authenticated ? ragqlClient(accessToken) : ragqlClient(null);

  if (accessToken) {
    setTimeout(function () {
      logout();
      window.location.replace("/logout");
    }, 3600000 * 4);
  }
  let routes;

  try{
  if (authenticated) {
    if (user?.data?.getUserDetails.edges[0].node.isSuperuser !== true) {
      // logout();
      // <Route path={"/ThankYou" } element={ThankYou} />
      routes = <div>{AuthRoutes}</div>;
    }
    if (user?.data?.getUserDetails.edges[0].node.isSuperuser === true) {
      // logout();
      // <Route path={"/ThankYou" } element={ThankYou} />
      routes = <div>{AdminRoutes}</div>;
    }
  } else if (!authenticated) {
    routes = (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<Registration />} />
        <Route path={"/logout"} element={<LogoutPage />} />
        {/* <Route path={"/ThankYou" } element={ThankYou} /> */}
        <Route path="/" Component={() => <Navigate to="/logout" />} />
      </Routes>
    );
  }
  }catch(error){
    showBoundary(error)
  }

  const tracker = new OpenReplay({
    projectKey: OR_PROJECT_KEY,
    ingestPoint: OR_INGESTPOINT,
    // __DISABLE_SECURE_MODE:true,        //for testing locally
    onStart: ({ sessionToken, sessionID }) => {
        console.log('inside onStart',sessionID,sessionToken);
        Sentry.setTag("openReplaySessionURL", tracker.getSessionURL());
      },
  });

  console.log("OR_ENABLE",OR_ENABLE)

    useEffect(()=>{
      console.log('inside effect')
      try{
        if(OR_ENABLE===true){
        console.log('Inside OR_ENABLE')
        if(user?.data?.getUserDetails?.edges[0]?.node?.username){
          console.log('userID ',user?.data?.getUserDetails?.edges[0]?.node?.username)
          tracker.start({
            userID:user?.data?.getUserDetails?.edges[0]?.node?.username,
          })
              .then(() => {
                console.log('tracker started successfully.');
              })
              .catch((error:any) => {
                Sentry.captureException(error)
                console.error('tracker failed to start:', error);
              });
        }else{
          console.log('user id not present')
        }
      }
      }catch(error){
        showBoundary(error)
      }
    },[user])

  return (
    <SessionContext.Provider value={values}>
      <ApolloProvider client={client}>
          {routes}
      </ApolloProvider>
    </SessionContext.Provider>
  );
}

export default App;
