import React, { useEffect, useState } from "react";
import { Typography, FormHelperText } from "@mui/material";
import styles from "./Registration.module.css";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button } from "../../../components/UI/Form/Button/Button";
import { Navigate, Link } from "react-router-dom";
import * as validations from "../../../common/validateRegex";
import * as routeConstant from "../../../common/RouteConstants";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { CompanyUser } from "../../../common/Roles";

export interface RegistrationProps {}

export const Registration: React.FC<RegistrationProps> = () => {
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [authMessage, setAuthMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userRole, setUserRole] = useState();
  const [ifSuccess, setIfSuccess] = useState(false);

  const handlePasswordChange = () => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPassword(event.target.value);
    setPasswordError(false);
  };

  const handleFirstNameChange = () => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
    setFirstNameError(false);
  };

  const handleLastNameChange = () => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLastName(event.target.value);
    setLastNameError(false);
  };

  const handleEmailChange = () => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmail(event.target.value);
    setEmailError(false);
  };

  const handlePhoneNumberChange = () => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPhoneNumber(event.target.value);
    setPhoneNumberError(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleInputErrors = () => {
    let foundErrors = false;
    if (!firstName) {
      setFirstNameError(true);
      foundErrors = true;
    } else if (firstName) {
      setFirstNameError(false);
    }
    if (!lastName) {
      setLastNameError(true);
      foundErrors = true;
    } else if (lastName) {
      setLastNameError(false);
    }
    if (!email || !validations.EMAIL_REGEX.test(email)) {
      setEmailError(true);
      foundErrors = true;
    } else if (email) {
      setEmailError(false);
    }
    if (!phoneNumber || !validations.PHONE_REGEX.test(phoneNumber)) {
      setPhoneNumberError(true);
      foundErrors = true;
    } else if (phoneNumber) {
      setPhoneNumberError(false);
    }
    if (!password || !validations.PASSWORD_REGEX.test(password)) {
      setPasswordError(true);
      foundErrors = true;
    } else if (password) {
      setPasswordError(false);
    }

    return foundErrors;
  };

  const insertIntoUser = () => {
    // if (userRole) {
    //   createUser({
    //     variables: {
    //       username: email.toLowerCase(),
    //       email: email.toLowerCase(),
    //       password: password,
    //       role: userRole,
    //       confirmed: true
    //     }
    //   })
    //     .then(userRes => {
    //       insertIntoContact(userRes);
    //     })
    //     .catch(err => {
    //       // setErrorMessage('We are unable to register, kindly contact your technical team.');
    //     });
    // }
  };

  const insertIntoContact = (
    // userRes: FetchResult<any, Record<string, any>, Record<string, any>>
  ) => {
    // createContact({
    //   variables: {
    //     name: firstName + " " + lastName,
    //     email: email.toLowerCase(),
    //     phone: phoneNumber,
    //     contact_type: "Individual",
    //     user_id: userRes.data.createUser.user.id
    //   }
    // })
    //   .then(conRes => {
    //     insertIntoIndividual(conRes);
    //   })
    //   .catch(err => {});
  };

  const insertIntoIndividual = (
    // conRes: FetchResult<any, Record<string, any>, Record<string, any>>
  ) => {
    // createIndividual({
    //   variables: {
    //     first_name: firstName,
    //     last_name: lastName,
    //     contact_id: conRes.data.createContact.contact.id
    //   }
    // })
    //   .then(res => {
    //     setIfSuccess(true); // redirect user to login page
    //   })
    //   .catch(err => {});
  };

  const handleSubmit = () => {
    if (!handleInputErrors()) {
      insertIntoUser();
    }
  };

  if (ifSuccess) {
    return (
      <Navigate
        to={{
          pathname: routeConstant.LOGIN_URL
        }}
      />
    );
  }

  return (
    <div className={styles.Container}>
      <Card className={styles.root}>
        <Grid item xs={12} className={styles.Registration}>
          <div className={styles.Box}>
            <div className={styles.RegistrationLogo}>CyberCompliance360</div>
            <div className={styles.BoxTitle}>
              <Typography variant="h3" classes={{ root: styles.TitleText }}>
                Register
              </Typography>
            </div>
            <div className={styles.CenterBox}>
              <div className={styles.Margin}>
                <FormControl variant="outlined">
                  <InputLabel classes={{ root: styles.FormLabel }}>
                    First Name*
                  </InputLabel>
                  <OutlinedInput
                    classes={{
                      root: styles.InputField,
                      notchedOutline: styles.InputField,
                      input: styles.Input
                    }}
                    data-testid="firstname"
                    error={firstNameError}
                    id="firstname"
                    label="First Name*"
                    type="text"
                    value={firstName}
                    onChange={handleFirstNameChange()}
                  />
                  {firstNameError ? (
                    <FormHelperText classes={{ root: styles.FormHelperText }}>
                      First Name required.
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </div>
              <div className={styles.Margin}>
                <FormControl variant="outlined">
                  <InputLabel classes={{ root: styles.FormLabel }}>
                    Last Name*
                  </InputLabel>
                  <OutlinedInput
                    classes={{
                      root: styles.InputField,
                      notchedOutline: styles.InputField,
                      input: styles.Input
                    }}
                    data-testid="lastname"
                    error={lastNameError}
                    id="lastname"
                    label="Last Name*"
                    type="text"
                    value={lastName}
                    onChange={handleLastNameChange()}
                  />
                  {lastNameError ? (
                    <FormHelperText classes={{ root: styles.FormHelperText }}>
                      Last Name required.
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </div>
              <div className={styles.Margin}>
                <FormControl variant="outlined">
                  <InputLabel classes={{ root: styles.FormLabel }}>
                    Email Address*
                  </InputLabel>
                  <OutlinedInput
                    classes={{
                      root: styles.InputField,
                      notchedOutline: styles.InputField,
                      input: styles.Input
                    }}
                    data-testid="email"
                    error={emailError}
                    id="email"
                    label="Email Address*"
                    type="text"
                    value={email}
                    onChange={handleEmailChange()}
                  />
                  {emailError ? (
                    <FormHelperText classes={{ root: styles.FormHelperText }}>
                      Please enter valid email address.
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </div>
              <div className={styles.Margin}>
                <FormControl variant="outlined">
                  <InputLabel classes={{ root: styles.FormLabel }}>
                    Password
                  </InputLabel>
                  <OutlinedInput
                    classes={{
                      root: styles.InputField,
                      notchedOutline: styles.InputField,
                      input: styles.Input
                    }}
                    data-testid="password"
                    error={passwordError}
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    value={password}
                    onChange={handlePasswordChange()}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <Visibility classes={{ root: styles.IconButton }} />
                          ) : (
                            <VisibilityOff
                              classes={{ root: styles.IconButton }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />

                  {passwordError ? (
                    <FormHelperText classes={{ root: styles.FormHelperText }}>
                      Password must contain Uppercase character,<br></br>{" "}
                      Special character(@*&=+), Digits(0-9) <br></br>and minimum
                      8 characters.
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </div>
              <div className={styles.Margin}>
                <FormControl variant="outlined">
                  <InputLabel classes={{ root: styles.FormLabel }}>
                    Phone number
                  </InputLabel>
                  <OutlinedInput
                    classes={{
                      root: styles.InputField,
                      notchedOutline: styles.InputField,
                      input: styles.Input
                    }}
                    error={phoneNumberError}
                    id="phone-number"
                    label="Your phone number"
                    type="integer"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange()}
                  />
                  {phoneNumberError ? (
                    <FormHelperText classes={{ root: styles.FormHelperText }}>
                      Please enter valid phone number.
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </div>
              {errorMessage &&
              !firstNameError &&
              !lastNameError &&
              !passwordError &&
              !phoneNumberError ? (
                <div className={styles.ErrorMessage}>{errorMessage}</div>
              ) : null}
              <div className={styles.Margin}>
                <Button
                  data-testid="registrationButton"
                  onClick={handleSubmit}
                  color="primary"
                  variant={"contained"}
                  className={styles.ContinueButton}
                >
                  Continue
                </Button>
              </div>
            </div>

            <div className={styles.Or}>
              <div className={styles.OrText}>OR</div>
            </div>
            <div className={styles.Link}>
              <Link to="/login">Login To CyberCompliance360</Link>
            </div>
          </div>
        </Grid>
      </Card>
    </div>
  );
};

export default Registration;
