import React, { useState, useEffect, forwardRef,useImperativeHandle } from "react";
import styles from "./Windows_Network.module.css";
import CssBaseLine from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import RaStepper from "../component/RaStepper/RaStepper";
import IconButton from "@mui/material/IconButton";
import Input from "../../../components/UI/Form/Input/Input";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { FormHelperText, makeStyles, createStyles } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { GET_TARGET } from "../../../graphql/queries/Target";
import { setRaStepper } from "../common/SetRaStepper";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import InputAdornment from "@mui/material/InputAdornment";
import { Button } from "../../../components/UI/Form/Button/Button";
import * as routeConstant from "../../../common/RouteConstants";
import * as msgConstant from "../../../common/MessageConstants";
import AlertBox from "../../../components/UI/AlertBox/AlertBox";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../../../components/UI/Alert/Alert";
import stepper from "../common/raStepperList.json";
import { GET_AVAILABLE_SERVER } from "../../../graphql/queries/Target";
import {
  CREATE_TARGET,
  UPDATE_TARGET,
  DELETE_TARGET,
} from "../../../graphql/mutations/Target";
import {
  SUCCESS,
  UPDATE,
  DELETE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../common/MessageConstants";
import { TEST_WINDOWS_CONNECTION } from "../../../graphql/mutations/VPNConnection";
import rerunstepper from "../common/raRerunStepperList.json";
import { setActiveFormStep } from "../../../services/Data";
import Tooltip from "@mui/material/Tooltip";
import Cookies from "js-cookie";
import logout from "../../Auth/Logout/Logout";
import {
  DOMAIN_VERIFY,
  IP_VERIFY,
} from "../../../graphql/mutations/DomainVerify";
import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { RA_TARGET_VPNTEST } from "../../../config";
import { sentry_error_catch } from "../../../common/sentry_error_catch";
import { useErrorBoundary } from "react-error-boundary";

export interface WindowsNetworkProps {
  updateConnection? :any;
}

export const Windows_Network: React.FC<any> = forwardRef((props:any,ref:any) => {
  const { showBoundary } = useErrorBoundary();
  const history = useNavigate();
  const location = useLocation();
  const TargetNameTimestamp = localStorage.getItem("TargetNameTimestamp")
    ? JSON.parse(localStorage.getItem("TargetNameTimestamp") || "")
    : null;
  const [param, setParam] = useState<any>(
    location?.state ? location.state[Object.keys(location.state)[0]] : null
  );
  const client = useApolloClient();
  const [ipRange, setIpRange] = useState<any>("");
  const [userName, setUserName] = useState<String>("");
  const [domainName, setDomainName] = useState<String>("");
  const [vpnUserName, setVpnUserName] = useState<String>("");
  const [password, setPassword] = useState<string>("");
  const [subnetMask, setSubnetMask] = useState<any>('');
  const [showPassword, setShowPassword] = useState(false);
  const [dialogBoxMsg, setDialogBoxMsg] = useState("");
  const [linuxDomain, setWindowsDomain] = useState(false);
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);
  const clientInfo =
    location?.state[Object.keys(location?.state)[0]]?.clientInfo;
  const [editDataId, setEditDataId] = useState<Number | null>();
  const [ipAddress, setIpAddress] = useState<any>("");
  const [connectionSuccess, SetConnectionSuccess] = useState(false);
  const partner = JSON.parse(localStorage.getItem("partnerData") || "{}");
  const ReRunTargetName = JSON.parse(
    localStorage.getItem("re-runTargetName") || "{}"
  );
  const targetId = JSON.parse(localStorage.getItem("targetId") || "{}");
  const VPNUsername = JSON.parse(localStorage.getItem("vpnUserName") || "{}");
  const VPNPassword = JSON.parse(localStorage.getItem("vpnPassword") || "{}");
  const [windowLoginCheckBox, setWindowLoginCheckBox] = useState(false);
  const [linuxLoginCheckBox, setLinuxLoginCheckBox] = useState(false);
  const [windowLoginToolTip,setWindowLoginToolTip] = useState(false);
  const [windowsCredential,setWindowsCredential] = useState(false);
  const [editWindowsIpRange,setEditWindowsIpRange] = useState(true);
  const [ipListOpen,setIpListOpen] = useState(false);
  // const WinTargetName = JSON.parse(localStorage.getItem("WinTargetName") || "{}");
  const WinTargetName = localStorage.getItem("WinTargetName")
    ? JSON.parse(localStorage.getItem("WinTargetName") || "")
    : null;
  const LinuxTargetName = localStorage.getItem("LinuxTargetName")
    ? JSON.parse(localStorage.getItem("LinuxTargetName") || "")
    : null;
  const name = localStorage.getItem("name")
    ? JSON.parse(localStorage.getItem("name") || "")
    : null;
  const [showBackdrop, setShowbackdrop] = useState(true);

  const partnerId = partner.partnerId.id;
  const [targetName, setTargetName] = useState<String>("");
  const clientId = clientInfo ? parseInt(clientInfo.clientId) : undefined;
  const [backdrop, setBackdrop] = useState(false);
  const [open, setOpen] = React.useState(false);
  const session = Cookies.getJSON("ob_session");
  const [windowsCertCheckBox, setWindowsCertCheckBox] = useState();

  if (param) {
    if (
      editDataId === null ||
      (editDataId === undefined && localStorage.getItem("targetId") !== "{")
    ) {
      setEditDataId(JSON.parse(localStorage.getItem("targetId") || "{}"));
    }
  }
  const [testWindowsConnection] = useMutation(TEST_WINDOWS_CONNECTION);

  const [isError, setIsError] = useState<any>({
    name: "",
    ipRange: "",
    userName: "",
    password: "",
    ipList:"",
    vpnUserName: "",
    vpnPassword: "",
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  const startDate = new Date();
  const [updateTarget] = useMutation(UPDATE_TARGET);
  const [deleteTarget] = useMutation(DELETE_TARGET);
  const [domainVerify] = useMutation(DOMAIN_VERIFY);
  const [IPVerify] = useMutation(IP_VERIFY);

  useEffect(() => {
    if (location?.state) {
      setParam(location?.state[Object.keys(location?.state)[0]]);
    }
  }, []);
  useEffect(() => {
    if (param) {
      setWindowsCertCheckBox(param.checkboxSelected);
      setWindowsCredential(props.windowLoginSelected);
      setLinuxLoginCheckBox(props.linuxLoginSelected);
    }
    if(param && param.targetInfo && param.targetInfo.subnetMask){
      setSubnetMask(param.targetInfo.subnetMask)
    }
  }, [param]);


  const checkValidation = () => {
    if (
      isError.name !== "" ||
      isError.ipRange !== "" ||
      isError.userName !== "" ||
      isError.password !== "" ||
      isError.vpnUserName !== "" ||
      isError.vpnPassword !== "" ||
      !ipRange ||
      !userName ||
      !password ||
      !connectionSuccess
    ) {
      return true;
    }
    return false;
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const {
    data: targetData,
    loading: targetLoading,
    error: targetError,
  } = useQuery(GET_TARGET, {
    variables: {
      targetName:
        param && param.editData
          ? targetName
            ? targetName
            : ReRunTargetName
          : ReRunTargetName
          ? ReRunTargetName
          : targetName,
    },
    onCompleted: (data: any) => {
      setShowbackdrop(false);
      if (data.getCredentialsDetails.edges[0]) {
        // setIpAddress(data.getCredentialsDetails.edges[0].node.winIpAddress);
        setIpAddress(JSON.parse(localStorage.getItem("WinIpAddress") || ""))
        setPassword(JSON.parse(localStorage.getItem("winPassword") || ""))
        setUserName(
          data.getCredentialsDetails.edges[0].node
            ? data.getCredentialsDetails.edges[0].node.winUsername
            : null
        );
        setDomainName(
          data.getCredentialsDetails.edges[0].node
            ? data.getCredentialsDetails.edges[0].node.winName
            : null
        );
      }
    },
    onError: (err:any) => {
      setShowbackdrop(false);
      sentry_error_catch(err,setBackdrop,setFormState)
      history(routeConstant.RA_REPORT_LISTING);
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    // setRaStepper(client, stepper.WindowsNetwork.name, stepper.WindowsNetwork.value, param);
    try {
      if (ReRunTargetName.includes("_windows")) {
        setActiveFormStep(1);
        let data = {
          LinuxNetwork:
            param && param.LinuxNetwork ? param.LinuxNetwork : false,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : true,
          editData: param && param.editData ? param.editData : false,
          clientInfo: param && param.clientInfo ? param.clientInfo : null,
          targetInfo: param && param.targetInfo ? param.targetInfo : null,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          targetName: ReRunTargetName ? ReRunTargetName : targetName,
        };
        setRaStepper(
          client,
          rerunstepper.LoginCredentail.name,
          rerunstepper.LoginCredentail.value,
          data
        );
      } else {
        let data = {
          LinuxNetwork:
            param && param.LinuxNetwork ? param.LinuxNetwork : false,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : true,
          editData: true,
          clientInfo: param && param.clientInfo ? param.clientInfo : null,
          targetInfo: param && param.targetInfo ? param.targetInfo : null,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          targetName: ReRunTargetName ? ReRunTargetName : targetName,
        };
        setRaStepper(
          client,
          stepper.LoginCredentail.name,
          stepper.LoginCredentail.value,
          data
        );
        setActiveFormStep(2);
      }
    } catch (err:any){
      let data = {
        LinuxNetwork: param && param.LinuxNetwork ? param.LinuxNetwork : false,
        windowsNetwork:
          param && param.windowsNetwork ? param.windowsNetwork : true,
        editData: true,
        clientInfo: param && param.clientInfo ? param.clientInfo : null,
        targetInfo: param && param.targetInfo ? param.targetInfo : null,
        editLinuxData: param.editLinuxData ? param.editLinuxData : false,
        editWindowsData: param.editWindowsData ? param.editWindowsData : false,
        targetName: ReRunTargetName ? ReRunTargetName : targetName,
      };
      setRaStepper(
        client,
        stepper.LoginCredentail.name,
        stepper.LoginCredentail.value,
        data
      );
      setActiveFormStep(2);
    }
    if (
      param != undefined &&
      param.editWindowsData &&
      param.editWindowsData === true
    ) {
      SetConnectionSuccess(true);
      setFormState((formState) => ({
        ...formState,
        isSuccess: true,
        isUpdate: false,
        isDelete: false,
        isFailed: false,
        errMessage: "Connection has been validated",
      }));
    }
  }, []);

  useEffect(() => {
    if (targetId && editDataId !== undefined) {
      setTargetName(WinTargetName ? WinTargetName : name);
      setIpRange(JSON.parse(localStorage.getItem("ipRange") || ""));
    }
  }, []);

  const handleOkay = () => {
    setWindowsDomain(true);
    setShowDialogBox(true);
    setDialogBoxMsg(msgConstant.WINDOWS_NETWORK_CREDENTIALS);
  };

  const handleSubmitDialogBox = () => {
    setBackdrop(true);
    handleAlertClose();
    if (Cookies.getJSON("ob_session")) {
      // if(!windowLoginCheckBox){
      //   onClickTestConnection();
      // }
      if (editDataId) {
        let input = {
          partner: partnerId,
          client: clientId,
          targetName: TargetNameTimestamp,
          host: ipRange,
          winName: domainName,
          winIpAddress: ipRange,
          winUsername: userName,
          winPassword: password,
          startDate: startDate,
          vpnUsername: VPNUsername,
          vpnPassword: VPNPassword,
          subnet:subnetMask,
          authCheckboxSelected: windowsCertCheckBox ? "Yes" : "No",
          user: parseInt(partner.userId["id"]),
        };
        let id = editDataId;
        updateTarget({
          variables: {
            input,
            id,
          },
        })
          .then((userRes) => {
            setBackdrop(false);
            // if(!windowLoginCheckBox){
            //   setFormState((formState) => ({
            //     ...formState,
            //     isSuccess: false,
            //     isUpdate: true,
            //     isDelete: false,
            //     isFailed: false,
            //     errMessage: "Windows Target Created Successfully! .",
            //   }));
            // }else{
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: true,
                isDelete: false,
                isFailed: false,
                errMessage: "Windows Credentials Updated Successfully .",
              }));
            // }
            setSubmitDisabled(false);
            setEditDataId(null);
            localStorage.setItem("WinIpAddress", JSON.stringify(ipAddress));
            localStorage.setItem("winTargetId", JSON.stringify(userRes.data.updateTarget.targetField.id));
            localStorage.setItem("winUsername", JSON.stringify(userName));
            localStorage.setItem("winPassword", JSON.stringify(password));
            localStorage.setItem(
              "WinTargetName",
              JSON.stringify(userRes.data.updateTarget.targetField.targetName)
            );
            setShowDialogBox(false);
            let data:any = {};
            // setTimeout(() => {
            if (connectionSuccess) {
              data = {
                LinuxNetwork:
                  param && param.LinuxNetwork ? param.LinuxNetwork : false,
                windowsNetwork:
                  param && param.windowsNetwork ? param.windowsNetwork : true,
                editData: param && param.editData ? param.editData : true,
                clientInfo: param && param.clientInfo ? param.clientInfo : null,
                targetInfo: param && param.targetInfo ? param.targetInfo : null,
                editLinuxData: param.editLinuxData
                  ? param.editLinuxData
                  : false,
                editWindowsData: param.editWindowsData
                  ? param.editWindowsData
                  : true,
                previousPage: param?.previousPage,
                checkboxSelected: windowsCertCheckBox,
                linuxLoginSelected: linuxLoginCheckBox,
                windowLoginSelected: windowsCredential,
                scanName : param.scanName ?  param.scanName : '',
                targetIpRange : param.targetIpRange ? param.targetIpRange : '',
                subnetMask : param.subnetMask ? param.subnetMask : '',
              };
            } else {
              data = {
                LinuxNetwork:
                  param && param.LinuxNetwork ? param.LinuxNetwork : false,
                windowsNetwork:
                  param && param.windowsNetwork ? param.windowsNetwork : true,
                editData: param && param.editData ? param.editData : false,
                clientInfo: param && param.clientInfo ? param.clientInfo : null,
                targetInfo: param && param.targetInfo ? param.targetInfo : null,
                editLinuxData: param.editLinuxData
                  ? param.editLinuxData
                  : false,
                editWindowsData: param.editWindowsData
                  ? param.editWindowsData
                  : false,
                previousPage: param?.previousPage,
                checkboxSelected: windowsCertCheckBox,
                windowLoginSelected: windowsCredential,
                linuxLoginSelected: linuxLoginCheckBox,
                scanName : param.scanName ?  param.scanName : '',
                targetIpRange : param.targetIpRange ? param.targetIpRange : '',
                subnetMask : param.subnetMask ? param.subnetMask : '',
              };
            }
            const windowsTargetDetails = {
              windowsUsername : userName ? userName : "",
              windowsPassword: password ? password : "",
              windowsValidateIP: ipAddress ? ipAddress : "",
              windowsIpRange: ipRange ? ipRange : "",
              windowsDomain: domainName ? domainName : ""
            }
            data["windowsTargetDetails"] = windowsTargetDetails
            props.sendDetails(data);
            // setRaStepper(
            //   client,
            //   stepper.ScanConfiguration.name,
            //   stepper.ScanConfiguration.value,
            //   data
            // );
            // try {
            //   if (ReRunTargetName && ReRunTargetName.includes("_windows")) {
            //     history(routeConstant.LINUX_NETWORK, { state: { data } });
            //   } else {
            //     history(routeConstant.TASK_DETAILS, { state: { data } });
            //   }
            // } catch {
            //   history(routeConstant.TASK_DETAILS, { state: { data } });
            // }
            // }, 1000);
          })
          .catch((err:any) => {
            setBackdrop(false);
            setShowDialogBox(false);
            setSubmitDisabled(true);
            let error = err.message;
            if (
              error.includes("duplicate key value violates unique constraint")
            ) {
              error = " Name already present.";
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: error,
              }));
            } else {
              sentry_error_catch(err,setBackdrop,setFormState)
            }
            
          });
      }
    } else {
      logout();
    }
  };
   useImperativeHandle(
    ref,
    () => ({
      handleSubmitDialogBox
    })
  );
  const handleSkip = () => {
    try {
      if (ReRunTargetName && ReRunTargetName.includes("_windows")) {
        let data = {
          LinuxNetwork:
            param && param.LinuxNetwork ? param.LinuxNetwork : false,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : true,
          editData: param && param.editData ? param.editData : false,
          clientInfo: param && param.clientInfo ? param.clientInfo : null,
          targetInfo: param && param.targetInfo ? param.targetInfo : null,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          targetName: ReRunTargetName ? ReRunTargetName : targetName,
          previousPage: param?.previousPage,
          checkboxSelected: windowsCertCheckBox,
          linuxLoginSelected: linuxLoginCheckBox,
          windowLoginSelected: windowLoginCheckBox,
        };
        // setRaStepper(
        //   client,
        //   rerunstepper.LinuxNetwork.name,
        //   rerunstepper.LinuxNetwork.value,
        //   data
        // );
        history(routeConstant.LINUX_NETWORK, { state: { data } });
      } else {
        // let data = { windowsNetwork: true, editData: true, clientInfo: param.clientInfo, targetInfo: param.targetInfo }
        let data = {
          LinuxNetwork:
            param && param.LinuxNetwork ? param.LinuxNetwork : false,
          windowsNetwork: true,
          editData: true,
          clientInfo: param && param.clientInfo ? param.clientInfo : null,
          targetInfo: param && param.targetInfo ? param.targetInfo : null,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          targetName: ReRunTargetName ? ReRunTargetName : targetName,
          previousPage: param?.previousPage,
          checkboxSelected: windowsCertCheckBox,
          linuxLoginSelected: linuxLoginCheckBox,
          windowLoginSelected: windowLoginCheckBox,
        };

        setRaStepper(
          client,
          stepper.ScanConfiguration.name,
          stepper.ScanConfiguration.value,
          data
        );
        history(routeConstant.TASK_DETAILS, { state: { data } });
      }
    } catch(err:any) {
      // let data = { windowsNetwork: true, editData: true, clientInfo: param.clientInfo, targetInfo: param.targetInfo }
      let data = {
        LinuxNetwork: param && param.LinuxNetwork ? param.LinuxNetwork : false,
        windowsNetwork: true,
        editData: true,
        clientInfo: param && param.clientInfo ? param.clientInfo : null,
        targetInfo: param && param.targetInfo ? param.targetInfo : null,
        editLinuxData: param.editLinuxData ? param.editLinuxData : false,
        editWindowsData: param.editWindowsData ? param.editWindowsData : false,
        targetName: ReRunTargetName ? ReRunTargetName : targetName,
        previousPage: param?.previousPage,
        checkboxSelected: windowsCertCheckBox,
        linuxLoginSelected: linuxLoginCheckBox,
        windowLoginSelected: windowLoginCheckBox,
      };

      setRaStepper(
        client,
        stepper.ScanConfiguration.name,
        stepper.ScanConfiguration.value,
        data
      );
      history(routeConstant.TASK_DETAILS, { state: { data } });
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleIpRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIpAddress(event.target.value);
    let value = event.target.value;
    let isErrIpRange = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      ipRange: isErrIpRange,
    }));
    // setSubmitDisabled(checkValidation);
    props.ChangeLoginSubmitDisabled(true);
  };
  const handleIpListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIpRange(event.target.value);
    let value = event.target.value;
    let isErrIpList = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      ipList: isErrIpList,
    }));
    setSubmitDisabled(checkValidation);
    props.ChangeLoginSubmitDisabled(true);
  }

  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
    let value = event.target.value;
    let isErrUserName = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      userName: isErrUserName,
    }));
    // setSubmitDisabled(checkValidation);
    props.ChangeLoginSubmitDisabled(true);
  };
  const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDomainName(event.target.value);
    let value = event.target.value;
    let isErrUserName = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      domainName: isErrUserName,
    }));
    // setSubmitDisabled(checkValidation);
    props.ChangeLoginSubmitDisabled(true);
  };

  const handleVpnUserNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setVpnUserName(event.target.value);
    let value = event.target.value;
    let isErrVpnUserName = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      vpnUserName: isErrVpnUserName,
    }));
    // setSubmitDisabled(checkValidation);
  };

  const submitAction = async () => {
    try{
    const windowLoginCheckBoxParameter = "No"
    if (
      (localStorage.getItem("runTargetName") != null &&
        targetData &&
        targetData != null) ||
      (targetData != undefined &&
        targetData.getCredentialsDetails &&
        targetData.getCredentialsDetails.edges &&
        targetData.getCredentialsDetails.edges.length > 0)
    ) {
      setBackdrop(true);

      const headerObj = {
        "Content-Type": "application/json",
        Authorization: "jwt" + " " + session,
      };

      let url;
      url =
        RA_TARGET_VPNTEST +
        "target/testwincredentails/?cid=" +
        clientId +
        "&host=" +
        ipRange +
        "&tname= " +
        targetName +
        "&vusername=" +
        VPNUsername +
        "&vpasswords=" +
        `${encodeURIComponent(VPNPassword)}` +
        "&whost=" +
        ipAddress +
        "&wusername=" +
        userName +
        "&wpassword=" +
        `${encodeURIComponent(password)}` +
        "&wname=" +
        domainName +
        "&credscheckbox=" + 
        windowLoginCheckBoxParameter +
        "&subnet=" +
        subnetMask +
        "&tid=" +
        targetData.getCredentialsDetails.edges[0].node.vatTarget.id;
      await fetch(url, {
        method: "GET",
        headers: headerObj,
        // body: JSON.stringify({ UserId: 0, Assessment_ID: id }),
      })
        .then((data) => data.json())
        .then((response) => {
          setBackdrop(false);
          if (response == "Authentication succeeded, connection successful") {
            SetConnectionSuccess(true);
            setSubmitDisabled(false);
            setFormState((formState) => ({
              ...formState,
              isSuccess: true,
              isUpdate: false,
              isDelete: false,
              isFailed: false,
              errMessage: msgConstant.TEST_CONNECTION,
            }));
            let connectionObj = {
              windows_test_connection : true,
            }
            props.updateConnection(connectionObj)
          } else if (response == "VPN is Connected,Please Disconnect") {
            SetConnectionSuccess(false);
            setSubmitDisabled(true);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage:
                "You are already connected with another VPN. Please disconnect then try again",
            }));
          } else if (
            response == "Authentication failed, please verify your credentials"
          ) {
            SetConnectionSuccess(false);
            setSubmitDisabled(true);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: msgConstant.VPN_AUTHENTICATION_FAILED,
            }));
          } else {
            SetConnectionSuccess(false);
            setSubmitDisabled(true);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: msgConstant.TEST_CONNECTION_FAILED,
            }));
          }
        })
        .catch((err:any) => {
          setSubmitDisabled(true);
          sentry_error_catch(err,setBackdrop,setFormState)
        });
    } else {
      setBackdrop(true);

      const headerObj = {
        "Content-Type": "application/json",
        Authorization: "jwt" + " " + session,
      };
      let url;
      url =
        RA_TARGET_VPNTEST +
        "target/testwincredentails/?cid=" +
        clientId +
        "&host=" +
        ipRange +
        "&tname= " +
        targetName +
        "&vusername=" +
        VPNUsername +
        "&vpasswords=" +
        `${encodeURIComponent(VPNPassword)}` +
        "&whost=" +
        ipAddress +
        "&wusername=" +
        userName +
        "&wpassword=" +
        `${encodeURIComponent(password)}` +
        "&wname=" +
        domainName +
        "&subnet=" +
        subnetMask +
        "&credscheckbox=" + 
        windowLoginCheckBoxParameter;
      await fetch(url, {
        method: "GET",
        headers: headerObj,
        // body: JSON.stringify({ UserId: 0, Assessment_ID: id }),
      })
        .then((data) => data.json())
        .then((response) => {
          setBackdrop(false);
          if (response == "Authentication succeeded, connection successful") {
            SetConnectionSuccess(true);
            setSubmitDisabled(false);
            setFormState((formState) => ({
              ...formState,
              isSuccess: true,
              isUpdate: false,
              isDelete: false,
              isFailed: false,
              errMessage: msgConstant.TEST_CONNECTION,
            }));
            let connectionObj = {
              windows_test_connection : true,
            }
            props.updateConnection(connectionObj)
          } else if (response == "VPN is Connected,Please Disconnect") {
            SetConnectionSuccess(false);
            setSubmitDisabled(true);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage:
                "You are already connected with another VPN. Please disconnect then try again",
            }));
          } else if (
            response == "Authentication failed, please verify your credentials"
          ) {
            SetConnectionSuccess(false);
            setSubmitDisabled(true);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: msgConstant.VPN_AUTHENTICATION_FAILED,
            }));
          } else if (response == "Openvpn File is invalid") {
            SetConnectionSuccess(false);
            setSubmitDisabled(true);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: msgConstant.VPN_FILE_INVALID,
            }));
          } else {
            SetConnectionSuccess(false);
            setSubmitDisabled(true);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: msgConstant.TEST_CONNECTION_FAILED,
            }));
          }
        })
        .catch((err:any) => {
          setSubmitDisabled(true);
          sentry_error_catch(err,setBackdrop,setFormState)
        });
    }}catch(error){
      showBoundary(error)
    }
  };
  const withOutWindowLoginVPNConnection = () => {
    const windowLoginCheckBoxParameter = !windowLoginCheckBox ? "Yes" : "No"
    let url = ""
    const headerObj = {
      "Content-Type": "application/json",
      Authorization: "jwt" + " " + session,
    };
    if (
      (localStorage.getItem("runTargetName") != null &&
        targetData &&
        targetData != null) ||
      (targetData != undefined &&
        targetData.getCredentialsDetails &&
        targetData.getCredentialsDetails.edges &&
        targetData.getCredentialsDetails.edges.length > 0)
    ) {          
      url =
        RA_TARGET_VPNTEST +
        "target/testwincredentails/?cid=" +
        clientId +
        "&tname= " +
        targetName +
        "&vusername=" +
        VPNUsername +
        "&vpasswords=" +
        `${encodeURIComponent(VPNPassword)}` +
        "&whost=" +
        ipAddress +
        "&wusername=" +
        !windowLoginCheckBox ? "" : userName +
        "&wpassword=" +
        `${!windowLoginCheckBox ? "" : encodeURIComponent(password)}` +
        "&wname=" +
        !windowLoginCheckBox ? "" : domainName +
        "&credscheckbox=" + 
        windowLoginCheckBoxParameter +
        "&tid=" +
        targetData.getCredentialsDetails.edges[0].node.vatTarget.id;
    }else{
      url =
      RA_TARGET_VPNTEST +
      "target/testwincredentails/?cid=" +
      clientId +
      "&tname= " +
      targetName +
      "&vusername=" +
      VPNUsername +
      "&vpasswords=" +
      `${encodeURIComponent(VPNPassword)}` +
      "&whost=" +
      ipAddress +
      "&wusername=" +
      !windowLoginCheckBox ? "" : userName +
      "&wpassword=" +
      `${!windowLoginCheckBox ? "" : encodeURIComponent(password)}` +
      "&wname=" +
      !windowLoginCheckBox ? "" : domainName +
      "&credscheckbox=" + 
      windowLoginCheckBoxParameter
    }
    fetch(url, {
      method: "GET",
      headers: headerObj,
    }).then(() => {
      SetConnectionSuccess(true);
    }).catch((err:any) => {
      sentry_error_catch(err,setBackdrop,setFormState)
      SetConnectionSuccess(false);
    })
  }

  const onClickTestConnection = () => {
    try{
    if (Cookies.getJSON("ob_session")) {
      if (handleInputErrors()) {
        handleAlertClose();
        let input = {
          host: ipAddress,
        };
        if (parseInt(ipAddress)) {
          IPVerify({
            variables: {
              input,
            },
          })
            .then((userRes) => {
              if (userRes.data.IPVerify.status === "Valid IP address") {
                // if(!windowLoginCheckBox){
                //   withOutWindowLoginVPNConnection()
                // }else{
                  submitAction();
                // }
              } else {
                setBackdrop(false);
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: false,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: true,
                  errMessage: " Please Enter Valid IP Address",
                }));
              }
            })
            .catch((err:any) => {
              sentry_error_catch(err,setBackdrop,setFormState)
            });
        } else {
          domainVerify({
            variables: {
              input,
            },
          })
            .then((userRes: any) => {
              if (
                userRes.data.domainVerify.status === "Domain name is registered"
              ) {
                // if(!windowLoginCheckBox){
                //   withOutWindowLoginVPNConnection()
                // }else{
                  submitAction();
                // }
              } else {
                setBackdrop(false);
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: false,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: true,
                  errMessage: " Please Enter Valid Domain Name",
                }));
              }
            })
            .catch((err: any) => {
              sentry_error_catch(err,setBackdrop,setFormState)
            });
        }
      } else {
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: msgConstant.REQUIRED_ALL_FIELD,
        }));
      }
    } else {
      logout();
    }}catch(error){
      showBoundary(error)
    }
  };

  const handleClose = () => {
    setShowDialogBox(false);
  };

  const handleBack = () => {
    if (Cookies.getJSON("ob_session")) {
      try {
        if (ReRunTargetName && ReRunTargetName.includes("_windows")) {
          let data = {
            LinuxNetwork:
              param && param.LinuxNetwork ? param.LinuxNetwork : false,
            windowsNetwork:
              param && param.windowsNetwork ? param.windowsNetwork : true,
            editData: true,
            clientInfo: param && param.clientInfo ? param.clientInfo : null,
            targetInfo: param && param.targetInfo ? param.targetInfo : null,
            editLinuxData: param.editLinuxData ? param.editLinuxData : false,
            editWindowsData: param.editWindowsData
              ? param.editWindowsData
              : false,
            targetName: ReRunTargetName ? ReRunTargetName : targetName,
            previousPage: param?.previousPage,
            checkboxSelected: windowsCertCheckBox,
            linuxLoginSelected: linuxLoginCheckBox,
            windowLoginSelected: windowLoginCheckBox,
          };
          setRaStepper(
            client,
            rerunstepper.Target.name,
            rerunstepper.Target.value,
            data
          );
          history(routeConstant.TARGET, { state: { data } });
        } else {
          let data = {
            editData: true,
            LinuxNetwork:
              param && param.LinuxNetwork ? param.LinuxNetwork : true,
            windowsNetwork:
              param && param.windowsNetwork ? param.windowsNetwork : false,
            clientInfo: param.clientInfo,
            targetInfo: param.targetInfo,
            editLinuxData: param.editLinuxData ? param.editLinuxData : false,
            editWindowsData: param.editWindowsData
              ? param.editWindowsData
              : false,
            previousPage: param?.previousPage,
            checkboxSelected: windowsCertCheckBox,
            linuxLoginSelected: linuxLoginCheckBox,
            windowLoginSelected: windowLoginCheckBox,
          };
          if (LinuxTargetName) {
            history(routeConstant.LINUX_NETWORK, { state: { data } });
          } else {
            history(routeConstant.TARGET, { state: { data } });
          }
        }
      } catch(err:any) {
        let data = {
          editData: true,
          LinuxNetwork: param && param.LinuxNetwork ? param.LinuxNetwork : true,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : false,
          clientInfo: param.clientInfo,
          targetInfo: param.targetInfo,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          previousPage: param?.previousPage,
          checkboxSelected: windowsCertCheckBox,
          linuxLoginSelected: linuxLoginCheckBox,
          windowLoginSelected: windowLoginCheckBox,
        };
        if (LinuxTargetName) {
          history(routeConstant.LINUX_NETWORK, { state: { data } });
        } else {
          history(routeConstant.TARGET, { state: { data } });
        }
      }
    } else {
      logout();
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    let value = event.target.value;
    let isErrPassword = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      password: isErrPassword,
    }));
  };
  const handleInputErrors = () => {
    let error = true;
    // if(windowLoginCheckBox){
    if (userName === "" || userName === null) {
      error = false;
      setIsError((isError: any) => ({
        ...isError,
        userName: "Required",
      }));
    }
    // }
    if (ipAddress === "" || ipAddress === null) {
      error = false;
      setIsError((error: any) => ({
        ...error,
        ipRange: "Required",
      }));
    }
    // if(windowLoginCheckBox){
    if (domainName === "" || domainName === null) {
      error = false;
      setIsError((error: any) => ({
        ...error,
        domainName: "Required",
      }));
    }
    // }
    // if(windowLoginCheckBox){
    if (password === "" || password === null) {
      error = false;
      setIsError((isError: any) => ({
        ...isError,
        password: "Required",
      }));
    }
    // }

    return error;
  };
  const handleToolTipClose = () => {
    setOpen(false);
  };

  const handleToolTipOpen = () => {
    setOpen(true);
  };

  // const theme = createMuiTheme({
  //   overrides: {
  //     MuiTooltip: {
  //       tooltip: {
  //         backgroundColor: "rgb(240, 102, 1, 0.8)",
  //         borderRadius: "12px",
  //         position: "relative",
  //         "&:before": {
  //           content: "' '",
  //           width: "0px",
  //           height: "0px",
  //           zIndex: 9999,
  //           position: "absolute",
  //         },
  //       },
  //       tooltipPlacementRight: {
  //         "&:before": {
  //           borderTop: "6px solid transparent",
  //           borderBottom: "6px solid transparent",
  //           borderRight: "6px solid rgba(240, 102, 1, 0.8)",
  //           left: "-6px",
  //           top: "45%",
  //         },
  //       },
  //       tooltipPlacementLeft: {
  //         "&:before": {
  //           borderTop: "6px solid transparent",
  //           borderBottom: "6px solid transparent",
  //           borderLeft: "6px solid rgba(240, 102, 1, 0.8)",
  //           right: "-6px",
  //           top: "45%",
  //         },
  //       },
  //       tooltipPlacementBottom: {
  //         "&:before": {
  //           borderLeft: "6px solid transparent",
  //           borderRight: "6px solid transparent",
  //           borderBottom: "6px solid rgba(240, 102, 1, 0.8)",
  //           left: "45%",
  //           top: "-6px",
  //         },
  //       },
  //       tooltipPlacementTop: {
  //         "&:before": {
  //           borderLeft: "6px solid transparent",
  //           borderRight: "6px solid transparent",
  //           borderTop: "6px solid rgba(240, 102, 1, 0.8)",
  //           left: "45%",
  //           bottom: "-6px",
  //         },
  //       },
  //     },
  //   },
  // });

  const handleCancel = () => {
    if (Cookies.getJSON("ob_session")) {
      let userData = JSON.parse(Cookies.getJSON("ob_user"));
      deleteTarget({
        variables: {
          id: Number(targetId),
          firstName: userData.data.getUserDetails.edges[0].node.firstName,
          lastName: userData.data.getUserDetails.edges[0].node.lastName,
        },
      })
        .then((res: any) => {
          let data = {};
          data = { refetchData: true, clientInfo: clientInfo };
          if (param.previousPage === "client") {
            history(routeConstant.CLIENT, { state: { data } });
            localStorage.removeItem("name");
            localStorage.removeItem("customClientUrl");
            localStorage.removeItem("targetId");
            localStorage.removeItem("ipRange");
            localStorage.removeItem("ipAddress");
            localStorage.removeItem("re-runTargetName");
            localStorage.removeItem("userName");
            localStorage.removeItem("password");
            localStorage.removeItem("vpnUserName");
            localStorage.removeItem("vpnPassword");
            localStorage.removeItem("vpnFilePath");
            localStorage.removeItem("WinTargetName");
            localStorage.removeItem("LinuxTargetName");
          } else {
            history(routeConstant.RA_REPORT_LISTING, { state: { data } });
            localStorage.removeItem("name");
            localStorage.removeItem("targetId");
            localStorage.removeItem("ipRange");
            localStorage.removeItem("ipAddress");
            localStorage.removeItem("re-runTargetName");
            localStorage.removeItem("userName");
            localStorage.removeItem("password");
            localStorage.removeItem("vpnUserName");
            localStorage.removeItem("vpnPassword");
            localStorage.removeItem("vpnFilePath");
            localStorage.removeItem("WinTargetName");
            localStorage.removeItem("LinuxTargetName");
          }
        })
        .catch((err:any) => {
          sentry_error_catch(err,setBackdrop,setFormState)
          let data = {};
          data = { refetchData: true, clientInfo: clientInfo };
          if (param.previousPage == "client") {
            history(routeConstant.CLIENT, { state: { data } });
            localStorage.removeItem("name");
            localStorage.removeItem("targetId");
            localStorage.removeItem("ipRange");
            localStorage.removeItem("ipAddress");
            localStorage.removeItem("re-runTargetName");
            localStorage.removeItem("userName");
            localStorage.removeItem("password");
            localStorage.removeItem("vpnUserName");
            localStorage.removeItem("vpnPassword");
            localStorage.removeItem("vpnFilePath");
            localStorage.removeItem("WinTargetName");
            localStorage.removeItem("LinuxTargetName");
          } else {
            history(routeConstant.RA_REPORT_LISTING, { state: { data } });
            localStorage.removeItem("name");
            localStorage.removeItem("targetId");
            localStorage.removeItem("ipRange");
            localStorage.removeItem("ipAddress");
            localStorage.removeItem("re-runTargetName");
            localStorage.removeItem("userName");
            localStorage.removeItem("password");
            localStorage.removeItem("vpnUserName");
            localStorage.removeItem("vpnPassword");
            localStorage.removeItem("vpnFilePath");
            localStorage.removeItem("WinTargetName");
            localStorage.removeItem("LinuxTargetName");
          }
        });
    } else {
      logout();
    }
  };
  const checkWindowsBoxChangeHandler = (event: any) => {
    // setWindowLoginCheckBox(event.target.checked);
    setWindowsCredential(event.target.checked);
    // if(event.target.checked === true){
    //   setUserName("");
    //   setPassword("");
    // }
  }

  return (
    <React.Fragment>
      {/* <CssBaseLine />
      <Typography component="h5" variant="h1">
        Advanced Vulnerability Assessment for{" "}
        {param !== undefined && param.clientInfo !== undefined
          ? param.clientInfo.name
          : null}
      </Typography>
      <RaStepper />*/}
      {backdrop || targetLoading ? <SimpleBackdrop /> : null}
      <Grid container spacing={3} className={styles.AlertWrap}>
        <Grid item xs={12}>
          {formState.isSuccess ? (
            <Alert
              severity="success"
              id="susscess-alert"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <strong>{formState.errMessage}</strong>
              {/* {SUCCESS} */}
            </Alert>
          ) : null}
          {formState.isUpdate ? (
            <Alert
              severity="success"
              id="success-alert2"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <strong>{formState.errMessage}</strong>
              {/* {SUCCESS} */}
            </Alert>
          ) : null}
          {formState.isFailed ? (
            <Alert
              severity="error"
              id="error-alert"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {FAILED}
              {formState.errMessage}
            </Alert>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <div style={{ paddingLeft: "7px" }}>
            {/* <FormControlLabel
              control={
                <Checkbox name="windows_login_check" checked={windowsCredential} color="primary" onChange={checkWindowsBoxChangeHandler} />
              }
              classes={{root:styles['MuiFormControlLabel-root']}}
              label="Windows Credential"
            />
            <span style={{cursor:"pointer"}}>
              <Tooltip
                open={windowLoginToolTip}
                onClose={() => {
                  setWindowLoginToolTip(false)
                }}
                placement="top"
                onOpen={() => {
                  setWindowLoginToolTip(true)
                }}
                title={
                  <React.Fragment>
                    <p>
                      <b>Note:</b>
                      <b>
                        {"Select the checkbox to enter data for Windows Scan."}
                      </b>{" "}
                    </p>
                  </React.Fragment>
                }
              >
                <ContactSupportIcon className={styles.CircleIcon} />
              </Tooltip>
            </span> */}
            <Typography component="h5" variant="h2">
              Windows Network
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            id="username"
            label="User Name"
            value={userName}
            onChange={handleUserNameChange}
            required
            error={isError.userName}
            helperText={isError.userName}
            disabled = {!windowsCredential}
          >
            User Name
          </Input>
        </Grid>
        <Grid item xs={12} md={6} className={styles.PasswordField}>
          <FormControl className={styles.TextField} variant="outlined">
            <InputLabel classes={{ root: styles.FormLabel }}>
              Password *
            </InputLabel>
            <OutlinedInput
              classes={{
                root: styles.InputField,
                notchedOutline: styles.InputField,
                focused: styles.InputField,
              }}
              type={showPassword ? "text" : "password"}
              id="password"
              label="Password *"
              disabled = {!windowsCredential}
              value={password}
              onChange={handlePasswordChange}
              required
              error={isError.password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    className={styles.PassField}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {isError.password ? (
              <FormHelperText
                error={isError.password}
                classes={{ root: styles.FormHelperText }}
              >
                Required
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label="Domain Name"
            id="domain-name"
            value={domainName}
            disabled = {!windowsCredential}
            onChange={handleDomainChange}
            required
            error={isError.domainName}
            helperText={isError.domainName}
          >
            Domain Name
          </Input>
        </Grid>
        <Grid item xs={12} md={6}>
          <span className={styles.IPTooltip}>
            {/* <ThemeProvider theme={theme}> */}
            <Tooltip
              open={open}
              onClose={handleToolTipClose}
              onOpen={handleToolTipOpen}
              placement="bottom-end"
              title={
                <React.Fragment>
                  <p>
                    <b>Please enter data in the below formats</b>{" "}
                  </p>
                  <b>{"Single IP Address"}</b>
                  <em>{"(e.g. 192.168.x.xx)"}</em>{" "}
                  <p>
                    <b>{" Multiple IP Address"}</b>{" "}
                    {"(e.g. 192.168.x.x,192.168.x.x)"}
                  </p>{" "}
                </React.Fragment>
              }
            >
              <Input
                type="text"
                label="Ip To Validate"
                id="ip-range"
                value={ipAddress}
                onChange={handleIpRangeChange}
                required
                error={isError.ipRange}
                disabled = {!windowsCredential}
                helperText={isError.ipRange}
              >
                Ip To Validate *
              </Input>
            </Tooltip>
            {/* </ThemeProvider> */}
          </span>
        </Grid>
        </Grid>
        <Grid container spacing={3} className={styles.containerSpacing}>
        <Grid item xs={12} md={6}>
          <span className={styles.IPTooltip}>
            {/* <ThemeProvider theme={theme}> */}
            <Tooltip
              className={styles.tooltip}
              open={ipListOpen}
              onClose={() => setIpListOpen(false)}
              onOpen={() => setIpListOpen(true)}
              placement="bottom-end"
              title={
                <React.Fragment>
                  <p>
                    <b>Enter IP Address only</b>{" "}
                  </p>
                  <b>{"Single IP Address"}</b>
                  <em>{"(e.g. 192.168.x.xx)"}</em>{" "}
                  <p>
                    <b>{" Multiple IP Address"}</b>{" "}
                    {"(e.g. 192.168.x.x,192.168.x.x)"}
                  </p>{" "}
                </React.Fragment>
              }
            >
              <Input
                type="text"
                label="IP List"
                id="ip-range"
                value={ipRange}
                onChange={handleIpListChange}
                required
                error={isError.ipList}
                helperText={isError.ipList}
                disabled = {editWindowsIpRange}
              >
                IP List
              </Input>
            </Tooltip>
            {/* </ThemeProvider> */}
          </span>
          </Grid>
          <Grid item xs={12} md={6} className={styles.removeItemSpacing}>
          <Button
            variant={"contained"}
            className={styles.borderLess}
            onClick={() => {
              setEditWindowsIpRange((editWindowsIpRange:any) => !editWindowsIpRange)
            }}
            id="edit-ip-btn"
            color="primary"
            data-testid="edit-ip-button"
          >
            <img
              className={styles.EditIcon}
              src={process.env.PUBLIC_URL + "/icons/svg-icon/Edit-icon.svg"}
              alt="edit icon"
            />
          </Button>
        </Grid>
        </Grid>
        <Grid container spacing={3} className={styles.containerSpacing}>
        <Grid item xs={12} className={styles.ActionButtons}>
          {/* <Button
            className={styles.borderLess}
            variant={"contained"}
            id="back-btn"
            onClick={handleBack}
            color="primary"
            data-testid="cancel-button"
          >
            back
          </Button>
          {param != undefined && !param.editWindowsData ? (
            <Button
              className={styles.borderLess}
              id="skip-btn"
              variant={"contained"}
              onClick={handleSkip}
              color="primary"
              data-testid="cancel-button"
            >
              skip
            </Button>
          ) : null} */}
          <Button
            type="button"
            id="test-connection-btn"
            color="primary"
            variant={"contained"}
            onClick={onClickTestConnection}
            // disabled={!windowLoginCheckBox}
          >
            {param != undefined && param.editWindowsData
              ? "Retry"
              : "Test Connection"}
          </Button>
          {/* <Button
            onClick={handleSubmitDialogBox}
            color="primary"
            id="next-btn"
            variant={"contained"}
            data-testid="ok-button"
            disabled={!windowLoginCheckBox ? windowLoginCheckBox || ipAddress == "" : !connectionSuccess}
          >
            next
          </Button>
          <Button
            className={styles.borderLess}
            variant={"contained"}
            id="cancel-btn"
            onClick={handleCancel}
            color="primary"
            data-testid="cancel-button"
          >
            cancel
          </Button> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
});
export default Windows_Network;
