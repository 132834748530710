import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles, createStyles } from '@mui/styles';
import styles from "./Backdrop.module.css";
import theme from "../../../../theme";

const useStyles = makeStyles(() =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#F06601",
    },
    backdroptext: {
      color: "#ffffff",
    },
  })
);

export default function SimpleBackdrop() {
  const classes = useStyles();
  const open = true;

  return (
    <Backdrop className={classes.backdrop} open={open}>
    {/* <Backdrop open={open}> */}
      <div className={styles.CenterItems}>
        <div className={styles.LoadingPadding}>
          <CircularProgress style={{ color: "#F06601" }} />
        </div>
        <div className={classes.backdroptext}>Loading...</div>
        {/* <div >Loading...</div> */}
      </div>
    </Backdrop>
  );
}
