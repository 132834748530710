import React from "react";
import styles from "./AlertBox.module.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { Button } from "../Form/Button/Button";

const AlertBox = (props: any) => {
  return (
    <Dialog
      open={props.open}
      aria-labelledby="form-dialog-title"
      disableEnforceFocus={props.disableEnforceFocus}
      disableEscapeKeyDown={props.disableEscapeKeyDown}
      classes={{
        container: styles.Alertbox,
        paper: styles.AlertboxPaper,
        scrollPaper: styles.ScrollPaper,
      }}
    >
      <DialogTitle id="form-dialog-title" className={styles.AlertTitle}>
        Warning!
        <Link color="primary" to={{ pathname: props.closeButtonPath }}>
          <IconButton
            id="close-button"
            aria-label="close"
            style={{
              position: "absolute",
              right: "12px",
              top: "8px",
            }}
            onClick={props.handleCancel}
          >
            <CloseIcon />
          </IconButton>
        </Link>
      </DialogTitle>
      <DialogContent className={styles.AlertBody}>
        <DialogContentText>{props.dialogBoxMsg}</DialogContentText>
      </DialogContent>
      <DialogActions  className={styles.AlertActions} >
        {props.buttonName !== "" ? (
          <Link color="primary" to={{ pathname: props.pathName }}>
            <Button
              id="okay-button"
              variant="contained"
              onClick={props.handleOkay}
              color="primary"
            >
              {props.buttonName}
            </Button>
          </Link>
        ) : null}
        <Link color="primary" to={{ pathname: props.closeButtonPath }}>
          <Button
            id="close-button"
            variant="contained"
            color="primary"
            onClick={props.handleClose}
          >
            {props.CloseButtonName}
          </Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
};

export default AlertBox;
