import { gql } from "@apollo/client";

export const GET_DELETE_STATUS_PARTNER = gql`
mutation DeletePartnerAdminPage($token:String){
    DeletePartnerAdminPage(token:$token){
      targetField{
        partnerName
        pg360PartnerId
        activeStatus
        email
      }
    }
  }
`;
export const GET_DELETE_STATUS_CLIENT = gql`
mutation DeleteClientAdminPage($token:String){
    DeleteClientAdminPage(token:$token){
      targetField{
        partnerName
        clientName
        pg360ClientId
        pg360PartnerId
        activeStatus
        email
      }
    }
  }
`;
export const GET_DELETE_STATUS_USER =  gql`
mutation DeleteUserAdminPage($token:String){
    DeleteUserAdminPage(token:$token){
      targetField{
        firstName
        lastName
        pgUserRole
        pg360UserId
        partnerName
        pg360PartnerId
        activeStatus
        emailId
        disableTransactionalMail
      }
    }
  }
`;
export const DELETE_PARTNER =  gql`
mutation ($pg360PartnerId:Int) {
  deletePartner(pg360PartnerId:$pg360PartnerId){
    status
  }  
}
`
export const DELETE_PARTNER_USER =  gql`
mutation deleteUser($pg360UserId: Int!){
  deleteUser(pg360UserId: $pg360UserId) {
    status
  }
}
`
export const DELETE_CLIENT = gql`
mutation deleteClient($pg360ClientId: Int) {
  deleteClient(pg360ClientId: $pg360ClientId) {
    status
  }
}
`
export const GET_LATEST_TARGET = gql`
mutation PartnerStatsAdminPage($token:String){
  PartnerStatsAdminPage(token:$token){
    targetField{
      id
      targetName
      host
      publishedFlag
      startDate
      nmapFlag
      partnerName
      clientId
      clientName
      scanType
      targetStatus
      deletedStatus
      reportCreationDate
      mailSendFlag
    }
  }
}
`
export const GET_SUB_TARGET_DETAILS = gql`
mutation ViewTaskAdminPage($token:String,$targetid:Int){
  ViewTaskAdminPage(token:$token,targetid:$targetid){
    targetField{
      taskName
      startDate
      endDate
      scanStatus
      reportsFlag
      reportCreattionFlag
      spiderScanStatus
      activeScanStatus
      resultCreation
    }
  }
}
`