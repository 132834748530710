import React, { useEffect, useState } from 'react';
import classes from "./ViewTargetDetails.module.css";
import * as routeConstants from '../../../common/RouteConstants';
import { Button } from "../../../components/UI/Form/Button/Button";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import Input from "../../../components/UI/Form/Input/Input";
import ClearIcon from "@mui/icons-material//Clear";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Cookies from "js-cookie";
import { GET_SUB_TARGET_DETAILS } from '../../../graphql/mutations/AdminUI';
import * as Sentry from "@sentry/react";

const ViewTargetDetails: React.FC = (props:any) => {
  const history = useNavigate();
  const session = Cookies.getJSON("ob_session");
  const location: any = useLocation();
  const [param, setParam] = useState<any>(location.state[Object.keys(location.state)[0]]);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const timeFormat = "MM/DD/YYYY hh:mm a";
  const moment = require("moment-timezone");
  const contentType = "application/json";
  const [newData, setNewData] = useState<any>([]);
  const [token, setToken] = useState<any>("");
  const [subTargetFilter,setSubTargetFilter] = useState<any>("");
  const [searchData,setSearchData] = useState<any>([]);
  const [getSubTargetDetails,{data:getSubTargetDetail}] = useMutation(GET_SUB_TARGET_DETAILS, {
    onCompleted: (data:any) => {
      setShowBackDrop(false);
      createTableDataObject(data.ViewTaskAdminPage.targetField)
    },
    onError: (error:any) => {
      setShowBackDrop(false);
      Sentry.captureException(error)
      console.log("assesserror", error);
    }
  })

  const headerObj = {
    "Content-Type": contentType,
    Authorization: token,
  }

  useEffect(() => {
    setParam(location.state[Object.keys(location.state)[0]]);
  }, []);
  useEffect(() => {
    setShowBackDrop(true);
    console.log(param)
    getSubTargetDetails({
      variables: {
        token: session,
        targetid:param.id,
        sort: "id:desc"
      }
    })
  }, [param])

  // array to display the column.
  const VAColumn: any = [
    { title: "Target Name", key: "target_name" },
    { title: "Host", key: "host" },
    { title: "Task Name", key: "task_name" },
    { title: "Report Flag", key: "report_flag" },
    { title: "Report Creation Flag", key: "report_creation_flag" },
    {title: "Start Date", key:"start_date"},
    { title: "End Date", key: "end_date" },
  ];
  //this effect gets called after every initial render. 
  const PTColumn: any = [
    { title: "Target Name", key: "target_name" },
    { title: "Host", key: "host" },
    { title: "Spider Status", key: "spider_status" },
    { title: "Active Status", key: "active_status" },
    { title: "Result Creation", key: "result_creation" },
    {title: "Start Date", key:"start_date"},
    { title: "End Date", key: "end_date" },
  ];
  // function to handel click event to action button 
  const onRowClick:any = (rowData: any, val: string) => {
    
  }
  
  // data object creation to display in table
  const createTableDataObject = async (data: any,range?:boolean) => {
    let arr: any = [];
    console.log("data",data,"----");
    if (data && data.length !== 0) {
      if (data !== 0) {
        data.forEach((element: any, i: any) => {
            const obj: any = {};
            if(param.scan_type === "External" || param.scan_type === "Advanced" || param.scan_type === "CRIS-VT" ){
                obj["target_name"] = param.target_name;
                obj["host"] = param.host;
                obj["task_name"] = element.taskName ? element.taskName : "";
                obj["start_date"] = element.startDate ? moment(element.startDate).format(timeFormat ): "";
                obj["end_date"] =  element.endDate ? moment(element.endDate).format(timeFormat ): "";
                obj["report_flag"] = element.reportsFlag ? element.reportsFlag : "";
                obj['report_creation_flag'] = element.reportCreattionFlag ? element.reportCreattionFlag : "";
            }else{
                obj["target_name"] = param.target_name;
                obj["host"] = param.host;
                obj["spider_status"] = element.spiderScanStatus ? element.spiderScanStatus : "";
                obj["start_date"] = element.startDate ? moment(element.startDate).format(timeFormat ): "";
                obj["end_date"] =  element.endDate ? moment(element.endDate).format(timeFormat ): "";
                obj["active_status"] = element.activeScanStatus ? element.activeScanStatus : "";
                obj['result_creation'] = element.resultCreation ? element.resultCreation : "";
            }

            arr.push(obj);
        });
      }
    }
    console.log(arr)
    setNewData(arr);
    setSearchData(arr);
  }

  // to handle delete event.
  
 
  const backToList = () => {
    if(location.state.page === "LatestTarget"){
      history(routeConstants.ADMIN_TARGET);
    }else if(location.state.page === "DeleteTarget"){
      history(routeConstants.DELETE_TARGET);
    }
  }
  
  const enableBackdrop = () => {
    if (showBackDrop) {
      return true;
    } else {
      return false;
    }
  }
  const handleTargetFilterChange = (event:any) => {
    if(event.target.name === "filterTarget"){
      setSubTargetFilter(event.target.value)
    }
   }
   const searchResetHandler = (event:any) => {
     setSubTargetFilter("")
     setSearchData(newData)
   }
   const searchHandler = (event:any) => {
    if(subTargetFilter.trim().length > 0){
       const search:any = []
       getSubTargetDetail.ViewTaskAdminPage.targetField.forEach((element:any) => {
         if(element["task_name"].includes(subTargetFilter)){
           search.push(element)
         }
       });
       setSearchData(search)
    }
   }
   
  return <React.Fragment>
    <CssBaseline />
    {showBackDrop ? <SimpleBackdrop /> : null}
    <Typography component="h5" variant="h1">
      View Sub Target Details
    </Typography>
    <Grid container>
      {/* <Grid item xs={12} sm={10} className={classes.FilterWrap}>
        <span style={{ width: "230px" }}>
          <Grid item md={12} xs={12}>
            <Input
              // required={true}
              id="sub-target-id"
              type="search"
              name="filterSubTarget"
              label="Search Sub Target Name"
              variant="outlined"
              value={subTargetFilter}
              onChange={handleTargetFilterChange}
              error
            />
          </Grid>
        </span>
        <div className={classes.FilterWrap1}>
          <span>
            <Button id="search" onClick={searchHandler}>
              Search <SearchIcon />
            </Button>
          </span>
        </div>
        <div className={classes.FilterWrap2}>
          <span>
            <Button id="reset" color="secondary" onClick={searchResetHandler}>
              RESET <ClearIcon />
            </Button>
          </span>
        </div>
      </Grid> */}
      <Grid item xs={12} sm={12} className={classes.FilterAddWrap}>
        <div className={classes.ButtonGroup2}>
          <Button
            id="cancel-button"
            className={classes.BackToButton}
            variant={"contained"}
            onClick={backToList}
            color="secondary"
          >
            <img
              src={`${process.env.PUBLIC_URL}/icons/svg-icon/back-list.svg`}
              alt="user icon"
            />
            &nbsp; Back to List
          </Button>
        </div>
      </Grid>
    </Grid>
    <Grid>
      <Paper className={classes.paper}>
        <div className={classes.ScrollTable}>
          {searchData.length !== 0 ? (
            <MaterialTable
              data={searchData}
              columns={(param.scan_type === "External" || param.scan_type === "Advanced" || param.scan_type === "CRIS-VT") ? VAColumn : PTColumn}
              pageSize={11}
              onButtonClick={onRowClick}
           />
          ) : !enableBackdrop() ? (
            <Typography component="h5" variant="h3">
              You don't have any Sub Target for this Scan.
            </Typography>
          ) : null}
        </div>
      </Paper>
    </Grid>
  </React.Fragment>
}

export default ViewTargetDetails;
