import React from "react";
import styles from "./Layout.module.css";
import { Header } from "./Header/Header";
import { Footer } from "./Footer/Footer";

export const Layout = ({ children }: { children: JSX.Element })  => {
  return (
    <>
      <Header />
        <div className={styles.mainContainer}>
          <main className={styles.Main}>
            <div>{children}</div>
          </main>
        </div>
      <Footer />
    </>
  );
};

export default Layout;
