import React, { useState, useEffect } from "react";
import styles from "./PartnerForm.module.css";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { Button } from "../../../../components/UI/Form/Button/Button";
import CssBaseline from "@mui/material/CssBaseline";
import { AddEditForm } from "../../../../components/UI/AddEditForm/AddEditForm";
import Input from "../../../../components/UI/Form/Input/Input";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Alert from "../../../../components/UI/Alert/Alert";
import { useNavigate, useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import * as routeConstant from "../../../../common/RouteConstants";
import Paper from "@mui/material/Paper";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";
import MaterialTable from "../../../../components/UI/Table/MaterialTable";
import { Logout } from "../../../Auth/Logout/Logout";
import Loading from "../../../../components/UI/Layout/Loading/Loading";
import EditIcon from "@mui/icons-material/Edit";
import {
  SUCCESS,
  UPDATE,
  DELETE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../../common/MessageConstants";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_PARTNER,
  UPDATE_PARTNER,
} from "../../../../graphql/mutations/RaPartner";
import { GET_PARTNER } from "../../../../graphql/queries/Partners";
import * as validations from "../../../../common/validateRegex";
import { sentry_error_catch } from "../../../../common/sentry_error_catch";

export const PartnerForm: React.FC = (props: any) => {
  const history = useNavigate();
  const location = useLocation();
  const [partnerName, setPartnerName] = useState("");
  const [email, setEmail] = useState("");
  const [partnerID, setPartnerID] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [newData, setNewData] = useState<any>([]);
  const [param, setParam] = useState<any>(
    location?.state[Object.keys(location?.state)[0]]
  );
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const [isError, setIsError] = useState<any>({
    partnerName: "",
    address: "",
    email: "",
    phoneNumber: "",
  });

  const [submitDisabled, setSubmitDisabled] = useState(true);

  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  const [createPartner] = useMutation(CREATE_PARTNER);
  const [updatePartner] = useMutation(UPDATE_PARTNER);

  const {
    data: partnerData,
    error: errorPartner,
    loading: loadPartner,
    refetch: refetchOrg,
  } = useQuery(GET_PARTNER, {
    variables: {
      orderBy: "partner_name",
    },
    onCompleted: (data: any) => {
      createTableDataObject(data.getPartner.edges);
    },
    fetchPolicy: "cache-and-network",
    onError: (err:any) => {
      sentry_error_catch(err,undefined,setFormState)
      history(routeConstant.ADMIN_DASHBOARD, { state: { param } });
    },
  });

  useEffect(() => {
    setParam(location?.state[Object.keys(location?.state)[0]]);
    refetchOrg();
  }, []);

  useEffect(() => {
    if (param) {
      setPartnerID(param.partnerId ? param.partnerId : param.partner_id);
      setPartnerName(param.name ? param.name : param.partner);
      setEmail(param.email);
      setPhoneNumber(param.phone);
      setAddress(param.address);
    }
  }, [param]);

  useEffect(() => {
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
    if (formState.isSuccess === true || formState.isUpdate === true) {
      if (param != null) {
        param.formState = formState;
        // backToList();
      }
      if (param === null || param === undefined) {
        // param = [];
        // param.from = "Partner-form";
        // param.formState = formState;
        backToList();
      }
    }
  }, [formState]);

  if (loadPartner) {
    return <SimpleBackdrop />;
  }

  if (errorPartner) {
    const error = { message: "Error" };
    history(routeConstant.ADMIN_DASHBOARD, { state: { param } });
  }

  const createTableDataObject = (data: any) => {
    const arr: any = [];
    data.map((element: any, index: any) => {
      const obj: any = {};
      obj["id"] = element.node.id;
      obj["name"] = element.node.partnerName;
      obj["email"] = element.node.emailId;
      obj["phone"] = element.node.mobileNumber;
      arr.push(obj);
    });
    setNewData(arr);
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "partnerName") {
      setPartnerName(event.target.value);
      let err = event.target.value === "" || null ? "Required" : "";
      setIsError((error: any) => ({
        ...error,
        partnerName: err,
      }));
    }
    if (event.target.name === "email") {
      setEmail(event.target.value);
      let err = event.target.value === "" || null ? "Required" : "";
      setIsError((error: any) => ({
        ...error,
        email: err,
      }));
      if (!err) {
        var pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!validations.EMAIL_REGEX.test(event.target.value)) {
          let errors = "Please enter valid email address.";
          setIsError((error: any) => ({
            ...error,
            email: errors,
          }));
        }
      }
    }
    if (event.target.name === "phoneNumber") {
      setPhoneNumber(event.target.value);
    }
    if (event.target.name === "address") {
      setAddress(event.target.value);
      let err = event.target.value === "" || null ? "Required" : "";
      setIsError((error: any) => ({
        ...error,
        address: err,
      }));
    }
    setSubmitDisabled(checkValidation);
  };

  const checkValidation = () => {
    let validation = false;
    if (isError.partnerName !== "" && isError.address !== "") {
      validation = true;
    }
    return validation;
  };

  const handleInputErrors = () => {
    let foundErrors = false;
    if (!partnerName) {
      let err = "Required";
      setIsError((error: any) => ({
        ...error,
        partnerName: err,
      }));
      foundErrors = true;
    }
    if (!email) {
      let errors = "Required";
      setIsError((error: any) => ({
        ...error,
        email: errors,
      }));
      foundErrors = true;
    }
    if (email && !validations.EMAIL_REGEX.test(email)) {
      let errors = "Please enter valid email address.";
      setIsError((error: any) => ({
        ...error,
        email: errors,
      }));
      foundErrors = true;
    }

    return foundErrors;
  };
  const handleSubmit = () => {
    if (!handleInputErrors()) {
      if (props.location.pathname.includes("/partner-form/edit")) {
        updateIntoPartner();
      } else {
        insertIntoPartner();
      }
    }
  };

  const insertIntoPartner = () => {
    createPartner({
      variables: {
        input: {
          partnerName: partnerName,
          emailId: email,
          mobileNumber: phoneNumber,
          address: address,
        },
      },
    })
      .then((res: any) => {
        setFormState((formState) => ({
          ...formState,
          isSuccess: true,
          isUpdate: false,
          isDelete: false,
          isFailed: false,
          errMessage: " " + partnerName + " ",
        }));
        backToList();
      })
      .catch((err:any) => {
        // setLoader(false)
        let error = err.message;
        if (error.includes("duplicate key value violates unique constraint")) {
          error = " Partner Name Already Exists ";
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: error,
          }));
        } else {
          sentry_error_catch(err,undefined,setFormState)
        }
        
      });
  };

  const updateIntoPartner = () => {
    updatePartner({
      variables: {
        id: partnerID,
        partnerdata: {
          partnerName: partnerName,
          emailId: email,
          mobileNumber: phoneNumber,
          address: address,
        },
      },
    })
      .then((res: any) => {
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: true,
          isDelete: false,
          isFailed: false,
          errMessage: " " + partnerName + " ",
        }));
        backToList();
      })
      .catch((err:any) => {
        history(routeConstant.ADMIN_DASHBOARD, { state: { param } });
        let error = err.message;
        if (error.includes("duplicate key value violates unique constraint")) {
          error = " Partner Name Already Exists ";
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: error,
          }));
        } else {
          sentry_error_catch(err,undefined,setFormState)
        }
        
      });
  };

  const backToList = () => {
    history(routeConstant.ADD_PARTNER, { state: { param } });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        {/* Partner */}
        {param ? "Edit Partner " : "Add Partner"}
      </Typography>
      <AddEditForm
        handleOk={handleSubmit}
        // disabled={submitDisabled}
        handleCancel={backToList}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {formState.isFailed ? (
              <Alert
                id="error-alert"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {FAILED}
                {formState.errMessage}
              </Alert>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              id="partner-name"
              type="text"
              label="Partner Name*"
              name="partnerName"
              value={partnerName}
              onChange={handleChange}
              error={isError.partnerName}
              helperText={isError.partnerName}
            >
              Partner Name
            </Input>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              id="partner-email"
              type="text"
              label="Email*"
              name="email"
              value={email}
              onChange={handleChange}
              error={isError.email}
              helperText={isError.email}
            >
              E-mail
            </Input>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              id="partner-phone-number"
              type="text"
              label="Phone Number"
              name="phoneNumber"
              value={phoneNumber}
              onChange={handleChange}
              error={isError.phoneNumber}
              helperText={isError.phoneNumber}
            >
              Phone Number
            </Input>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              id="partner-address"
              type="text"
              label="Address"
              name="address"
              value={address}
              onChange={handleChange}
              error={isError.address}
              helperText={isError.address}
            >
              Address
            </Input>
          </Grid>
        </Grid>
      </AddEditForm>
    </React.Fragment>
  );
};

export default PartnerForm;
