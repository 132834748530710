import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import styles from './Loading.module.css';

export const Loading: React.FC = () => {
  return (
    <div className={styles.CenterItems} id="loader">
      <div className={styles.LoadingPadding}>
        <CircularProgress />
      </div>
      {/* <Typography variant="h5">Loading...</Typography> */}
    </div>
  );
};

export default Loading;
