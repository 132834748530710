import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Logout from "./containers/Auth/Logout/Logout";
import DeletePartner from './containers/AdminPanel/DeletePartner/DeletePartner';
import DeleteClient from './containers/AdminPanel/DeleteClient/DeleteClient';
import Partner from "./containers/AdminPanel/Partner/Partner/Partner";
import PartnerUser from "./containers/AdminPanel/PartnerUser/PartnerUser/PartnerUser";
import AdminDashboard from "./containers/AdminPanel/Dashboard/Dashboard";
import * as routeConstants from "./common/RouteConstants";
import PartnerUserForm from "./containers/AdminPanel/PartnerUser/PartnerUserForm/PartnerUserForm";
import PartnerForm from "./containers/AdminPanel/Partner/PartnerForm/PartnerForm";
import ReportStatus from "./containers/AdminPanel/ReportStatus/ReportStatus";
import Client from "./containers/Client/Client/Client";
import Target from "./containers/RiskAssessment/Target/Target";
import TaskDetails from "./containers/RiskAssessment/TaskDetails/TaskDetails";
import RaReportListing from "./containers/RiskAssessment/RaReportListing/RaReportListing";
import VpnStatus from "./containers/AdminPanel/VpnStatus/VpnStatus";
import { Prospects } from "./containers/Prospects/Prospects";
import Layout from "./components/UI/Layout/Layout";
import LatestTarget from "./containers/AdminPanel/LatestTarget/LatestTarget";
import ViewTargetDetails from "./containers/AdminPanel/ViewTargetDetails/ViewTargetDetails";
import DeleteTarget from "./containers/AdminPanel/DeleteTarget/DeleteTarget";

const defaultRedirect = () => <Navigate to="/admin-dashboard" />;
export const AdminRoutes = (
  <Routes>
    <Route
      path={routeConstants.PARTNER_USER_FORM}
      element={<Layout><PartnerUserForm /></Layout>}
    />
    <Route
      path={routeConstants.PARTNER_USER_FORM_ADD}
      element={<Layout><PartnerUserForm /></Layout>}
    />
    <Route
      path={routeConstants.PARTNER_USER_FORM_EDIT + ":id"}
      element={<Layout><PartnerUserForm /></Layout>}
    />
    <Route path={routeConstants.PARTNER_USER_FORM} element={<Layout><PartnerForm /></Layout>} />
    <Route path={routeConstants.PARTNER_FORM_ADD} element={<Layout><PartnerForm /></Layout>} />
    <Route
      path={routeConstants.PARTNER_FORM_EDIT + ":id"}
      element={<Layout><PartnerForm /></Layout>}
    />
    <Route
      path={routeConstants.ADMIN_REPORT_STATUS}
      element={<Layout><ReportStatus /></Layout>}
    />
    <Route path={routeConstants.ADMIN_VPN_STATUS} element={<Layout><VpnStatus /></Layout>} />

    <Route
      path={routeConstants.RA_REPORT_LISTING}
      element={<Layout><RaReportListing /></Layout>}
    />
    <Route path={routeConstants.ADMIN_PARTNER} element={<Layout><DeletePartner /></Layout>} />
    <Route path={routeConstants.ADMIN_CLIENT} element={<Layout><DeleteClient /></Layout>} />
    <Route path={routeConstants.ADMIN_DASHBOARD} element={<Layout><AdminDashboard /></Layout>} />
    <Route path={routeConstants.ADMIN_TARGET} element={<Layout><LatestTarget /></Layout>} />
    <Route path={routeConstants.DELETE_TARGET} element={<Layout><DeleteTarget /></Layout>} />
    <Route path={routeConstants.ADMIN_SUB_TARGET_DETAILS} element={<Layout><ViewTargetDetails /></Layout>} />
    <Route path={routeConstants.ADD_PARTNER} element={<Layout><Partner /></Layout>} />
    <Route path={routeConstants.CLIENT} element={<Layout><Client /></Layout>} />
    <Route path={routeConstants.TARGET} element={<Layout><Target /></Layout>} />
    <Route path={routeConstants.TASK_DETAILS} element={<Layout><TaskDetails /></Layout>} />
    <Route path={routeConstants.REPORT_STATUS} element={<Layout><ReportStatus /></Layout>} />
    <Route path={routeConstants.PARTNER_USER} element={<Layout><PartnerUser /></Layout>} />
    <Route path={routeConstants.VIEW_PROSPECT} element={<Layout><Prospects /></Layout>} />
    <Route path={routeConstants.LOGOUT_URL} element={<Logout />} />
    <Route path="/" Component={defaultRedirect} />
  </Routes>
);
