import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.css";
import Typography from "@mui/material/Typography";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import Grid from "@mui/material/Grid";
import { Button } from "../../../components/UI/Form/Button/Button";
import Paper from "@mui/material/Paper";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Input from "../../../components/UI/Form/Input/Input";
import DescriptionIcon from "@mui/icons-material/Description";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import DeleteIcon from "@mui/icons-material/Delete";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { GET_PARTNER } from "../../../graphql/queries/Partners";
import { GET_PARTNER_USERS, GET_PARTNER_ID_USER } from "../../../graphql/queries/PartnerUser";
import { useNavigate, useLocation } from "react-router-dom";
import * as routeConstant from "../../../common/RouteConstants";
import moment from "moment";
import { CompanyUser } from "../../../common/Roles";
import Loading from "../../../components/UI/Layout/Loading/Loading";
import logout from "../../../containers/Auth/Logout/Logout";
import { GET_REPORT_LISTING,GET_REPORT_LISTING_STATUS } from "../../../graphql/queries/ReportListing";
import Cookies from "js-cookie";
import { GET_ALL_PROSPECT_CLIENTS } from "../../../graphql/queries/Target";
import { GET_DELETE_STATUS_CLIENT, GET_DELETE_STATUS_PARTNER, GET_DELETE_STATUS_USER, GET_LATEST_TARGET } from "../../../graphql/mutations/AdminUI";
import * as Sentry from "@sentry/react";

export const Dashboard: React.FC = (props: any) => {
  const [partnerCount, setPartnerCount] = useState(0);
  const [partnerUserCount, setPartnerUserCount] = useState();
  const [clientCount, setClientCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [latestTargetCount,setLatestTargetCount] = useState(0);
  const [deletedTargetCount,setDeletedTargetCount] = useState(0);
  const [newData, setNewData] = useState<any>([]);
  const [prosData, setProsData] = useState([]);
  const session = Cookies.getJSON("ob_session");
  const history = useNavigate();
  const location = useLocation();

  function sentry_error_catch(err:any) {
    console.log("Unexcepted error",err)
    Sentry.captureException(err)
  }
  useEffect(()=>{
    // getPartnerUsersdata()
    getDeleteStatusPartner({
      variables:{
        token:session
      }
    })
    getDeleteStatusClient({
      variables:{
        token:session
      }
    })
    getDeleteStatusUser({
      variables:{
        token:session
      }
    })
    getLatestTarget({
      variables:{
        token:session
      }
    })
  },[])

  const [getDeleteStatusPartner, { error:partnerError , loading: loadPartner }] = useMutation(GET_DELETE_STATUS_PARTNER, {
    onCompleted: (data: any) => {
      setPartnerCount(data.DeletePartnerAdminPage?.targetField?.length ? data.DeletePartnerAdminPage?.targetField?.length : 0);
    },
    onError:(err:any) => {
      sentry_error_catch(err)
    },
  });
  const [getDeleteStatusClient, { error:clientError , loading: loadClient }] = useMutation(GET_DELETE_STATUS_CLIENT, {
    onCompleted: (data: any) => {
      setClientCount(data.DeleteClientAdminPage?.targetField?.length ? data.DeleteClientAdminPage.targetField.length : 0);
    },
    onError:(err:any) => {
      sentry_error_catch(err)
    },
  });
  const [getDeleteStatusUser, { error:userError , loading: loadUser }] = useMutation(GET_DELETE_STATUS_USER, {
    onCompleted: (data: any) => {
      setUserCount(data.DeleteUserAdminPage?.targetField?.length ? data.DeleteUserAdminPage.targetField.length : 0);
    },
    onError:(err:any) => {
      sentry_error_catch(err)
    },
  });
  const [getLatestTarget, { error:targetError , loading: loadTarget }] = useMutation(GET_LATEST_TARGET, {
    onCompleted: (data: any) => {
      let startDateOfWeekFromNow = moment().subtract(7, 'days').calendar();
      const weekDate = new Date(startDateOfWeekFromNow);
      if(data.PartnerStatsAdminPage.targetField && data.PartnerStatsAdminPage.targetField.length > 0){
        const rangeData = data.PartnerStatsAdminPage.targetField.filter((element:any) => {
          if(new Date(element.startDate) >= weekDate){
            return element
          }
        });
        setLatestTargetCount(rangeData.length);
        const deleteTarget = data.PartnerStatsAdminPage.targetField.filter((element:any) => element.deletedStatus === "True" );
        setDeletedTargetCount(deleteTarget.length);
      }
    },
    onError:(err:any) => {
      sentry_error_catch(err)
    },
  });
    // const{ error:partnerError , loading: loadPartner } = useQuery(GET_PARTNER, {
    //   variables: {
    //     orderBy: "-created_date",
    //   },
    //   onCompleted: (data: any) => {
    //     // createTableDataObject(data.getPartner.edges);
    //     setPartnerCount(data.getPartner.edges.length);
    //   },
    //   fetchPolicy: "cache-and-network",
    // });

    // const{   data: dataReportListing, loading: loadClient  } = useQuery(GET_REPORT_LISTING_STATUS, {
    //     fetchPolicy: "cache-and-network",
    //     onCompleted:(data)=>{
    //       createTableDataObject(data.getTargetStatus);
    //     },
    //     onError: error => {
    //       //  logout()
    //       // history(routeConstant.DASHBOARD);
    //     }
    //   });

    //   const{ data: ProspectusClientData, loading: ProspectusClientLoading  } = useQuery(GET_ALL_PROSPECT_CLIENTS, {
    //     fetchPolicy: "cache-and-network",
    //     onCompleted:(data)=>{
    //       createProspectTableDataObject(data.getCompanyData[0].data);
    //     },
    //     onError: error => {
    //       //  logout()
    //       // history(routeConstant.DASHBOARD);
    //     }
    //   });

  const [getPartnerUsersdata ,{ loading: loadPartnerUsers }] = useLazyQuery(
    GET_PARTNER_USERS,
    {
      onCompleted: (data: any) => {
        let arr: any = [];
        data.getPartnerUserDetails.edges.map((element: any, index: any) => {
          if(element.node.userType === 'Partner') {
            let obj: any = {};
            arr.push(obj);
          }
        });
        setPartnerUserCount(arr.length);
      },
      fetchPolicy: "cache-and-network",
      onError:(err:any)=>{
        sentry_error_catch(err)
        // logout()
      }
    }
  );

  //table
  const column = [
    { title: "Client", key: "client" },
     
    // { title: "Target", field: "target" },
    // { title: "Start Date", key: "startDate" },
    // { title: "Status", key: "status" },
     {
      key: "view",
      title: "",
      icon: <VisibilityIcon />,
      tooltip: "View",
      displayIcon: (rowData: any) => true,
    },
    // { title: "Published Status", field: "publishedFlag" }
  ];
  const ProspectColumn = [
    { title: "Prospect", key: "client" },
  ];

  const createTableDataObject = (data: any) => {
    const arr: any = [];
    data.map((element: any, index: any) => {
      const obj: any = {};
      if(element.status === "Generating Report" && element.publishedFlag === "Unpublished" && element.clientType === "Client") {
      obj["client"] = element.clientName;
      obj["target"] = element.targetName;
      obj["targetId"] = element.targetId;
      obj["clientId"] = element.clientId;
      obj["status"] = element.status;
      obj["publishedFlag"] = element.publishedFlag;
      obj["startDate"] = element.startDate;
      arr.push(obj);
      }
    });
    const pp = arr.filter( (ele :any , ind:any) => ind === arr.findIndex( (elem : any) => elem.client === ele.client ))
    setNewData(pp);

  };
  const createProspectTableDataObject = (data: any) => {
    const arr: any = [];
    data.map((element: any, index: any) => {
      const obj: any = {};
      obj["client"] = element.clientName;
      obj["target"] = element.targetName;
      obj["targetId"] = element.targetId;
      obj["clientId"] = element.clientId;
      obj["status"] = element.status;
      obj["publishedFlag"] = element.publishedFlag;
      obj["startDate"] = element.startDate;
      obj["external"] = element.external;
      obj["pentest"] = element.pentest;
      arr.push(obj);
    });
    setProsData(arr)

  };

  const partnerClickOpen = () => {
    let data: any = { "AddPartner": true };
    history(routeConstant.ADD_PARTNER, {state: {data}});
  };

  const ReportClickOpen = () => {
    history(routeConstant.ADMIN_REPORT_STATUS);
  };
  

  const onRowClick = (rowData: any, param: any) => {
    let data: any = { clientInfo: rowData };
    if (param === "RA") {
      history(routeConstant.RA_REPORT_LISTING, {state: {data}});
    }

    if (param === "edit") {
      history(routeConstant.PARTNER_FORM_EDIT + rowData.partnerId, {state:{rowData}});
    }
    if (param === "view") {
      let d = {
        "clientId" : rowData.clientId,
        "name": rowData.client
      }
      let data: any = { clientInfo: d, partnerId : "" };
      history(routeConstant.RA_REPORT_LISTING, {state: {data}});
    }
    if (param === "ViewExternal") {
      if (Cookies.getJSON("ob_session")) {
        let data = { clientInfo: rowData ,type: "External"};
        history(routeConstant.VIEW_PROSPECT, {state: {data}});
      } else {
        logout();
      }
    }
    if (param === "viewPenTest") {
      if (Cookies.getJSON("ob_session")) {
        let data = { clientInfo: rowData ,type: "Pentest"};
        history(routeConstant.VIEW_PROSPECT, {state: {data}});
      } else {
        logout();
      }
    }
  };

  // if (loadPartner || loadPartnerUsers || loadClient ) return <SimpleBackdrop />;
  // if (partnerError) {
  //   let error = { message: "Error" };
  //   return (
  //     <div className="error">
  //       Error!
  //       {logout()}
  //     </div>
  //   )
  // }
  const handleClick = (event:any) => {
    if(event === "deletePartner"){
      history(routeConstant.ADMIN_PARTNER)
    }else if(event === "deletePartnerUser"){
     history(routeConstant.PARTNER_USER)
    }else if(event === "deleteClient"){
     history(routeConstant.ADMIN_CLIENT)
   }else if(event === "viewLatestTarget"){
     history(routeConstant.ADMIN_TARGET)
    }else if(event === "viewDeletedTarget"){
      history(routeConstant.DELETE_TARGET)
     }
  }

  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        <Typography component="h1" variant="h1">
           Admin Screen
        </Typography>
        {loadPartner || loadUser || loadClient || loadTarget ? <SimpleBackdrop/>: null}

        <Grid container spacing={3} className={styles.GridBox}>
          <Grid item xs={12} sm={4} className={styles.FilterAddWrap}>
            <div className={styles.dash_block}>
              <div className={styles.dash_head}>Partners</div>
              <div className={styles.dash_count} onClick={(event) => handleClick("deletePartner")}>{partnerCount}</div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} className={styles.FilterAddWrap}>
            <div className={styles.dash_block}>
              <div className={styles.dash_head}>Client</div>
              <div className={styles.dash_count}  onClick={(event) => handleClick("deleteClient")}>{clientCount}</div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} className={styles.FilterAddWrap}>
            <div className={styles.dash_block}>
              <div className={styles.dash_head}>Partner User</div>
              <div className={styles.dash_count} onClick={(event) => handleClick("deletePartnerUser")}>{userCount}</div>
            </div>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
          <div className={styles.partnerButton}>
            <Button
              // className={styles.partnerinsideButton}
              id="partner-btn"
              color="primary"
              variant="contained"
              onClick={partnerClickOpen}
            >
              &nbsp; Partner
            </Button>
          </div>
          </Grid>
          <Grid item xs={12} sm={6}>
          <div className={styles.partnerButton}>
            <Button
              // className={styles.partnerinsideButton}
              id="report-status-btn"
              color="primary"
              variant="contained"
            onClick={ReportClickOpen}
            >
              &nbsp; Report Status
            </Button>
          </div>
          </Grid> */}
          <Grid item xs={12} sm={6} className={styles.FilterAddWrap}>
            <div className={styles.dash_block}>
              <div className={styles.dash_head}>Deleted Target</div>
              <div className={styles.dash_count} onClick={(event) => handleClick("viewDeletedTarget")}>{deletedTargetCount}</div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className={styles.FilterAddWrap}>
            <div className={styles.dash_block}>
              <div className={styles.dash_head}>Latest Target</div>
              <div className={styles.dash_count} onClick={(event) => handleClick("viewLatestTarget")}>{latestTargetCount}</div>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
      {/* <Grid className={styles.recentTypo} item xs={12}>
        <Typography component="h2" variant="h1" gutterBottom>
            Pending Reports of Clients
        </Typography>
        <br></br>

      </Grid>
      <Grid>
        <Paper className={styles.tableGrid}>
          <MaterialTable
            columns={column}
            data={newData}
            pageSize={25}
              onButtonClick={onRowClick}
            // actions={[
            //   {
            //     icon: () => <VisibilityIcon />,
            //     tooltip: "View",
            //     onClick: (event: any, rowData: any, oldData: any) => {
            //       onRowClick(event, rowData, oldData, 'View');
            //     },
            //   },
              // {
              //   icon: () => <img className={styles.EditIcon}
              //   src={
              //     process.env.PUBLIC_URL + "/icons/svg-icon/edit.svg"
              //   }
              //   alt="edit icon"
              // />,
              //   tooltip: "Edit",
              //   onClick: (event: any, rowData: any, oldData: any) => {
              //     onRowClick(event, rowData, oldData, "Edit");
              //   }
              // }
              // {
              //   icon: () => <DeleteIcon />,
              //   tooltip: "Delete",
              //   onClick: (event: any, rowData: any, oldData: any) => {
              //     onRowClick(event, rowData, oldData, 'Delete');
              //   },
              // },
            // ]}
            // options={{
            //   headerStyle: {
            //     backgroundColor: "#fef9f5",
            //     color: "#002F60"
            //   },
            //   actionsColumnIndex: -1,
            //   paging: false,
            //   sorting: true,
            //   search: false,
            //   filter: true,
            //   draggable: false
            // }}
          />
        </Paper>
      </Grid>
      <Grid className={styles.recentTypo} item xs={12}>
        <Typography component="h2" variant="h1" gutterBottom>
            Pending Reports of Prospects
        </Typography>
        <br></br>

      </Grid>
      <Grid>
        <Paper className={styles.tableGrid}>
          <MaterialTable
            columns={ProspectColumn}
            data={prosData}
            pageSize={25}
            // actions={[
              // {
              //   icon: () => <VisibilityIcon />,
              //   tooltip: "View",
              //   onClick: (event: any, rowData: any, oldData: any) => {
              //     onRowClick(event, rowData, oldData, 'View');
              //   },
              // },
              // {
              //   icon: () => <img className={styles.EditIcon}
              //   src={
              //     process.env.PUBLIC_URL + "/icons/svg-icon/edit.svg"
              //   }
              //   alt="edit icon"
              // />,
              //   tooltip: "Edit",
              //   onClick: (event: any, rowData: any, oldData: any) => {
              //     onRowClick(event, rowData, oldData, "Edit");
              //   }
              // }
              // {
              //   icon: () => <DeleteIcon />,
              //   tooltip: "Delete",
              //   onClick: (event: any, rowData: any, oldData: any) => {
              //     onRowClick(event, rowData, oldData, 'Delete');
              //   },
              // },
            //   (rowData: any) =>
            //   rowData.pentest 
            //   ? {
            //       icon: () => 
            //       // "OB360 Pen",
            //       <Typography component="h6" variant="h4">
            //       OB360 PEN TEST {rowData.external ? "|" : null}
            //   </Typography>,
            //       // tooltip: "Pen Test",
            //       onClick: (event: any, rowData: any, oldData: any) => {
            //         onRowClick(event, rowData, oldData, "viewPenTest");
            //       },
            //     }
            //   : null,
            //   (rowData: any) =>
            //   rowData.external
            //   ?  {
            //     icon: () => 
            //     // <div className={styles.Pen}>OB360 Vulnerability</div>,
            //   <Typography component="h6" variant="h4">
            //    OB360 VULNERABILITY TEST
            //   </Typography>,
            //     // icon: () => <AddCircleIcon className={styles.CircleIcon} />,
            //     // tooltip: "View External Vulnerability Test",
            //     onClick: (event: any, rowData: any, oldData: any) => {
            //       onRowClick(event, rowData, oldData, "ViewExternal");
            //     },
            //   }
            //   : null,

            // ]}
            // options={{
            //   headerStyle: {
            //     backgroundColor: "#fef9f5",
            //     color: "#002F60"
            //   },
            //   actionsColumnIndex: -1,
            //   paging: false,
            //   sorting: true,
            //   search: false,
            //   filter: true,
            //   draggable: false
            // }}
          />
        </Paper>
      </Grid> */}
    </div>
  );
};

export default Dashboard;
