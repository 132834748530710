import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import styles from "./ScanDetails.module.css";
import Cookies from "js-cookie";
import { GET_SCAN_DETAILS } from "../../../graphql/mutations/Target";
import { useMutation } from "@apollo/client";
import Input from "../../../components/UI/Form/Input/Input";
import ClearIcon from "@mui/icons-material//Clear";
import SearchIcon from "@mui/icons-material/Search";
import FormHelperText from '@mui/material/FormHelperText';
import { Button } from "../../../components/UI/Form/Button/Button";
import logout from "../../Auth/Logout/Logout";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Select from '@mui/material/Select';
import * as routeConstant from "../../../common/RouteConstants";
import * as msgConstant from "../../../common/MessageConstants";
import { useNavigate } from 'react-router-dom';
import Alert from "../../../components/UI/Alert/Alert";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import dayjs from "dayjs";
import { sentry_error_catch } from "../../../common/sentry_error_catch";
import { useErrorBoundary } from "react-error-boundary";


const ScanDetails: React.FC = (props: any) => {
  const { showBoundary } = useErrorBoundary();
  const [newData, setNewData] = useState([]);
  const history = useNavigate();
  const reactCsv = require("react-csv");
  const [showBackdrop, setShowBackdrop] = useState<Boolean>(false);
  const [clientNameFilter, setClientNameFilter] = useState("");
  const [scanType, setScanType] = useState("");
  const session = Cookies.getJSON("ob_session");
  let moment = require("moment-timezone");
  const [searchClient, setSearchClient] = useState<boolean>(false);
  const [searchData,setSearchData] = useState<any>([]);
  const partner = JSON.parse(localStorage.getItem("partnerData") || "{}");
  const [startDate,setStartDate] = useState<any>(null);
  const [endDate,setEndDate] = useState<any>(null);
  const [formState, setFormState] = useState({
    isDelete: false,
    isSuccess: false,
    isFailed: false,
    errMessage: "",
  })

  const [getScanDetails,{data:scanDetails}] = useMutation(GET_SCAN_DETAILS, {
    onCompleted: (data) => {
      setShowBackdrop(false);
      createTableDataObject(data.ScanDetails.targetField)
    },
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackdrop,setFormState)
      // logout();
    }
  });

  useEffect(() => {
    if (session) {
      setShowBackdrop(true);
      getScanDetails({
        variables: {
          partnerid: partner.partnerId.id,
          token: session
        }
      }).then(()=>{
      }).catch((err:any)=>{
        sentry_error_catch(err,setShowBackdrop,setFormState)
      })
    } else {
      logout();
    }
  }, [])

  const column = [
    { title: "Client Name", key: "clientName" },
    { title: "Scan Type", key: "scanType" },
    { title: "Target Name", key: "targetName" },
    { title: "Host", key: "host" },
    { title: "Creation Date", key: "creationDate" },
  ];

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isDelete: false,
      isSuccess: false,
      isFailed: false,
      errMessage: ""
    }))
  }

  const searchFilterChangeHandler = (event:any) => {
    setClientNameFilter(event.target.value);
  }

  const startDateChangeHandler = (event:any) => {
    if(event && event["$d"]){
      setStartDate(
        new Date(event["$d"])
      );
    }
  }

  const endDateChangeHandler = (event:any) => {
    if(event && event["$d"]){
      setEndDate(
        new Date(event["$d"])
      );
    }
  }

  const scanTypeChangeHandler = (event:any) => {
     setScanType(event?.target.value)
  }

  const createTableDataObject = (data: any,range?:any) => {
    const arr: any = [];
    console.log("data",data);
    if (data && data.length > 0) {
      data.forEach((element: any) => {
        const obj: any = {};
        obj["clientName"] = element.clientName ? element.clientName : '';
        obj["host"] = element.host ? element.host : '';
        obj["targetName"] = element.targetName ? element.targetName : '';
        element.scanType ? element.scanType === "Pentest"
          ? (obj["scanType"] = "Pen Test")
          : element.scanType === "Advanced"
            ? (obj["scanType"] = "Advanced VA")
            : element.scanType === "External"
              ? (obj["scanType"] = "External VA")
              : (obj["scanType"] = element.scanType) :
          obj["scanType"] = '';
        obj["creationDate"] = element.creationDate ? moment(element.creationDate)
          .tz(partner.partnerId.tZone)
          .format("MM/DD/YYYY hh:mm A")
          : '';

        arr.sort((a: any, b: any) => {
          if (!a.creationDate) return 1;
          if (!b.creationDate) return -1;
          return (
            new Date(b.creationDate).valueOf() -
            new Date(a.creationDate).valueOf()
          );
        });

        arr.push(obj);
      })
    }
    if(range){
      setShowBackdrop(false);
    }
    if(!range){
      setSearchData(arr);
    }
    setNewData(arr);
  }

  const searchResetHandler = () => {
    setClientNameFilter("");
    setEndDate(null);
    setStartDate(null);
    setScanType("");
    setSearchClient(false);
    setNewData(searchData);
    handleAlertClose();
  }
  
  const handleExport = () => {
    // const csvData = convertToCSV(newData);
    // downloadCSV(csvData, "data");
  };
  
  const searchhandler = () => {
    try{
    setNewData([]);
    handleAlertClose();
    setSearchClient(true);
    if(clientNameFilter.trim().length > 0 && endDate !== null && startDate !== null && scanType !== ""){
      if(startDate > endDate){
        setFormState((formState) => ({
          ...formState,
          isDelete: false,
          isSuccess: false,
          isFailed: true,
          errMessage: msgConstant.SCAN_DETAIL_DATE_ERR,
        }))
      }else{
        setShowBackdrop(true);
        let rangeData:any = [];
        if(scanDetails.ScanDetails.targetField){
          rangeData = scanDetails.ScanDetails.targetField.filter((element:any) => {
            if(element.clientName.includes(clientNameFilter) && new Date(element.creationDate) >= startDate && new Date(element.creationDate) <= endDate && (scanType === element.scanType || scanType === "All")){
              console.log("element",element)
              return element
            }
          });
        }
        setTimeout(() => {
          createTableDataObject(rangeData,true)
        },500)
      }
    }else if(startDate == "Invalid Date" || endDate == "Invalid Date"){
      setFormState((formState) => ({
        ...formState,
        isDelete: false,
        isSuccess: false,
        isFailed: true,
        errMessage: "Please Enter the Valid Date"
      }))
    }else if(clientNameFilter.trim().length > 0 && endDate !== null && startDate !== null){
      if(startDate > endDate){
        setFormState((formState) => ({
          ...formState,
          isDelete: false,
          isSuccess: false,
          isFailed: true,
          errMessage: msgConstant.SCAN_DETAIL_DATE_ERR,
        }))
      }else{
        setShowBackdrop(true);
        let rangeData:any = [];
        if(scanDetails.ScanDetails.targetField){ 
          rangeData = scanDetails.ScanDetails.targetField.filter((element:any) => {
            if(element.clientName.includes(clientNameFilter) && new Date(element.creationDate) >= startDate && new Date(element.creationDate) <= endDate){
              console.log("element",element)
              return element
            }
          });
        }
        setTimeout(() => {
          createTableDataObject(rangeData,true)
        },500)
      }
    }else if(clientNameFilter.trim().length > 0 && scanType !== ""){
      setShowBackdrop(true);
      let rangeData:any = [];
      if(scanDetails.ScanDetails.targetField){
          rangeData = scanDetails.ScanDetails.targetField.filter((element:any) => {
          if(element.clientName.includes(clientNameFilter) && (scanType === element.scanType || scanType === "All")){
            console.log("element",element)
            return element
          }
        });
      }
      setTimeout(() => {
        createTableDataObject(rangeData,true)
      },500)
    }else if(endDate !== null && startDate !== null && scanType !== ""){
      if(startDate > endDate){
        setFormState((formState) => ({
          ...formState,
          isDelete: false,
          isSuccess: false,
          isFailed: true,
          errMessage: msgConstant.SCAN_DETAIL_DATE_ERR,
        }))
      }else{
        setShowBackdrop(true);
        let rangeData:any = [];
        if(scanDetails.ScanDetails.targetField){
          rangeData = scanDetails.ScanDetails.targetField.filter((element:any) => {
            if((scanType === element.scanType || scanType === "All") && new Date(element.creationDate) >= startDate && new Date(element.creationDate) <= endDate){
              console.log("element",element)
              return element
            }
          });
        }
        setTimeout(() => {
          createTableDataObject(rangeData,true)
        },500)
      }
    }else if(clientNameFilter.trim().length > 0){
      let search:any = []
      setShowBackdrop(true);
      if(scanDetails.ScanDetails.targetField){
        scanDetails.ScanDetails.targetField.forEach((element:any) => {
          console.log(element.clientName,clientNameFilter)
          if(element.clientName && element.clientName.includes(clientNameFilter)){
            search.push(element);
          }
        });
      }
       setTimeout(() => {
         createTableDataObject(search,true);
        },500)
    }else if(endDate !== null && startDate !== null && clientNameFilter === ""){
      if(startDate > endDate){
        setFormState((formState) => ({
          ...formState,
          isDelete: false,
          isSuccess: false,
          isFailed: true,
          errMessage: msgConstant.SCAN_DETAIL_DATE_ERR
        }))
      }else{
        setShowBackdrop(true);
        let rangeData:any = [];
        if(scanDetails.ScanDetails.targetField){
          rangeData = scanDetails.ScanDetails.targetField.filter((element:any) => {
            console.log(element.creationDate ,startDate)
            if(new Date(element.creationDate) >= startDate && new Date(element.creationDate) <= endDate){
              console.log("element",element)
              return element
            }
          });
        }
        console.log("rangeData",rangeData);
        setTimeout(() => {
           createTableDataObject(rangeData,true)
        },500)
      }
    }else if(scanType !== ""){
      let search:any = []
      setShowBackdrop(true);
      if(scanDetails.ScanDetails.targetField){
        scanDetails.ScanDetails.targetField.forEach((element:any) => {
          if(element.scanType && (element.scanType === scanType || scanType === "All")){
          search.push(element);
          }
        });
      }
      setTimeout(() => {
        createTableDataObject(search,true);
      },500)
    }else if(startDate === null && endDate !== null){
      setFormState((formState) => ({
        ...formState,
        isDelete: false,
        isSuccess: false,
        isFailed: true,
        errMessage: "Please Select the Start Date Field"
       }))
    }else if(endDate === null && startDate !== null){
      setFormState((formState) => ({
        ...formState,
        isDelete: false,
        isSuccess: false,
        isFailed: true,
        errMessage: "Please Select the End Date Field"
       }))
    }else if(endDate === null && startDate === null){
      setFormState((formState) => ({
        ...formState,
        isDelete: false,
        isSuccess: false,
        isFailed: true,
        errMessage: "Please Enter the Valid Date"
       }))
    }
    // else if(clientNameFilter === ""){
    //   setFormState((formState) => ({
    //     ...formState,
    //     isDelete: false,
    //     isSuccess: false,
    //     isFailed: true,
    //     errMessage: "Please Enter the Client Name"
    //    }))
    // }
    // else if( scanType === ""){
    //   setFormState((formState) => ({
    //     ...formState,
    //     isDelete: false,
    //     isSuccess: false,
    //     isFailed: true,
    //     errMessage: "Please Select the Scan Type"
    //    }))
    // }
    }catch(error){
      showBoundary(error)
    }
  }
  const backToList = () => {
    history(routeConstant.CLIENT);
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container>
        <Grid item xs={12} sm={10}>
          <Typography component="h5" variant="h1">
            Scan Usage Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2} className={styles.FilterAddWrap}>
          <div className={styles.ButtonGroup2}>
            <Button
              id="cancel-button"
              className={styles.BackToButton}
              variant={"contained"}
              onClick={backToList}
              color="secondary"
            >
              <img
                src={`${process.env.PUBLIC_URL}/icons/svg-icon/back-list.svg`}
                alt="user icon"
              />
              &nbsp; Back to List
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} className={styles.FilterWrap}>
          <span style={{ width: "250px" }}>
            <Grid item md={12} xs={12}>
              <Input
                // required={true}
                id="client-name"
                type="search"
                name="filterClient"
                label="Search Client Name"
                variant="outlined"
                value={clientNameFilter}
                onChange={searchFilterChangeHandler}
                error
              />
            </Grid>
          </span>
          <div style={{ width: "200px", marginLeft: "10px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date *"
                value={startDate}
                className={styles.FilterDateInput}
                onChange={startDateChangeHandler}
              />
            </LocalizationProvider>
          </div>
          <div style={{ width: "200px", marginLeft: "10px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date *"
                value={endDate}
                className={styles.FilterDateInput}
                onChange={endDateChangeHandler}
              />
            </LocalizationProvider>
          </div>
          <FormControl sx={{ minWidth: 220,ml:1 }} size="medium"  classes={{root:styles.selectInput}}>
            <InputLabel id="demo-simple-select-label">Scan Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={scanType}
              label="Scan Type"
              defaultValue=""
              onChange={scanTypeChangeHandler}
              // classes={{select:styles.selectInput}}
              sx={{borderRadius: 2.5,color:"black"}}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Pentest">Pentest</MenuItem>
              <MenuItem value="External">External</MenuItem>
              <MenuItem value="Advanced">Advanced</MenuItem>
            </Select>
          </FormControl>
          <div className={styles.searchbarButton}>
            <Button
              className={styles.ActionButton}
              variant={"contained"}
              color="primary"
              onClick={searchhandler}
              id="search-button"
            >
              <SearchIcon className={styles.EditIcon} /> &nbsp; Search
            </Button>
            <Button
              className={styles.borderLess}
              variant={"contained"}
              onClick={searchResetHandler}
              color="primary"
              id="cancel-button"
            >
              RESET
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid>
        {formState.isFailed ? (
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <strong>{formState.errMessage}</strong>
          </Alert>
        ) : null}
        <Paper className={styles.paper}>
          {showBackdrop ? <SimpleBackdrop /> : null}
          <div className={styles.ScrollTable}>
            {newData.length !== 0 ? (
              <MaterialTable
                columns={column}
                data={newData}
                pageSize={25}
              //   onButtonClick={onRowClick}
              />
            ) : !showBackdrop && newData.length == 0 && !searchClient ? (
              <Typography component="h5" variant="h3">
                There are no Target Available for any Client.
              </Typography>
            ) : (
              <Typography component="h5" variant="h3">
                Data not available
              </Typography>
            )}
          </div>
        </Paper>
        <div style={{textAlign:"right"}}>
          <reactCsv.CSVLink data={newData} filename={"scan_usage_details.csv"} header={["ClientName","host","targetName","scanType","creationDate"]} onClick={(e:any)=> {
            if (!(newData.length > 0)) {
              e.preventDefault();
            }
          }}>
            <Button disabled={!(newData.length > 0)}>CSV DOWNLOAD</Button>
          </reactCsv.CSVLink>
        </div>
      </Grid>
    </React.Fragment>

  )
}

export default ScanDetails;
