import React, { useState, useEffect } from "react";
import { setRaStepper } from "../../common/SetRaStepper";
import stepper from "../../common/raStepperList.json";
import { setActiveFormStep } from "../../../../services/Data";
import rerunstepper from "../../common/raRerunStepperList.json";
import { useApolloClient } from "@apollo/client";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";
import { useNavigate, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Button } from "../../../../components/UI/Form/Button/Button";
import { Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Alert from "../../../../components/UI/Alert/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FormHelperText } from "@mui/material";
import { useMutation, useLazyQuery } from "@apollo/client";
import styles from "./MainTarget.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import * as routeConstant from "../../../../common/RouteConstants";
import * as msgConstant from "../../../../common/MessageConstants";
import Cookies from "js-cookie";
import logout from "../../../Auth/Logout/Logout";
import RaStepper from "../../component/RaStepper/RaStepper";
import Input from "../../../../components/UI/Form/Input/Input";
import {
  PG_URL,
  PG_PRICING_API_KEY,
} from "../../../../config/index";
import { DialogBox } from "../../../../components/UI/DialogBox/DialogBox";
import { DELETE_TARGET } from "../../../../graphql/mutations/Target";
import * as Sentry from "@sentry/react";
import { sentry_error_catch } from "../../../../common/sentry_error_catch";
import { useErrorBoundary } from "react-error-boundary";

const MainTarget = () => {
  const { showBoundary } = useErrorBoundary();
  const location = useLocation();
  const history = useNavigate();
  const [name, setName] = useState<String>("");
  const [ipRange, setIpRange] = useState<any>("");
  const [subnetMask, setSubnetMask] = useState<String>("");
  const [param, setParam] = useState<any>(
    location?.state ? location.state[Object.keys(location.state)[0]] : null
  );
  const ReRunTargetName = JSON.parse(
    localStorage.getItem("re-runTargetName") || "{}"
  );
  const clientInfo = param ? param.clientInfo : undefined;
  const partner = JSON.parse(localStorage.getItem("partnerData") || "{}");
  const pg360PartnerId = JSON.parse(
    localStorage.getItem("pg360PartnerId") || "{}"
  );
  const targetId = localStorage.getItem("targetId")
    ? JSON.parse(localStorage.getItem("targetId") || "")
    : null;
  const [targetOpen, setTargetOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openToolTip, setOpenToolTip] = React.useState(false);
  const partnerId = partner.partnerId;
  const clientId = clientInfo ? parseInt(clientInfo.clientId) : undefined;
  const [priceData, setPriceData] = useState<any>();
  const [showIpRangeWarningPopup, setShowIpRangeWarningPopup] = useState(false);
  const client = useApolloClient();
  const [backdrop, setBackdrop] = useState(false);
  const targetName = param
    ? param.targetName
      ? param.targetName
      : null
    : null;
  const obUser = Cookies.getJSON("ob_user");
  const [deleteTarget] = useMutation(DELETE_TARGET);
  const [isError, setIsError] = useState<any>({
    name: "",
    ipRange: "",
    subnetMask: "",
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  useEffect(() => {
    if (location?.state) {
      setParam(location.state[Object.keys(location.state)[0]]);
    }
    getPricingData();
  }, []);

  useEffect(() => {
    if(param && param.scanName && param.subnetMask && param.targetIpRange && 
      param?.scanName !== "" && param?.subnetMask !== "" && param?.targetIpRange !== ""){
      setName(param.scanName);
      setSubnetMask(param.subnetMask);
      setIpRange(param.targetIpRange);
    }
  },[param])

  useEffect(() => {
    try {
      if (param.reRun && param.targetName.includes("_windows")) {
        setRaStepper(
          client,
          rerunstepper.Target.name,
          rerunstepper.Target.value,
          param
        );
      } else if (ReRunTargetName && ReRunTargetName.includes("_windows")) {
        let data = {
          LinuxNetwork:
            param && param.LinuxNetwork ? param.LinuxNetwork : false,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : true,
          editData: param && param.editData ? param.editData : false,
          clientInfo: param && param.clientInfo ? param.clientInfo : null,
          targetInfo: param && param.targetInfo ? param.targetInfo : null,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          targetName: ReRunTargetName ? ReRunTargetName : targetName,
        };
        setRaStepper(
          client,
          rerunstepper.Target.name,
          rerunstepper.Target.value,
          data
        );
      } else {
        setActiveFormStep(0);
        setRaStepper(client, stepper.Target.name, stepper.Target.value, param);
      }
    } catch{
      if (param.reRun && param.targetName.includes("_windows")) {
        setRaStepper(
          client,
          rerunstepper.Target.name,
          rerunstepper.Target.value,
          param
        );
      } else {
        setActiveFormStep(0);
        setRaStepper(client, stepper.Target.name, stepper.Target.value, param);
      }
    }
  })

  useEffect(() => {
    setSubmitDisabled(!(name !== "" && ipRange !== "" && subnetMask !== ""));
  },[name,ipRange,subnetMask])

  const redirect = () => {
    if (!Cookies.getJSON("ob_session")) {
      logout();
    }else{
      setTimeout(() => {
        const data = {
        ...param,
        scanName : name,
        targetIpRange : ipRange,
        subnetMask : subnetMask,
      };
      history(routeConstant.VPN_CONNECT, { state: { data } });
    },1000)
   }
  }
  const handleSubmitDialogBox = () => {
    try{
    handleAlertClose();
    if(checkIsSingleIp()){
      setShowIpRangeWarningPopup(true);
    }else{
      if (!Cookies.getJSON("ob_session")) {
        logout();
      }else{
        setTimeout(() => {
          const data = {
          ...param,
          scanName : name,
          targetIpRange : ipRange,
          subnetMask : subnetMask,
        };
        history(routeConstant.VPN_CONNECT, { state: { data } });
      },1000)
     }
    }}catch(error){
      showBoundary(error)
    }
  }
  const checkIsSingleIp = () => {
    let checkIp = false;
    if(ipRange.length > 0){
      const pattern = new RegExp('(?:\\d{1,3}\\.){3}\\d{1,3}$');
      const isSingleIp = pattern.test(ipRange);
      console.log("isSingleIp",isSingleIp);
      if(isSingleIp){
        // setShowIpRangeWarningPopup(true)
        checkIp = true;
      }
    }
    return checkIp;
  }
  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };
  const getPricingData = async () => {
    let headerObj = {
      "api-key": PG_PRICING_API_KEY,
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    try {
      // partner id should be dynamic
      await fetch(PG_URL + "ob360-scans/api/v1/check-scan-count/post", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify({
          partner_id: pg360PartnerId,
          type: "OB360",
          sub_type: "VA",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setPriceData(data);
        });
    } catch (err:any) { 
      Sentry.captureException(err);
    }
  };
  const removeDataFromLocalStorage = () => {
    localStorage.removeItem("name");
    localStorage.removeItem("targetId");
    localStorage.removeItem("ipRange");
    localStorage.removeItem("ipAddress");
    localStorage.removeItem("re-runTargetName");
    localStorage.removeItem("userName");
    localStorage.removeItem("password");
    localStorage.removeItem("vpnUserName");
    localStorage.removeItem("vpnPassword");
    localStorage.removeItem("vpnFilePath");
    localStorage.removeItem("WinTargetName");
    localStorage.removeItem("LinuxTargetName");
    localStorage.removeItem("subnetMask");
    localStorage.removeItem("LinuxTargetId");
    localStorage.removeItem("winTargetId");
    localStorage.removeItem("updatetargettimestamp");
    localStorage.removeItem("WinIpAddress");
    localStorage.removeItem("initialSubnetMask");
    localStorage.removeItem("initialTargetIpRange");
    localStorage.removeItem("TargetNameTimestamp")
  }
  let obuserData = JSON.parse(obUser);
  let firstName = obuserData.data.getUserDetails.edges[0].node.firstName;
  let lastName = obuserData.data.getUserDetails.edges[0].node.lastName;

  const handleBack = () => {
    try{
    if (Cookies.getJSON("ob_session")) {
      if (targetId && targetId.length > 0) {
        deleteTarget({
          variables: {
            id: Number(targetId),
            firstName: firstName,
            lastName: lastName,
          },
        })
          .then((res: any) => {
            let data = {};
            data = { refetchData: true, clientInfo: clientInfo };
            if (param?.previousPage === "client") {
              history(routeConstant.CLIENT, { state: { data } });
              localStorage.removeItem("customClientUrl");
              removeDataFromLocalStorage()
            } else {
              history(routeConstant.RA_REPORT_LISTING, { state: { data } });
              removeDataFromLocalStorage()
            }
          })
          .catch((err:any) => {
            let data = {};
            data = { refetchData: true, clientInfo: clientInfo };
            history(routeConstant.CLIENT, { state: { data } });
            sentry_error_catch(err,setBackdrop,setFormState)
            removeDataFromLocalStorage()
          });
      }else{
        let data = {};
        data = { refetchData: true, clientInfo: clientInfo };
        if (param?.previousPage === "client") {
          history(routeConstant.CLIENT, { state: { data } });
          removeDataFromLocalStorage()
        } else {
          history(routeConstant.RA_REPORT_LISTING, { state: { data } });
          removeDataFromLocalStorage()
        }
      }
    } else {
      logout();
    }
  }catch(error){
    showBoundary(error)
    }
  }
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    if (!/[^a-zA-Z0-9\- ]/.test(event.target.value)) {
      let value = event.target.value;
      let isErrName = value.length <= 0 ? "Required" : "";
      setIsError((isError: any) => ({
        ...isError,
        name: isErrName,
      }));
      
    }
    if (/[^a-zA-Z0-9\- ]/.test(event.target.value)) {
      setIsError((isError: any) => ({
        ...isError,
        name: "Invalid Scan Name",
      }));
    }
  };

  const handleIpRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIpRange(event.target.value);
    let value = event.target.value;
    let isErrIpRange = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      ipRange: isErrIpRange,
    }));
    // if(value.length > 0){
    //   const pattern = new RegExp('(?:\\d{1,3}\\.){3}\\d{1,3}$');
    //   console.log("value",value);
    //   const isSingleIp = pattern.test(value);
    //   console.log("isSingleIp",isSingleIp);
    //   if(isSingleIp){
    //     setShowIpRangeWarningPopup(true)
    //   }
    // }
  };

  const handleSubnetMaskChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubnetMask(event.target.value);
    let value = event.target.value;
    let isErrSubnetMask = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      subnetMask: isErrSubnetMask,
    }));
  };

  const handleTargetToolTipClose = () => {
    setTargetOpen(false);
  };

  const handleTargetToolTipOpen = () => {
    setTargetOpen(true);
  };

  const handleToolTipClose = () => {
    setOpen(false);
  };

  const handleToolTipOpen = () => {
    setOpen(true);
  };

  const handleSubnetToolTipClose = () => {
    setOpenToolTip(false);
  };

  const handleSubnetToolTipOpen = () => {
    setOpenToolTip(true);
  };

  const cancelIpRange = () => {
    setShowIpRangeWarningPopup(false);
    setIpRange("");
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        Advanced Vulnerability Assessment for{" "}
        {param !== undefined && param.clientInfo !== undefined
          ? param.clientInfo.name
          : null}
        {priceData?.scannable === 1 ? (
          <>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Credits:{" "}
            {priceData.scan_available.free + priceData.scan_available.credit}
          </>
        ) : (
          ""
        )}
      </Typography>
      <RaStepper />
      {backdrop ? <SimpleBackdrop /> : null}
      <DialogBox
        open={showIpRangeWarningPopup}
        handleOk={() => {
          setShowIpRangeWarningPopup(false)
          redirect()
        }}
        handleCancel = {
          cancelIpRange
        }
        buttonOk={"Add"}
        buttonCancel={"Remove"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.CreditMainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}
      >
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            {" "}
          </Typography>
        </div>
        <div className={styles.DialogBoxContext}>
          <p><b style={{color:"#e53935"}}>WARNING: </b>Scanning a single IP address reduces the effectiveness of vulnerability detection.</p>
          <p>We strongly advise scanning a range of IP addresses.(Eg :- 198.168.2.1-255)</p>
        </div>
      </DialogBox>
      <Grid container className={styles.backToListButtonPanel}>
        <Grid item xs={12} md={12} className={styles.backToListButton}>
          {/* <Button
            className={styles.BackToButton}
            id="back-btn"
            variant={"contained"}
            onClick={handleBack}
            color="secondary"
            data-testid="cancel-button"
          >
            <img
              src={`${process.env.PUBLIC_URL}/icons/svg-icon/back-list.svg`}
              alt="user icon"
            />
            &nbsp; Back to List
          </Button> */}
          <div className={styles.crossBtn}>
            <Button
              id="cancel-button"
              variant="contained"
              color="primary"
              onClick={handleBack}
            >
              {"X"}
            </Button>
            <span className={styles.cancleTooltip}>Cancel</span>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={styles.AlertWrap}>
        <Grid item xs={12}>
          {formState.isSuccess ? (
            <Alert
              severity="success"
              id="success-alert"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <strong>{formState.errMessage}</strong>
            </Alert>
          ) : null}
          {formState.isFailed ? (
            <Alert
              severity="error"
              id="error-alert"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {msgConstant.FAILED}
              {formState.errMessage}
            </Alert>
          ) : null}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className={
            param !== undefined && param.editData
              ? styles.disfield
              : styles.inputs
          }
        >
          <span className={styles.IPTooltip}>
            <Tooltip
              open={targetOpen}
              onClose={handleTargetToolTipClose}
              onOpen={handleTargetToolTipOpen}
              placement="bottom-end"
              title={
                <React.Fragment>
                  <p>
                    <b> Scan Name can't contain any special characters. </b>{" "}
                  </p>{" "}
                </React.Fragment>
              }
            >
              <Input
                type="text"
                id="scan-name"
                label="Scan Name"
                value={name}
                onChange={handleNameChange}
                required
                error={isError.name}
                helperText={isError.name}
                disabled={param !== undefined && param.editData}
              >
                Scan Name
              </Input>
            </Tooltip>
          </span>
        </Grid>
        <Grid item xs={12} md={6}>
          <span className={styles.IPTooltip}>
            <Tooltip
              open={open}
              onClose={handleToolTipClose}
              onOpen={handleToolTipOpen}
              placement="bottom-end"
              title={
                <React.Fragment>
                  <p>
                    <b>Please enter data in the below formats</b>{" "}
                  </p>
                  <b>{"Single IP Address"}</b>
                  <em>{"(e.g. 192.168.x.xx)"}</em>{" "}
                  <p>
                    <b>{" IP Range"}</b>{" "}
                    {
                      "(e.g. 192.168.X.1/24 or 192.168.X.1-255)"
                    }
                  </p>{" "}
                  <p>
                    <b>{"Multiple IP Addresses"}</b>{" "}
                    {
                      "(e.g.192.168.X.3,192.168.X.4)"
                    }
                  </p>{" "}
                  <p>
                    <b>For Domain/URL </b> <em>{"(e.g. domainname.com)"}</em>{" "}
                  </p>{" "}
                </React.Fragment>
              }
            >
              <Input
                type="text"
                id="url-ip-range"
                label="URL / IP Range"
                value={ipRange}
                onChange={handleIpRangeChange}
                required
                error={isError.ipRange}
                helperText={isError.ipRange}
              >
                IP Range
              </Input>
            </Tooltip>
          </span>
        </Grid>
        <Grid item xs={12} md={6}>
          <span className={styles.IPTooltip}>
            <Tooltip
              open={openToolTip}
              onClose={handleSubnetToolTipClose}
              onOpen={handleSubnetToolTipOpen}
              placement="bottom-end"
              title={
                <React.Fragment>
                  <p>
                    <b>Please enter data in the below formats</b>{" "}
                  </p>
                  <b>{"You can Add Subnet Mask"}</b>
                  <em>{"(e.g. 255.255.xxx.x or xxx.xxx.xxx.x)"}</em>{" "}
                </React.Fragment>
              }
            >
              <Input
                type="text"
                id="subnet-mask"
                label="Subnet Mask"
                value={subnetMask}
                onChange={handleSubnetMaskChange}
                required
                error={isError.subnetMask}
                helperText={isError.subnetMask}
              >
                Subnet Mask
              </Input>
            </Tooltip>
          </span>
        </Grid>
        <Grid item xs={12} className={styles.ActionButtons}>
          {/* <AlertBox
            DialogTitle={""}
            open={showDialogBox}
            dialogBoxMsg={dialogBoxMsg}
            pathName={""}
            handleOkay={handleOkay}
            cancelButtonPath={""}
            closeButtonPath={""}
            buttonName={"Yes"}
            CloseButtonName={"No"}
            handleCancel={handleCancel}
            handleClose={handleClose}
          ></AlertBox> */}
          <Button
            onClick={handleSubmitDialogBox}
            id="submit-dialogbox-btn"
            color="primary"
            variant={"contained"}
            disabled={submitDisabled}
          >
            next
          </Button>
          {/* <Button
            className={styles.borderLess}
            id="back-btn"
            variant={"contained"}
            onClick={handleBack}
            color="primary"
            data-testid="cancel-button"
          >
            cancel
          </Button> */}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default MainTarget;