import React, { useState, useEffect,forwardRef,useImperativeHandle   } from "react";
import styles from "./Linux_Network.module.css";
import CssBaseLine from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import RaStepper from "../component/RaStepper/RaStepper";
import IconButton from "@mui/material/IconButton";
import Input from "../../../components/UI/Form/Input/Input";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { FormHelperText, makeStyles, createStyles } from "@mui/material";
import { GET_TARGET } from "../../../graphql/queries/Target";
import { GET_TASK_DETAILS } from "../../../graphql/queries/TaskDetails";
import FormControl from "@mui/material/FormControl";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { GET_AVAILABLE_SERVER } from "../../../graphql/queries/Target";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import {
  CREATE_TARGET,
  UPDATE_TARGET,
  DELETE_TARGET,
} from "../../../graphql/mutations/Target";
import InputAdornment from "@mui/material/InputAdornment";
import { Button } from "../../../components/UI/Form/Button/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { setRaStepper } from "../common/SetRaStepper";
import { useApolloClient } from "@apollo/client";
import * as routeConstant from "../../../common/RouteConstants";
import * as msgConstant from "../../../common/MessageConstants";
import AlertBox from "../../../components/UI/AlertBox/AlertBox";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../../../components/UI/Alert/Alert";
import stepper from "../common/raStepperList.json";
import { TEST_LINUX_CONNECTION } from "../../../graphql/mutations/VPNConnection";
import {
  SUCCESS,
  UPDATE,
  DELETE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../common/MessageConstants";
import rerunstepper from "../common/raRerunStepperList.json";
import { setActiveFormStep } from "../../../services/Data";
import Tooltip from "@mui/material/Tooltip";
import Cookies from "js-cookie";
import logout from "../../Auth/Logout/Logout";
import {
  createTheme,
  // ThemeProvider,
  withStyles,
} from "@mui/material";
import {
  DOMAIN_VERIFY,
  IP_VERIFY,
} from "../../../graphql/mutations/DomainVerify";
import { RA_TARGET_VPNTEST } from "../../../config/index";
// import { customClient } from "../../../config/customClient";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/link-context";
// export interface LinuxNetworkProps {
//   updateConnection? :any;
//   ref?:any;
// }
import { sentry_error_catch } from "../../../common/sentry_error_catch";
import { useErrorBoundary } from "react-error-boundary";

export const Linux_Network: React.FC<any> = forwardRef((props:any,ref:any) => {
  const { showBoundary } = useErrorBoundary();
  let data = {};
  const history = useNavigate();
  const location = useLocation();
  const client = useApolloClient();
  const [param, setParam] = useState<any>(
    location?.state ? location.state[Object.keys(location.state)[0]] : null
  );
  const [targetData, setTargetData] = useState<any>(null);
  const [scanConfigList, setScanConfigList] = useState<any>([]);
  const [ipAddress, setIpAddress] = useState<any>("");
  const [editLinuxIpRange, setEditLinuxIpRange] = useState<any>(true);
  const [ipRange, setIpRange] = useState<String>("");
  const [userName, setUserName] = useState<String>("");
  const targetId = JSON.parse(localStorage.getItem("targetId") || "{}");
  const VPNUsername = JSON.parse(localStorage.getItem("vpnUserName") || "{}");
  const VPNPassword = JSON.parse(localStorage.getItem("vpnPassword") || "{}");
  const [vpnUserName, setVpnUserName] = useState<String>("");
  const [targetName, setTargetName] = useState<String>("");
  const [vpnPassword, setVpnPassword] = useState<String>("");
  const [password, setPassword] = useState<string>("");
  const [dialogBoxMsg, setDialogBoxMsg] = useState<string>("");
  const [showbackdrop, setShowbackdrop] = useState<boolean>(true);
  const [connectionSuccess, SetConnectionSuccess] = useState<boolean>(false);
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);
  const [linuxDomain, setLinuxDomain] = useState(false);
  const [linuxCredential, setLinuxCredential] = useState(false);
  const [editDataId, setEditDataId] = useState<Number | null>();
  const [ipListOpen, setIpListOpen] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const session = Cookies.getJSON("ob_session");
  const [params, setParams] = useState<any>({});
  const [subnetMask, setSubnetMask] = useState<any>('');
  const [linuxCertCheckBox, setLinuxCertCheckBox] = useState();
  const [loginCheckBox, setLoginCheckBox] = useState(false);
  const [loginToolTip,setLoginToolTip] = useState(false);
  const [windowsLoginCheckBox, setWindowsLoginCheckBox] = useState(false);
  const TargetNameTimestamp = localStorage.getItem("TargetNameTimestamp")
    ? JSON.parse(localStorage.getItem("TargetNameTimestamp") || "")
    : null;

  useEffect(() => {
    if (location?.state) {
      setParam(location.state[Object.keys(location.state)[0]]);
    }
  }, [location]);

  useEffect(() => {
    if (param) {
      if (
        editDataId === null ||
        (editDataId === undefined && localStorage.getItem("targetId") !== "{")
      ) {
        setEditDataId(JSON.parse(localStorage.getItem("targetId") || "{}"));
      }
    }
    if(param && param.targetInfo && param.targetInfo.subnetMask){
      setSubnetMask(param.targetInfo.subnetMask)
    }
  }, [param]);

  const startDate = new Date();
  const [updateTarget] = useMutation(UPDATE_TARGET);
  const [deleteTarget] = useMutation(DELETE_TARGET);
  const [domainVerify] = useMutation(DOMAIN_VERIFY);
  const [IPVerify] = useMutation(IP_VERIFY);

  const [backdrop, setBackdrop] = useState(false);
  const [isError, setIsError] = useState<any>({
    name: "",
    ipAddress: "",
    userName: "",
    password: "",
    vpnUserName: "",
    ipList: "",
    vpnPassword: "",
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const clientInfo = param ? param.clientInfo : undefined;
  const ReRunTargetName = JSON.parse(
    localStorage.getItem("re-runTargetName") || "{}"
  );
  const partner = JSON.parse(localStorage.getItem("partnerData") || "{}");
  const partnerId = partner.partnerId.id;
  const clientId = clientInfo ? parseInt(clientInfo.clientId) : undefined;
  const targetInfo = param ? param.targetInfo : undefined;
  const [testVpnConnection] = useMutation(TEST_LINUX_CONNECTION);
  const name = localStorage.getItem("name")
    ? JSON.parse(localStorage.getItem("name") || "")
    : null;
  const LinuxTargetName = localStorage.getItem("LinuxTargetName")
    ? JSON.parse(localStorage.getItem("LinuxTargetName") || "")
    : null;
  const [createTarget] = useMutation(CREATE_TARGET);
  const [getTaskData, { data: taskData, loading: taskLoading }] = useLazyQuery(
    GET_TASK_DETAILS,
    {
      onCompleted: (data: any) => {
        if (data.getTask.edges && data.getTask.edges[0]) {
          setScanConfigList(data.getTask.edges[0].node.vatScanConfigList);
        }
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const {
    data: tarData,
    loading: targetLoading,
    error: targetError,
  } = useQuery(GET_TARGET, {
    variables: {
      targetName:
        param && param.editData
          ? targetName
            ? targetName
            : ReRunTargetName
          : ReRunTargetName
          ? ReRunTargetName
          : targetName,
    },
    onCompleted: (data: any) => {
      if (data.getCredentialsDetails.edges[0]) {
        setTargetData(data);
        // setIpAddress(data.getCredentialsDetails.edges[0].node.linuxIpAddress);
        setIpAddress(JSON.parse(localStorage.getItem("ipAddress") || ""))
        setPassword(JSON.parse(localStorage.getItem("password") || ""))
        setUserName(
          data.getCredentialsDetails.edges[0].node
            ? data.getCredentialsDetails.edges[0].node.domainUsername
            : null
        );
      }
      setShowbackdrop(false);
    },
    onError: (err:any) => {
      sentry_error_catch(err,setBackdrop,setFormState)
    },
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    if (param) {
      setLinuxCertCheckBox(param.checkboxSelected);
      setLinuxCredential(props.linuxLoginSelected);
      setWindowsLoginCheckBox(props.windowLoginSelected);
    }
  }, [param,props]);


  useEffect(() => {
    try {
      if (ReRunTargetName.includes("_windows")) {
        setActiveFormStep(2);
        let data = {
          LinuxNetwork:
            param && param.LinuxNetwork ? param.LinuxNetwork : false,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : true,
          editData: param && param.editData ? param.editData : false,
          clientInfo: param && param.clientInfo ? param.clientInfo : null,
          targetInfo: param && param.targetInfo ? param.targetInfo : null,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          targetName: ReRunTargetName ? ReRunTargetName : targetName,
        };
        setRaStepper(
          client,
          rerunstepper.LoginCredentail.name,
          rerunstepper.LoginCredentail.value,
          data
        );
      } else {
        setRaStepper(
          client,
          stepper.LoginCredentail.name,
          stepper.LoginCredentail.value,
          param
        );
        setActiveFormStep(2);
      }
    } catch(err:any) {
      setRaStepper(
        client,
        stepper.LoginCredentail.name,
        stepper.LoginCredentail.value,
        param
      );
      setActiveFormStep(2);
    }
    if (
      param != undefined &&
      param.editLinuxData &&
      param.editLinuxData === true
    ) {
      setSubmitDisabled(false);
      setFormState((formState) => ({
        ...formState,
        isSuccess: true,
        isUpdate: false,
        isDelete: false,
        isFailed: false,
        errMessage: "Connection has been validated",
      }));
    }
  }, []);

  useEffect(() => {
    setParams(param);
    if (targetId && editDataId !== undefined) {
      setIpRange(JSON.parse(localStorage.getItem("ipRange") || ""));
      setTargetName(LinuxTargetName ? LinuxTargetName : name);
      if (localStorage.getItem("vpnUserName") !== null) {
        setVpnUserName(JSON.parse(localStorage.getItem("vpnUserName") || "{}"));
      }
    }
  }, [targetId, editDataId]);

  let clientID =
    param && param.clientInfo ? param.clientInfo.clientId : undefined;
  let host = param && param.targetInfo ? param.targetInfo.host : undefined;

  const checkValidation = () => {
    if (
      isError.name !== "" ||
      isError.ipAddress !== "" ||
      isError.userName !== "" ||
      isError.password !== "" ||
      isError.vpnUserName !== "" ||
      isError.vpnPassword !== "" ||
      !ipAddress ||
      !userName ||
      !password ||
      !connectionSuccess
    ) {
      return true;
    }
    return false;
  };

  const handleClose = () => {
    setShowDialogBox(false);
    setBackdrop(true);
    setTimeout(() => {
      if (connectionSuccess) {
        data = {
          LinuxNetwork: param && param.LinuxNetwork ? param.LinuxNetwork : true,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : false,
          editData: param && param.editData ? param.editData : false,
          clientInfo: param.clientInfo,
          targetInfo: param.targetInfo,
          editLinuxData: param.editLinuxData ? param.editLinuxData : true,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          previousPage: param?.previousPage,
          checkboxSelected: linuxCertCheckBox,
        };
      } else {
        data = {
          LinuxNetwork:
            param && param.LinuxNetwork ? param.LinuxNetwork : false,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : false,
          editData: param && param.editData ? param.editData : false,
          clientInfo: param.clientInfo,
          targetInfo: param.targetInfo,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          previousPage: param?.previousPage,
          checkboxSelected: linuxCertCheckBox,
          linuxLoginSelected: loginCheckBox,
          windowLoginSelected: windowsLoginCheckBox,
        };
      }
      history(routeConstant.TASK_DETAILS, { state: { data } });
      setBackdrop(false);
    }, 100);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleIpRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIpAddress(event.target.value);
    let value = event.target.value;
    let isErrIpRange = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      ipAddress: isErrIpRange,
    }));
    setSubmitDisabled(checkValidation);
    props.ChangeLoginSubmitDisabled(true);
  };
  const handleIpListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIpRange(event.target.value);
    let value = event.target.value;
    let isErrIpList = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      ipList: isErrIpList,
    }));
    setSubmitDisabled(checkValidation);
    props.ChangeLoginSubmitDisabled(true);
  }

  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
    let value = event.target.value;
    let isErrVpnUserName = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      vpnUserName: isErrVpnUserName,
    }));
    // setSubmitDisabled(checkValidation);
    props.ChangeLoginSubmitDisabled(true);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    let value = event.target.value;
    let isErrVpnPassword = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      vpnPassword: isErrVpnPassword,
    }));
    // setSubmitDisabled(checkValidation);
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleClickShowVpnPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOkay = () => {
    setTimeout(() => {
      if (connectionSuccess) {
        data = {
          LinuxNetwork: param && param.LinuxNetwork ? param.LinuxNetwork : true,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : false,
          editData: param.editData ? param.editData : false,
          editLinuxData: param.editLinuxData ? param.editLinuxData : true,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          clientInfo: param.clientInfo,
          targetInfo: param.targetInfo,
          previousPage: param?.previousPage,
          checkboxSelected: linuxCertCheckBox,
        };
      } else {
        data = {
          LinuxNetwork:
            param && param.LinuxNetwork ? param.LinuxNetwork : false,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : false,
          editData: param.editData ? param.editData : false,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          clientInfo: param.clientInfo,
          targetInfo: param.targetInfo,
          previousPage: param?.previousPage,
          checkboxSelected: linuxCertCheckBox,
          linuxLoginSelected: loginCheckBox,
          windowLoginSelected: windowsLoginCheckBox,
        };
      }
      history(routeConstant.WINDOWS_NETWORK, { state: { data } });
    }, 1000);
  };
  const withOutLoginVPNConnection = async () => {
    const loginCheckBoxParameter = !loginCheckBox ? "Yes" : "No";
    let url = ""
        const headerObj = {
          "Content-Type": "application/json",
          Authorization: "jwt" + " " + session,
        };
        if (
          (localStorage.getItem("runTargetName") != null &&
            targetData &&
            targetData != null) ||
          (targetData != undefined &&
            targetData.getCredentialsDetails &&
            targetData.getCredentialsDetails.edges &&
            targetData.getCredentialsDetails.edges.length > 0)
        ) {          
          if (targetData?.getCredentialsDetails?.edges.length > 0) {
            url = `${RA_TARGET_VPNTEST}target/testlinuxcredentails/?cid=${clientID}&tname=${targetName}&vusername=${VPNUsername}&vpasswords=${encodeURIComponent(VPNPassword)}&tid=${
              targetData.getCredentialsDetails.edges[0].node.vatTarget.id
            }&dhost=${ipAddress}&dusername=${!loginCheckBox ? "" : userName}&dpassword=${!loginCheckBox ? "" : encodeURIComponent(
              password)}&credscheckbox=${loginCheckBoxParameter}`;
          } 
        }else{
          url = `${RA_TARGET_VPNTEST}target/testlinuxcredentails/?cid=${clientID}&tname=${
            targetName}&vusername=${VPNUsername}&vpasswords=${encodeURIComponent(VPNPassword)}&dhost=${ipAddress}&dusername=${
              !loginCheckBox ? "" : userName}&dpassword=${!loginCheckBox ? "" : encodeURIComponent(password)}&credscheckbox=${loginCheckBoxParameter}`;
        }
        await fetch(url, {
          method: "GET",
          headers: headerObj,
        }).then(() => {
          SetConnectionSuccess(true);
        }).catch((err:any) => {
          sentry_error_catch(err,setBackdrop,setFormState)
          SetConnectionSuccess(false);
        })
  }

  const handleSubmitDialogBox = () => {
    setBackdrop(true);
    handleAlertClose();
    if (Cookies.getJSON("ob_session")) {
      // if(!loginCheckBox){
      //   onClickTestConnection();
      // }
      if (editDataId) {
        // setSubmitDisabled(true)
        let input = {
          partner: partnerId,
          client: clientId,
          targetName: TargetNameTimestamp,
          host: ipRange,
          linuxUsername: userName,
          linuxPassword: password,
          vpnUsername: vpnUserName,
          vpnPassword: vpnPassword,
          authCheckboxSelected: linuxCertCheckBox ? "Yes" : "No",
          startDate: startDate,
          subnet:subnetMask,
          linuxIpAddress: ipRange,
          user: parseInt(partner.userId["id"]),
        };
        let id = editDataId;
        updateTarget({
          variables: {
            input,
            id,
          },
        })
          .then((userRes) => {
            setBackdrop(false);
            // if(!loginCheckBox){
            //   setFormState((formState) => ({
            //     ...formState,
            //     isSuccess: false,
            //     isUpdate: true,
            //     isDelete: false,
            //     isFailed: false,
            //     errMessage: "Linux Target Created Successfully! ",
            //   }));
            // }else{
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: true,
                isDelete: false,
                isFailed: false,
                errMessage: "Linux Credentials Updated Successfully",
              }));
            // }
            setSubmitDisabled(false);
            setEditDataId(null);
            localStorage.setItem("LinuxTargetId", JSON.stringify(userRes.data.updateTarget.targetField.id));
            localStorage.setItem("ipAddress", JSON.stringify(ipAddress));
            localStorage.setItem("userName", JSON.stringify(userName));
            localStorage.setItem("password", JSON.stringify(password));
            if (userRes.data.updateTarget != undefined) {
              localStorage.setItem(
                "LinuxTargetName",
                JSON.stringify(userRes.data.updateTarget.targetField.targetName)
              );
            }
            setShowDialogBox(false);
            let data:any = {};
            if (connectionSuccess) {
              data = {
                LinuxNetwork:
                  param && param.LinuxNetwork ? param.LinuxNetwork : false,
                windowsNetwork:
                  param && param.windowsNetwork ? param.windowsNetwork : true,
                editData: param && param.editData ? param.editData : true,
                clientInfo: param && param.clientInfo ? param.clientInfo : null,
                targetInfo: param && param.targetInfo ? param.targetInfo : null,
                editLinuxData: param.editLinuxData
                  ? param.editLinuxData
                  : true,
                editWindowsData: param.editWindowsData
                  ? param.editWindowsData
                  : false,
                previousPage: param?.previousPage,
                checkboxSelected: linuxCertCheckBox,
                linuxLoginSelected: linuxCredential,
                windowLoginSelected: windowsLoginCheckBox,
                scanName : param.scanName ?  param.scanName : '',
                targetIpRange : param.targetIpRange ? param.targetIpRange : '',
                subnetMask : param.subnetMask ? param.subnetMask : '',
              };
            } else {
              data = {
                LinuxNetwork:
                  param && param.LinuxNetwork ? param.LinuxNetwork : false,
                windowsNetwork:
                  param && param.windowsNetwork ? param.windowsNetwork : true,
                editData: param && param.editData ? param.editData : false,
                clientInfo: param && param.clientInfo ? param.clientInfo : null,
                targetInfo: param && param.targetInfo ? param.targetInfo : null,
                editLinuxData: param.editLinuxData
                  ? param.editLinuxData
                  : false,
                editWindowsData: param.editWindowsData
                  ? param.editWindowsData
                  : false,
                previousPage: param?.previousPage,
                checkboxSelected: linuxCertCheckBox,
                linuxLoginSelected: linuxCredential,
                windowLoginSelected: windowsLoginCheckBox,
                scanName : param.scanName ?  param.scanName : '',
                targetIpRange : param.targetIpRange ? param.targetIpRange : '',
                subnetMask : param.subnetMask ? param.subnetMask : '',
              };
            }
            const linuxTargetDetails = {
              linuxUsername : userName ? userName : "",
              linuxPassword: password ? password : "",
              linuxValidateIP: ipAddress ? ipAddress : "",
              linuxIpRange: ipRange ? ipRange : ""
            }
            data["linuxTargetDetails"] = linuxTargetDetails
            props.sendDetails(data);
            // setRaStepper(
            //   client,
            //   stepper.ScanConfiguration.name,
            //   stepper.ScanConfiguration.value,
            //   data
            // );
            // try {
            //   if (ReRunTargetName && ReRunTargetName.includes("_windows")) {
            //     history(routeConstant.TASK_DETAILS, { state: { data } });
            //   } else {
            //     history(routeConstant.WINDOWS_NETWORK, { state: { data } });
            //   }
            // } catch {
            //   // setShowDialogBox(false);
            //   setTimeout(() => {
            //     setLinuxDomain(true);
            //     setShowDialogBox(true);
            //     setDialogBoxMsg(msgConstant.WINDOWS_NETWORK_CREDENTIALS);
            //   }, 1000);
            //   // history(routeConstant.TASK_DETAILS, { state: { data } });
            // }
          })
          .catch((err:any) => {
            console.log("errerrerr", err);
            setBackdrop(false);
            setShowDialogBox(false);
            setSubmitDisabled(true);
            let error = err.message;
            if (error.includes("duplicate key value violates unique constraint")) {
              error = " Name already present.";
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: error,
              }));
            } else {
              sentry_error_catch(err,setBackdrop,setFormState)
            }
          });
      }
    }
  }
  useImperativeHandle(
    ref,
    () => ({
      handleSubmitDialogBox
    })
  );

  const submitAction = async () => {
    try{
    const loginCheckBoxParameter = "No";
    if (
      (localStorage.getItem("runTargetName") != null &&
        targetData &&
        targetData != null) ||
      (targetData != undefined &&
        targetData.getCredentialsDetails &&
        targetData.getCredentialsDetails.edges &&
        targetData.getCredentialsDetails.edges.length > 0)
    ) {
      setBackdrop(true);
      const headerObj = {
        "Content-Type": "application/json",
        Authorization: "jwt" + " " + session,
      };
      let url;
      if (targetData?.getCredentialsDetails?.edges.length > 0) {
        url = `${RA_TARGET_VPNTEST}target/testlinuxcredentails/?cid=${clientID}&host=${ipRange}&tname=${targetName}&vusername=${VPNUsername}&vpasswords=${encodeURIComponent(VPNPassword)}&tid=${
          targetData.getCredentialsDetails.edges[0].node.vatTarget.id
        }&dhost=${ipAddress}&dusername=${userName}&dpassword=${encodeURIComponent(
          password
        )}&credscheckbox=${loginCheckBoxParameter}&subnet=${subnetMask}`;
      } else {
        url = `${RA_TARGET_VPNTEST}target/testlinuxcredentails/?cid=${clientID}&host=${ipRange}&tname=${targetName}
          "&vusername=${VPNUsername}&vpasswords=${encodeURIComponent(VPNPassword)}&dhost=${ipAddress}&dusername=${userName}&dpassword=
          ${encodeURIComponent(password)}&credscheckbox=${loginCheckBoxParameter}&subnet=${subnetMask}`;
      }
      await fetch(url, {
        method: "GET",
        headers: headerObj,
      })
        .then((data) => data.json())
        .then((response) => {
          setBackdrop(false);
          if (response == "Authentication succeeded, connection successful") {
            SetConnectionSuccess(true);
            setSubmitDisabled(false);
            setFormState((formState) => ({
              ...formState,
              isSuccess: true,
              isUpdate: false,
              isDelete: false,
              isFailed: false,
              errMessage: msgConstant.TEST_CONNECTION,
            }));
            let connectionObj = {
              linux_test_connection : true,
            }
            props.updateConnection(connectionObj)
          } else if (response == "VPN is Connected,Please Disconnect") {
            SetConnectionSuccess(false);
            setSubmitDisabled(true);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage:
                "You are already connected with another VPN. Please disconnect then try again",
            }));
          } else if (
            response == "Authentication failed, please verify your credentials"
          ) {
            SetConnectionSuccess(false);
            setSubmitDisabled(true);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: msgConstant.VPN_AUTHENTICATION_FAILED,
            }));
          } else if (response == "Openvpn File is invalid") {
            SetConnectionSuccess(false);
            setSubmitDisabled(true);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: msgConstant.VPN_FILE_INVALID,
            }));
          } else {
            SetConnectionSuccess(false);
            setSubmitDisabled(true);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: msgConstant.TEST_CONNECTION_FAILED,
            }));
          }
        })
        .catch((err:any) => {
          setSubmitDisabled(true);
          sentry_error_catch(err,setBackdrop,setFormState)
        });
    } else {
      setBackdrop(true);
      const headerObj = {
        "Content-Type": "application/json",
        Authorization: "jwt" + " " + session,
      };
      let url;
      url =
        RA_TARGET_VPNTEST +
        "target/testlinuxcredentails/?cid=" +
        clientID +
        "&host=" +
        ipRange +
        "&tname= " +
        targetName +
        "&vusername=" +
        VPNUsername +
        "&vpasswords=" +
        `${encodeURIComponent(VPNPassword)}` +
        "&dhost=" +
        ipAddress +
        "&dusername=" +
        userName +
        "&credscheckbox=" + 
        loginCheckBoxParameter +
        "&subnet=" +
        subnetMask +
        "&dpassword=" +
        `${encodeURIComponent(password)}`;
      await fetch(url, {
        method: "GET",
        headers: headerObj,
      })
        .then((data) => data.json())
        .then((response) => {
          setBackdrop(false);
          if (response == "Authentication succeeded, connection successful") {
            SetConnectionSuccess(true);
            setSubmitDisabled(false);
            setFormState((formState) => ({
              ...formState,
              isSuccess: true,
              isUpdate: false,
              isDelete: false,
              isFailed: false,
              errMessage: msgConstant.TEST_CONNECTION,
            }));
            let connectionObj = {
              linux_test_connection : true,
            }
            props.updateConnection(connectionObj)
          } else if (
            response == "Authentication failed, please verify your credentials"
          ) {
            SetConnectionSuccess(false);
            setSubmitDisabled(true);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: msgConstant.VPN_AUTHENTICATION_FAILED,
            }));
          } else if (response == "Openvpn File is invalid") {
            SetConnectionSuccess(false);
            setSubmitDisabled(true);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: msgConstant.VPN_FILE_INVALID,
            }));
          } else if (response == "VPN is Connected,Please Disconnect") {
            SetConnectionSuccess(false);
            setSubmitDisabled(true);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage:
                "You are already connected with another VPN. Please disconnect then try again",
            }));
          } else {
            SetConnectionSuccess(false);
            setSubmitDisabled(true);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: msgConstant.TEST_CONNECTION_FAILED,
            }));
          }
        })
        .catch((err:any) => {
          setSubmitDisabled(true);
          sentry_error_catch(err,setBackdrop,setFormState)
        });
    }}catch(error){
      showBoundary(error)
    }
  };
  const onClickTestConnection = () => {
    try{
    if (Cookies.getJSON("ob_session")) {
      if (handleInputErrors()) {
        handleAlertClose();
        let input = {
          host: ipAddress,
        };
        if (parseInt(ipAddress)) {
          IPVerify({
            variables: {
              input,
            },
          })
            .then((userRes) => {
              if (userRes.data.IPVerify.status === "Valid IP address") {
                // if(!loginCheckBox){
                //   withOutLoginVPNConnection()
                // }else{
                  submitAction();
                // }
              } else {
                setBackdrop(false);
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: false,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: true,
                  errMessage: " Please Enter Valid IP Address",
                }));
              }
            })
            .catch((err:any) => {
              sentry_error_catch(err,setBackdrop,setFormState)
            });
        } else {
          domainVerify({
            variables: {
              input,
            },
          })
            .then((userRes: any) => {
              if (
                userRes.data.domainVerify.status === "Domain name is registered"
              ) {
                // if(!loginCheckBox){
                //   withOutLoginVPNConnection()
                // }else{
                  submitAction();
                // }
              } else {
                setBackdrop(false);
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: false,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: true,
                  errMessage: " Please Enter Valid Domain Name",
                }));
              }
            })
            .catch((err: any) => {
              sentry_error_catch(err,setBackdrop,setFormState)
            });
        }
      } else {
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: msgConstant.REQUIRED_ALL_FIELD,
        }));
      }
    } else {
      logout();
    }}catch(error){
      showBoundary(error)
    }
  };

  const handleBack = () => {
    if (Cookies.getJSON("ob_session")) {
      try {
        if (ReRunTargetName.includes("_windows")) {
          setActiveFormStep(1);
          let data = {
            LinuxNetwork:
              param && param.LinuxNetwork ? param.LinuxNetwork : false,
            windowsNetwork:
              param && param.windowsNetwork ? param.windowsNetwork : true,
            editData: param && param.editData ? param.editData : false,
            clientInfo: param && param.clientInfo ? param.clientInfo : null,
            targetInfo: param && param.targetInfo ? param.targetInfo : null,
            editLinuxData:
              param && param.editLinuxData ? param.editLinuxData : false,
            editWindowsData:
              param && param.editWindowsData ? param.editWindowsData : false,
            targetName: ReRunTargetName ? ReRunTargetName : targetName,
            previousPage: param?.previousPage,
            checkboxSelected: linuxCertCheckBox,
            linuxLoginSelected: loginCheckBox,
            windowLoginSelected: windowsLoginCheckBox,
          };
          // setRaStepper(
          //   client,
          //   rerunstepper.WindowsNetwork.name,
          //   rerunstepper.WindowsNetwork.value,
          //   data
          // );
          history(routeConstant.WINDOWS_NETWORK, { state: { data } });
        } else {
          setRaStepper(
            client,
            stepper.Target.name,
            stepper.Target.value,
            param
          );
          setActiveFormStep(0);
          let data = {
            editData: true,
            editLinuxData: param.editLinuxData ? param.editLinuxData : false,
            LinuxNetwork:
              param && param.LinuxNetwork ? param.LinuxNetwork : false,
            windowsNetwork:
              param && param.windowsNetwork ? param.windowsNetwork : true,
            clientInfo: clientInfo,
            targetInfo: targetInfo,
            editWindowsData:
              param && param.editWindowsData ? param.editWindowsData : false,
            previousPage: param?.previousPage,
            checkboxSelected: linuxCertCheckBox,
            linuxLoginSelected: loginCheckBox,
            windowLoginSelected: windowsLoginCheckBox,
          };
          history(routeConstant.TARGET, { state: { data } });
        }
      } catch (err:any){
        setRaStepper(client, stepper.Target.name, stepper.Target.value, param);
        setActiveFormStep(0);
        let data = {
          editData: true,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          LinuxNetwork:
            param && param.LinuxNetwork ? param.LinuxNetwork : false,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : true,
          clientInfo: clientInfo,
          targetInfo: targetInfo,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          previousPage: param?.previousPage,
          checkboxSelected: linuxCertCheckBox,
          linuxLoginSelected: loginCheckBox,
          windowLoginSelected: windowsLoginCheckBox,
        };
        history(routeConstant.TARGET, { state: { data } });
      }
    } else {
      logout();
    }
  };

  const handleSkip = () => {
    try {
      if (ReRunTargetName.includes("_windows")) {
        setActiveFormStep(3);
        let data = {
          LinuxNetwork:
            param && param.LinuxNetwork ? param.LinuxNetwork : false,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : true,
          editData: param && param.editData ? param.editData : false,
          clientInfo: param && param.clientInfo ? param.clientInfo : null,
          targetInfo: param && param.targetInfo ? param.targetInfo : null,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          targetName: ReRunTargetName ? ReRunTargetName : targetName,
          previousPage: param?.previousPage,
          checkboxSelected: linuxCertCheckBox,
          linuxLoginSelected: loginCheckBox,
          windowLoginSelected: windowsLoginCheckBox,
        };
        setRaStepper(
          client,
          stepper.ScanConfiguration.name,
          stepper.ScanConfiguration.value,
          data
        );
        history(routeConstant.TASK_DETAILS, { state: { data } });
      } else {
        let data = {
          LinuxNetwork:
            param && param.LinuxNetwork ? param.LinuxNetwork : false,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : false,
          editData: param.editData ? param.editData : false,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          clientInfo: param.clientInfo,
          targetInfo: param.targetInfo,
          previousPage: param?.previousPage,
          checkboxSelected: linuxCertCheckBox,
          linuxLoginSelected: loginCheckBox,
          windowLoginSelected: windowsLoginCheckBox,
        };
        history(routeConstant.WINDOWS_NETWORK, { state: { data } });
      }
    } catch {
      let data = {
        LinuxNetwork: param && param.LinuxNetwork ? param.LinuxNetwork : false,
        windowsNetwork:
          param && param.windowsNetwork ? param.windowsNetwork : false,
        editData: param.editData ? param.editData : false,
        editLinuxData: param.editLinuxData ? param.editLinuxData : false,
        editWindowsData: param.editWindowsData ? param.editWindowsData : false,
        clientInfo: param.clientInfo,
        targetInfo: param.targetInfo,
        previousPage: param?.previousPage,
        checkboxSelected: linuxCertCheckBox,
        linuxLoginSelected: loginCheckBox,
        windowLoginSelected: windowsLoginCheckBox,
      };
      history(routeConstant.WINDOWS_NETWORK, { state: { data } });
    }
  };

  const handleInputErrors = () => {
    let error = true;
    // if(loginCheckBox){
    if (userName === "" || userName === null) {
      error = false;
      setIsError((isError: any) => ({
        ...isError,
        vpnUserName: "Required",
      }));
    }
  //  }
    if (ipAddress === "" || ipAddress === null) {
      error = false;
      setIsError((error: any) => ({
        ...error,
        ipAddress: "Required",
      }));
    }
    // if(loginCheckBox){
    if (password === "" || password === null) {
      error = false;
      setIsError((isError: any) => ({
        ...isError,
        vpnPassword: "Required",
      }));
     }
    // }
    return error;
  };
  const handleToolTipClose = () => {
    setOpen(false);
  };

  const handleToolTipOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    if (Cookies.getJSON("ob_session")) {
      let userData = JSON.parse(Cookies.getJSON("ob_user"));
      deleteTarget({
        variables: {
          id: Number(targetId),
          firstName: userData.data.getUserDetails.edges[0].node.firstName,
          lastName: userData.data.getUserDetails.edges[0].node.lastName,
        },
      })
        .then((res: any) => {
          let data = {};
          data = { refetchData: true, clientInfo: clientInfo };
          if (params.previousPage == "client") {
            history(routeConstant.CLIENT, { state: { data } });
            localStorage.removeItem("name");
            localStorage.removeItem("targetId");
            localStorage.removeItem("ipRange");
            localStorage.removeItem("customClientUrl");
            localStorage.removeItem("ipAddress");
            localStorage.removeItem("re-runTargetName");
            localStorage.removeItem("userName");
            localStorage.removeItem("password");
            localStorage.removeItem("vpnUserName");
            localStorage.removeItem("vpnPassword");
            localStorage.removeItem("vpnFilePath");
            localStorage.removeItem("WinTargetName");
            localStorage.removeItem("LinuxTargetName");
          } else {
            history(routeConstant.RA_REPORT_LISTING, { state: { data } });
            localStorage.removeItem("name");
            localStorage.removeItem("targetId");
            localStorage.removeItem("ipRange");
            localStorage.removeItem("ipAddress");
            localStorage.removeItem("re-runTargetName");
            localStorage.removeItem("userName");
            localStorage.removeItem("password");
            localStorage.removeItem("vpnUserName");
            localStorage.removeItem("vpnPassword");
            localStorage.removeItem("vpnFilePath");
            localStorage.removeItem("WinTargetName");
            localStorage.removeItem("LinuxTargetName");
          }
        })
        .catch((err:any) => {
          sentry_error_catch(err,setBackdrop,setFormState)
          let data = {};
          data = { refetchData: true, clientInfo: clientInfo };
          if (params.previousPage == "client") {
            history(routeConstant.CLIENT, { state: { data } });
            localStorage.removeItem("name");
            localStorage.removeItem("targetId");
            localStorage.removeItem("ipRange");
            localStorage.removeItem("ipAddress");
            localStorage.removeItem("re-runTargetName");
            localStorage.removeItem("userName");
            localStorage.removeItem("password");
            localStorage.removeItem("vpnUserName");
            localStorage.removeItem("vpnPassword");
            localStorage.removeItem("vpnFilePath");
            localStorage.removeItem("WinTargetName");
            localStorage.removeItem("LinuxTargetName");
          } else {
            history(routeConstant.RA_REPORT_LISTING, { state: { data } });
            localStorage.removeItem("name");
            localStorage.removeItem("targetId");
            localStorage.removeItem("ipRange");
            localStorage.removeItem("ipAddress");
            localStorage.removeItem("re-runTargetName");
            localStorage.removeItem("userName");
            localStorage.removeItem("password");
            localStorage.removeItem("vpnUserName");
            localStorage.removeItem("vpnPassword");
            localStorage.removeItem("vpnFilePath");
            localStorage.removeItem("WinTargetName");
            localStorage.removeItem("LinuxTargetName");
          }
        });
    } else {
      logout();
    }
  };
  const checkBoxChangeHandler = (event: any) => {
    // setLoginCheckBox(event.target.checked);
    setLinuxCredential(event.target.checked);
    // if(event.target.checked === true){
    //   setUserName("");
    //   setPassword("");
    // }
  }
  
  return (
    <React.Fragment>
      {/* <CssBaseLine />
      <Typography component="h5" variant="h1">
        Advanced Vulnerability Assessment for{" "}
        {param !== undefined && param.clientInfo !== undefined
          ? param.clientInfo.name
          : null}
      </Typography>
      <RaStepper /> */}
      {backdrop ? <SimpleBackdrop /> : null}
      <Grid container spacing={3} className={styles.AlertWrap}>
        <Grid item xs={12}>
          {formState.isSuccess ? (
            <Alert
              severity="success"
              id="success-alert"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <strong>{formState.errMessage}</strong>
              {/* {SUCCESS} */}
            </Alert>
          ) : null}
          {formState.isUpdate ? (
            <Alert
              severity="success"
              id="success-alert2"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <strong>{formState.errMessage}</strong>
              {/* {SUCCESS} */}
            </Alert>
          ) : null}
          {formState.isFailed ? (
            <Alert
              severity="error"
              id="error-alert"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {FAILED}
              {formState.errMessage}
            </Alert>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <div style={{ paddingLeft: "7px" }}>
            {/* <FormControlLabel
              control={
                <Checkbox name="linux_check" checked={linuxCredential} color="primary" onChange={checkBoxChangeHandler} />
              }
              classes={{root:styles['MuiFormControlLabel-root']}}
              label="Linux Credential"
            />
            <span style={{cursor:"pointer"}}>
              <Tooltip
                open={loginToolTip}
                onClose={() => {
                  setLoginToolTip(false)
                }}
                placement="top"
                onOpen={() => {
                  setLoginToolTip(true)
                }}
                title={
                  <React.Fragment>
                    <p>
                      <b>Note:</b>
                      <b>
                        {"Select the checkbox to enter data for Linux Scan."}
                      </b>{" "}
                    </p>
                  </React.Fragment>
                }
              >
                <ContactSupportIcon className={styles.CircleIcon} />
              </Tooltip>
            </span> */}
            <Typography component="h5" variant="h2">
              Linux Network
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            label=" Username"
            id="username"
            value={userName}
            onChange={handleUserNameChange}
            required
            error={isError.vpnUserName}
            helperText={isError.vpnUserName}
            disabled = {!linuxCredential}
          >
            User Name
          </Input>
        </Grid>
        <Grid item xs={12} md={6} className={styles.PasswordField}>
          <FormControl className={styles.TextField} variant="outlined">
            <InputLabel classes={{ root: styles.FormLabel }}>
              Password *
            </InputLabel>
            <OutlinedInput
              classes={{
                root: styles.InputField,
                notchedOutline: styles.InputField,
                focused: styles.InputField,
              }}
              type={showPassword ? "text" : "password"}
              label="Password *"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              required
              error={isError.vpnPassword}
              disabled = {!linuxCredential}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowVpnPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {isError.vpnPassword ? (
              <FormHelperText
                error={isError.vpnPassword}
                classes={{ root: styles.FormHelperText }}
              >
                Required
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <span className={styles.IPTooltip}>
            {/* <ThemeProvider theme={theme}> */}
            <Tooltip
              className={styles.tooltip}
              open={open}
              onClose={handleToolTipClose}
              onOpen={handleToolTipOpen}
              placement="bottom-end"
              title={
                <React.Fragment>
                  <p>
                    <b>Enter IP Address only</b>{" "}
                  </p>
                  <b>{"Single IP Address"}</b>
                  <em>{"(e.g. 192.168.x.xx)"}</em>{" "}
                  <p>
                    <b>{" Multiple IP Address"}</b>{" "}
                    {"(e.g. 192.168.x.x,192.168.x.x)"}
                  </p>{" "}
                </React.Fragment>
              }
            >
              <Input
                type="text"
                label="Ip To Validate"
                id="ip-validate"
                value={ipAddress}
                onChange={handleIpRangeChange}
                required
                error={isError.ipAddress}
                helperText={isError.ipAddress}
                disabled = {!linuxCredential}
              >
                Ip To Validate *
              </Input>
            </Tooltip>
            {/* </ThemeProvider> */}
          </span>
        </Grid>
        </Grid>
        <Grid container spacing={3} className={styles.containerSpacing}>
        <Grid item xs={12} md={6}>
          <span className={styles.IPTooltip}>
            {/* <ThemeProvider theme={theme}> */}
            <Tooltip
              className={styles.tooltip}
              open={ipListOpen}
              onClose={() => setIpListOpen(false)}
              onOpen={() => setIpListOpen(true)}
              placement="bottom-end"
              title={
                <React.Fragment>
                  <p>
                    <b>Enter IP Address only</b>{" "}
                  </p>
                  <b>{"Single IP Address"}</b>
                  <em>{"(e.g. 192.168.x.xx)"}</em>{" "}
                  <p>
                    <b>{" Multiple IP Address"}</b>{" "}
                    {"(e.g. 192.168.x.x,192.168.x.x)"}
                  </p>{" "}
                </React.Fragment>
              }
            >
              <Input
                type="text"
                label="IP List"
                id="ip-range"
                value={ipRange}
                onChange={handleIpListChange}
                required
                error={isError.ipList}
                helperText={isError.ipList}
                disabled = {editLinuxIpRange}
              >
                IP List
              </Input>
            </Tooltip>
            {/* </ThemeProvider> */}
          </span>
          </Grid>
          <Grid item xs={12} md={6} className={styles.removeItemSpacing}>
          <Button
            variant={"contained"}
            className={styles.borderLess}
            onClick={() => {
              setEditLinuxIpRange((editLinuxIpRange:any) => !editLinuxIpRange)
            }}
            id="edit-ip-btn"
            color="primary"
            data-testid="edit-ip-button"
          >
            <img
              className={styles.EditIcon}
              src={process.env.PUBLIC_URL + "/icons/svg-icon/Edit-icon.svg"}
              alt="edit icon"
            />
          </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={styles.containerSpacing}>
        <Grid item xs={12} className={styles.ActionButtons}>
          {/* <Button
            className={styles.borderLess}
            variant={"contained"}
            id="back-btn"
            onClick={handleBack}
            color="primary"
            data-testid="cancel-button"
          >
            back
          </Button>
          {param != undefined && !param.editLinuxData ? (
            <Button
              className={styles.borderLess}
              variant={"contained"}
              id="skip-btn"
              onClick={handleSkip}
              color="primary"
              data-testid="cancel-button"
            >
              skip
            </Button>
          ) : null} */}
          {/* <AlertBox
            DialogTitle={""}
            open={showDialogBox}
            dialogBoxMsg={dialogBoxMsg}
            pathName={""}
            handleOkay={handleOkay}
            cancelButtonPath={""}
            closeButtonPath={""}
            buttonName={"Yes"}
            CloseButtonName={"No"}
            handleCancel={handleCancel}
            handleClose={handleClose}
          ></AlertBox> */}
          {/* <AlertBox
            DialogTitle={""}
            open={showDialogBox}
            dialogBoxMsg={dialogBoxMsg}
            pathName={""}
            handleOkay={handleOkay}
            // cancelButtonPath={handleClose}
            // closeButtonPath={handleClose}
            buttonName={"Yes"}
            CloseButtonName={"No"}
            handleCancel={handleClose}
            handleClose={handleClose}
          ></AlertBox> */}

          <Button
            type="button"
            id="test-connection-btn"
            color="primary"
            variant={"contained"}
            onClick={onClickTestConnection}
            // disabled={!loginCheckBox}
          >
            {param != undefined && param.editLinuxData
              ? "Retry"
              : "Test Connection"}
          </Button>
          {/* <Button
            onClick={handleSubmitDialogBox}
            id="next-btn"
            color="primary"
            variant={"contained"}
            data-testid="ok-button"
            disabled={!loginCheckBox ? loginCheckBox || ipAddress == "" : submitDisabled}
          >
            next
          </Button>
          <Button
            className={styles.borderLess}
            variant={"contained"}
            onClick={handleCancel}
            id="cancel-btn"
            color="primary"
            data-testid="cancel-button"
          >
            cancel
          </Button> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default Linux_Network;
