import React from "react";
import App from "./App";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import './index.css';
import FallbackPage from "./containers/FallbackPage/FallbackPage";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0,

  integrations: [new Sentry.Replay()],
});

function handleBoundaryError(error:any,errorInfo:any){
  Sentry.captureException(error)
}
const container = document.getElementById("root") as HTMLElement;
const rootContainer = createRoot(container!); // createRoot(container!) if you use TypeScript
rootContainer.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={FallbackPage} onError={handleBoundaryError}>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </ThemeProvider>
);
