import React, { useEffect, useState } from 'react';
import classes from "./DeleteTarget.module.css";
import * as routeConstants from '../../../common/RouteConstants';
import { Button } from "../../../components/UI/Form/Button/Button";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import VisibilityIcon from '@mui/icons-material/Visibility';
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from "@mui/icons-material/GetApp";
import Alert from "../../../components/UI/Alert/Alert";
import CloseIcon from '@mui/icons-material/Close';
import Input from "../../../components/UI/Form/Input/Input";
import ClearIcon from "@mui/icons-material//Clear";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { saveAs } from "file-saver";
import Cookies from "js-cookie";
import logout from "../../Auth/Logout/Logout";
import { RA_REPORT_DOWNLOAD } from "../../../config/index";
import { GET_LATEST_TARGET } from '../../../graphql/mutations/AdminUI';
import * as msgConstant from "../../../common/MessageConstants";
import { sentry_error_catch } from '../../../common/sentry_error_catch';

const DeleteTarget: React.FC = (props:any) => {
  const history = useNavigate();
  const session = Cookies.getJSON("ob_session");
  const [showBackDrop, setShowBackDrop] = useState(false);
  const timeFormat = "MM/DD/YYYY hh:mm a";
  const moment = require("moment-timezone");
  const contentType = "application/json";
  const [newData, setNewData] = useState<any>([]);
  const [token, setToken] = useState<any>("");
  const location: any = useLocation();
  const [targetFilter,setTargetFilter] = useState<any>("");
  const [searchData,setSearchData] = useState<any>([]);
  const [formState, setFormState] = useState({
    isDelete: false,
    isSuccess: false,
    isFailed: false,
    errMessage: "",
  })
  const [getDeleteTarget,{data:deleteTarget}] = useMutation(GET_LATEST_TARGET, {
    onCompleted: (data:any) => {
      setShowBackDrop(false);
      createTableDataObject(data.PartnerStatsAdminPage.targetField)
    },
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  })

  const headerObj = {
    "Content-Type": contentType,
    Authorization: token,
  }
  useEffect(() => {
    setShowBackDrop(true);
    getDeleteTarget({
      variables: {
        token: session,
        sort: "id:desc"
      }
    })
  }, [])

  // array to display the column.
  const column: any = [
    {title: "Partner Name", key: "partner_name"},
    { title: "Target Name", key: "target_name" },
    { title: "Client Name", key: "client_name" },
    { title: "Scan Type", key: "scan_type" },
    {title: "Host", key:"host"},
    { title: "Scan Scheduled", key: "scan_scheduled" },
    { title: "Report Generated", key: "report_generated_date" },
    { title: "Status", key: "scan_status" },
    { title: "Delete Status", key: "delete_status" },
    {
      key: "Download",
      title: "",
      icon: <GetAppIcon className={classes.iconStyle} />,
      tooltip: "Download",
      displayIcon: (rowData: any) => {
        return rowData.report_generated_date !== "";
      },
    },
    {
      key: "View Target Task",
      title: "",
      icon: <VisibilityIcon />,
      tooltip: "View Sub Target  Details",
      displayIcon: (rowData: any) => true,
    },
  ];
  //this effect gets called after every initial render. 

  // function to handel click event to action button 
  const onRowClick:any = (rowData: any, val: string) => {
    if(val === "Download"){
      onDownload(rowData);
    }else if(val === "View Target Task"){
      history(routeConstants.ADMIN_SUB_TARGET_DETAILS, {state: {rowData,page:"DeleteTarget"}});
    }
  }
  const onDownload = async (rowData: any) => {
    handleAlertClose();
    if (Cookies.getJSON("ob_session")) {
      setShowBackDrop(true);
      let intTargetId = parseInt(rowData.id);
      const DocUrl = `${RA_REPORT_DOWNLOAD}?cid=${parseInt(rowData.client_id)}&tid=${intTargetId}`;
      fetch(DocUrl, {
        method: "GET",
      })
        .then((response: any) => {
          response.blob().then((blobData: any) => {
            saveAs(blobData, rowData.target_name.replace(/[^a-zA-Z0-9]/g, ""));
            setShowBackDrop(false);
          });
        })
        .catch((err:any) => {
          sentry_error_catch(err,setShowBackDrop,setFormState)
        });
    } else {
      logout();
    }
  };
  // data object creation to display in table
  const createTableDataObject = async (data: any,range?:boolean) => {
    let arr: any = [];
    if (data && data.length !== 0) {
      if (data !== 0) {
        data.forEach((element: any, i: any) => {
          if(element.deletedStatus === "True" ){
            const obj: any = {};
            obj["id"] = element.id;
            obj["delete_status"] = element.deletedStatus === "True" ? "Deleted" : "Not Deleted" ;
            obj["client_name"] = element.clientName ? element.clientName : "";
            obj["scan_scheduled"] = moment(element.startDate).format(timeFormat );
            obj["partner_name"] = element.partnerName ? element.partnerName : "";
            obj["report_generated_date"] = element.reportCreationDate ? moment(element.reportCreationDate).format(timeFormat ): "";
            obj["published_flag"] = element.publishedFlag ? element.publishedFlag : "";
            obj["client_id"] = element.clientId ? element.clientId : "";
            obj['scan_status'] = element.reportCreationDate && element.targetStatus === "Generating Report" ? "Report Generated" : element.targetStatus ? element.targetStatus : "";
            obj['scan_type'] = element.scanType ? element.scanType : "";
            obj['target_name'] = element.targetName ? element.targetName : "";
            obj['host'] = element.host ? element.host : "";
            arr.push(obj);
          }
        });
      }
    }
    setNewData(arr);
    setSearchData(arr);
  }

  // to handle delete event.
  
 
  const backToList = () => {
    history(routeConstants.ADMIN_DASHBOARD);
  }
  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isDelete: false,
      isSuccess: false,
      isFailed: false,
      errMessage: ""
    }))
  }
  
  const enableBackdrop = () => {
    if (showBackDrop) {
      return true;
    } else {
      return false;
    }
  }
  const handleTargetFilterChange = (event:any) => {
      setTargetFilter(event.target.value)
   }
   const searchResetHandler = (event:any) => {
     setTargetFilter("")
     handleAlertClose();
     setSearchData(newData)
   }
   const searchHandler = (event:any) => {
    handleAlertClose();
    if(targetFilter.trim().length > 0){
       const search:any = []
       newData.forEach((element:any) => {
         if(element["target_name"].includes(targetFilter)){
           search.push(element)
         }
       });
       setSearchData(search)
    }else{
      setFormState((formState) => ({
        ...formState,
        isDelete: false,
        isSuccess: false,
        isFailed: true,
        errMessage: msgConstant.SEARCH_TARGET_NAME_EMPTY,
       }))
    }
   }
   
  return <React.Fragment>
    <CssBaseline />
    {showBackDrop ? <SimpleBackdrop /> : null}
    <Typography component="h5" variant="h1">
      Delete Targets
    </Typography>
    <Grid container>
      <Grid item xs={12} sm={10} className={classes.FilterWrap}>
        <span style={{ width: "230px" }}>
          <Grid item md={12} xs={12}>
            <Input
              // required={true}
              id="target-id"
              type="search"
              name="deleteTarget"
              label="Search Target Name"
              variant="outlined"
              value={targetFilter}
              onChange={handleTargetFilterChange}
              error
            />
          </Grid>
        </span>
        <div className={classes.FilterWrap1}>
          <span>
            <Button id="search" onClick={searchHandler}>
              Search <SearchIcon />
            </Button>
          </span>
        </div>
        <div className={classes.FilterWrap2}>
          <span>
            <Button id="reset" color="secondary" onClick={searchResetHandler}>
              RESET <ClearIcon />
            </Button>
          </span>
        </div>
      </Grid>
      <Grid item xs={12} sm={2} className={classes.FilterAddWrap}>
        <div className={classes.ButtonGroup2}>
          <Button
            id="cancel-button"
            className={classes.BackToButton}
            variant={"contained"}
            onClick={backToList}
            color="secondary"
          >
            <img
              src={`${process.env.PUBLIC_URL}/icons/svg-icon/back-list.svg`}
              alt="user icon"
            />
            &nbsp; Back to List
          </Button>
        </div>
      </Grid>
    </Grid>
    <Grid>
      {formState.isFailed ? (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <strong>{formState.errMessage}</strong>
        </Alert>
      ) : null}
      <Paper className={classes.paper}>
        <div className={classes.ScrollTable}>
          {searchData.length !== 0 ? (
            <MaterialTable
              data={searchData}
              columns={column}
              pageSize={10}
              onButtonClick={onRowClick}
           />
          ) : !enableBackdrop() ? (
            <Typography component="h5" variant="h3">
              You don't have any Deleted Target 
            </Typography>
          ) : null}
        </div>
      </Paper>
    </Grid>
  </React.Fragment>
}

export default DeleteTarget;