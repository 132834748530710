import React, { useState, useContext, useEffect } from "react";
import styles from "./RaStepper.module.css";
import {
  makeStyles,
  createStyles,
  withStyles,
} from "@mui/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepConnector from "@mui/material/StepConnector";
import { Button } from "../../../../components/UI/Form/Button/Button";
import StepLabel from "@mui/material/StepLabel";
import { StepButton } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import {
  useQuery,
  useMutation,
  ApolloError,
  useLazyQuery,
} from "@apollo/client";
import { RA_STEPPER } from "../../../../graphql/mutations/RaStepper";
import stepper from "../../common/raStepperList.json";
import * as routeConstants from "../../../../common/RouteConstants";
import theme from "../../../../theme";
import rarerunStepperList from "../../common/raRerunStepperList.json"
import { getActiveFormStep } from "../../../../services/Data";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

export default function RaStepper(props: any) {
  var stepperObject = stepper;
  const nextValue = useQuery(RA_STEPPER);
  const [propsData, setPropsData] = useState<any>();
  const history = useNavigate();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState<any>(0);
  const [step, setStep] = useState<number>(0);

  const allSteps2: any = [
    {
      title: "Target",
      path: routeConstants.TARGET,
    },
    {
      title: "VPN Connect",
      path: routeConstants.VPN_CONNECT,
    },
    // {
    //   title: "Linux Network",
    //   path: routeConstants.LINUX_NETWORK,
    // },
    // {
    //   title: "Windows Network",
    //   path: routeConstants.WINDOWS_NETWORK,
    // },
    {
      title:"Login Credentails",
      path: routeConstants.LOGIN_CREDENTIALS,
    },
    {
      title: "Scan Configuration",
      path: routeConstants.TASK_DETAILS,
    },

  ];
  const rerun: any = [
    {
      title: "Target",
      path: routeConstants.TARGET,
    },
    {
      title: "VPN Connect",
      path: routeConstants.VPN_CONNECT,
    },
    // {
    //   title: "Windows Network",
    //   path: routeConstants.WINDOWS_NETWORK,
    // },
    // {
    //   title: "Linux Network",
    //   path: routeConstants.LINUX_NETWORK,
    // },
    {
      title:"Login Credentails",
      path: routeConstants.LOGIN_CREDENTIALS,
    },
    {
      title: "Scan Configuration",
      path: routeConstants.TASK_DETAILS,
    },

  ];
  let RerunStepperObject = rarerunStepperList;

  function getSteps() {
    let stepArray: any = [];
    try {
    if (nextValue.data && nextValue.data!=undefined && nextValue.data.propsData != undefined && nextValue.data.propsData.targetName != undefined && nextValue.data.propsData.targetName.includes('windows')) {
      stepArray = rerun
    }
    else {

      stepArray = allSteps2
    }
  }
  catch {
    stepArray = allSteps2
  }
    for (var i = 0; i < stepArray.length; i++) {
      if (stepArray[i].title === "Dashboard" || stepArray[i].title === "Logout") {
        stepArray.splice(i, 1);
      }
    }
  
    return stepArray;
  }

  useEffect(() => {
    if (nextValue.data) {
      if (nextValue.data.propsData.reRun && nextValue.data.propsData.targetName.includes('windows')) {
        if (activeStep !== nextValue.data.activeStep) {
         
          for (let index in rerun) {
            if (nextValue.data.raStep === rerun[index].title) {
              setActiveStep(nextValue.data.activeStep);

              setStep(nextValue.data.activeStep);
              setPropsData(nextValue.data.propsData)
            }
          }
        }
      } else {
        if (activeStep !== nextValue.data.activeStep) {
          for (let index in allSteps) {
            if (nextValue.data.raStep === allSteps[index].title) {
              setActiveStep(nextValue.data.activeStep);
              setStep(nextValue.data.activeStep);
              setPropsData(nextValue.data.propsData)
            }
          }
         
        }
      }
    }
  }, [nextValue.data]);
  const allSteps = getSteps();

  const handleTab = (e: any, i: number, label: any) => {
    setActiveStep(i);
    setStep(i);
    e.preventDefault();
    history(label.path, propsData);
  };
  return (
    <div className={classes.root}>
     <Stepper
      className={styles.ReactStepper}
      activeStep={activeStep}
      >
        {allSteps.map((label: any, i: number) => (
          <Step key={label.title} className={styles.ReactStep}>
            <StepButton
              id={label.title}
              color={Number(step) === i ? "primary" : "secondary"}
            >
              {label.title}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
