import React, { useState } from "react";
import Pagination from "../Pagination/Pagination";
import styles from "./MaterialTable.module.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

interface Column {
  key: string;
  title: string;
  render?: (value: any) => React.ReactNode;
  icon?: ((rowData: any) => React.ReactNode) | React.ReactNode;
  tooltip?: string;
  disable?: (rowData: any) => boolean;
  displayIcon?: (rowData: any) => boolean; // new property to control display of icon
}

interface Props {
  data: any[];
  columns: Column[];
  pageSize: number;
  onButtonClick?: (rowData: any, columnKey: string) => void;
}

interface SortConfig {
  key: string;
  direction: "ascending" | "descending";
}

const MaterialTable: React.FC<Props> = ({
  data,
  columns,
  pageSize,
  onButtonClick,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);

  const handleSort = (key: string) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig && sortConfig.key === key) {
      direction =
        sortConfig.direction === "ascending" ? "descending" : "ascending";
    }
    setSortConfig({ key, direction });
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleButtonClick = (rowData: any, columnKey: string) => {
    if (onButtonClick) {
      onButtonClick(rowData, columnKey);
    }
  };

  const sortedData = React.useMemo(() => {
    if (!sortConfig) {
      return data;
    }

    return [...data].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue < bValue) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }, [data, sortConfig]);

  const pageCount = Math.ceil(sortedData.length / pageSize);
  const displayedData = sortedData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const PageSize = 1;
  return (
    <div>
      <table>
        <thead style={{ backgroundColor: "#fcfaff", color: "#002F60" }}>
          <tr>
            {columns.map(({ key, title }, index) => (
              title ? (
                <th key={key} onClick={() => handleSort(key)}>
                  {title}
                  {sortConfig && sortConfig.key === key && (
                    <span>
                      {sortConfig.direction === "ascending" ? "↑" : "↓"}
                    </span>
                  )}
                </th>
              ) : (
                <React.Fragment key={index}></React.Fragment>
              )
            ))}
            <th></th> {/* Additional <th> element */}
          </tr>
        </thead>
        <tbody>
          {displayedData.map((rowData, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, columnIndex) => (
                column.title ?
                  <td key={column.key}>
                    {column.key === "status" ? (
                      <>
                        <div className={styles.TooltipWrap}>
                        
                          <div>{rowData[column.key] === "Scheduled-Recurring" ? rowData[column.key].slice(0,9):rowData[column.key]}</div>
                          {typeof column.icon === "function"
                            ? column.icon(rowData)
                            : column.icon}
                        </div>
                      </>
                    ) : (
                      <>
                        {column.render
                          ? column.render(rowData[column.key])
                          : rowData[column.key]}
                      </>
                    )}
                  </td> : <></>
              ))}

              <td className={styles.ButtonColumn}>
                {/* to print icons */}
                <div>
                  {columns.map((column, columnIndex) => (
                    !column.title ?
                      <React.Fragment key={column.key}>
                        {(!column.displayIcon || column.displayIcon(rowData)) &&
                          column.key !== "status" && (
                            <button
                              id={column.key}
                              className={column.displayIcon ? styles.button : ""}
                              onClick={() =>
                                handleButtonClick(rowData, column.key)
                              }
                              disabled={column.disable ? column.disable(rowData) : false}
                              title={column.tooltip}
                            >
                              {typeof column.icon === "function"
                                ? column.icon(rowData)
                                : column.icon}
                            </button>
                          )}
                      </React.Fragment> : <></>
                  ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ float: "right" }}>
        <Pagination
          currentPage={currentPage}
          totalCount={pageCount}
          pageSize={PageSize}
          onPageChange={(page: any) => handlePageChange(page)}
        />
      </div>
    </div>
  );
};

export default MaterialTable;
