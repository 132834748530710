import React, { useState, useEffect } from "react";
import styles from "./PartnerUser.module.css";
import Grid from "@mui/material/Grid";
import { Typography, FormHelperText } from "@mui/material";
import { Button } from "../../../../components/UI/Form/Button/Button";
import CssBaseline from "@mui/material/CssBaseline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Alert from "../../../../components/UI/Alert/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import MaterialTable from "../../../../components/UI/Table/MaterialTable";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";
import Loading from "../../../../components/UI/Layout/Loading/Loading";
import Input from "../../../../components/UI/Form/Input/Input";
import ClearIcon from "@mui/icons-material//Clear";
import SearchIcon from "@mui/icons-material/Search";
import { DialogBox } from "../../../../components/UI/DialogBox/DialogBox";
import { Link } from "react-router-dom";
import {
  useQuery,
  useMutation,
  FetchResult,
  useLazyQuery,
} from "@apollo/client";
import AutoCompleteDropDown from "../../../../components/UI/Form/Autocomplete/Autocomplete";
import * as validations from "../../../../common/validateRegex";
import {
  GET_PARTNER_USER,
  GET_PARTNER_ID_USER,
} from "../../../../graphql/queries/PartnerUser";
import logout from "../../../Auth/Logout/Logout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation } from "react-router-dom";
import * as routeConstant from "../../../../common/RouteConstants";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import {
  SUCCESS,
  UPDATE,
  DELETE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../../common/MessageConstants";
import DeleteIcon from "@mui/icons-material/Delete";
import { DELETE_PARTNER_USER, GET_DELETE_STATUS_USER } from "../../../../graphql/mutations/AdminUI";
import Cookies from "js-cookie";
import { sentry_error_catch } from "../../../../common/sentry_error_catch";
import * as msgConstant from "../../../../common/MessageConstants";

interface partnerValues {
  id: number;
  name: string;
  __typename: string;
}

export const PartnerUser: React.FC = (propsData: any) => {
  const history = useNavigate();
  const location = useLocation();
  const [showBackdrop, setShowBackdrop] = useState(true);
  const [param, setParam] = useState<any>();
  const [password, setPassword] = useState("");
  const [partnerID, setPartnerID] = useState<any>();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [partnerList, setPartnerList] = useState("");
  const [newData, setNewData] = useState([]);
  const [rowData, setRowData] = useState<any>();
  const deleteIcon = "/icons/svg-icon/delete.svg";
  const session = Cookies.getJSON("ob_session");
  const [userFilter,setUserFilter] = useState<any>("");
  const [searchData,setSearchData] = useState<any>([]);
  const [error,setError] = useState<any>(false);
  const [confirmPassError, setConfirmPassError] = useState(false);
  const [openDeleteUserDialogBox,setOpenDeleteUserDialogBox] = useState(false);
  const [isError, setIsError] = useState<any>({
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    email: "",
    phoneNumber: "",
  });
  const [userDeleted, SetUserDeleted] = useState(false);
  const [getDeleteStatusUser] = useMutation(GET_DELETE_STATUS_USER, {
    onCompleted: (data) => {
      setShowBackdrop(false)
      createTableDataObject(data.DeleteUserAdminPage.targetField);
    },
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackdrop,setFormState)
    }
  })

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  let userRole: any;
  if (user) {
    userRole = user.isSuperuser == true ? "SuperUser" : "CompanyUser";
  }
  let partnerdata: any;
  if (location !== null) {
    partnerdata = param ? param.propsData : null;
  }

  useEffect(() => {
    if (location?.state) {
      setParam(location.state[Object.keys(location.state)[0]]);
    }
  }, [location]);

  useEffect(() => {
    getDeleteStatusUser({
      variables: {
        token: session,
        sort: "id:desc"
      }
    })
  }, [])

  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  //table
  const column = [
    { title: "Name", key: "name" },
    { title: "Email", key: "email" },
    { title: "Created On", key: "created_on" },
    { title: "Phone", key: "phone" },
    {
      title: "",
      key: "edit",
      icon: () => (
        <img
          className={styles.EditIcon}
          src={process.env.PUBLIC_URL + "/icons/svg-icon/edit.svg"}
          alt="edit icon"
        />
      ),
      tooltip: "Edit",
    },
    {
      title: "",
      key: "delete",
      icon: () => <DeleteIcon />,
      tooltip: "Delete",
    },
  ];
  const userColumn: any = [
    { title: "Partner Name", key: "partner_name" },
    // { title: "First Name", key: "first_name" },
    // { title: "Last Name", key: "last_name" },
    { title: "User Name", key: "user_name" },
    { title: "Email", key: "email" },
    { title: "Pg User Id",key:"user_pgId"},
    { title: "Pg Partner Id",key:"partner_pgId"},
    { title: "Active Status", key: "active_status" },
    {
      icon: ( <img
          className={styles.EditIcon}
          src={process.env.PUBLIC_URL + deleteIcon}
          alt="delete icon"
      />
      ),
      tooltip: "Delete User",
      key: "deleteuser",
      title: "",
      displayIcon: (rowData: any) => true,
    }
  ]
  const [deleteUser] = useMutation(DELETE_PARTNER_USER);

  const createTableDataObject = (data: any) => {
    // let arr: any = [];
    // data.map((element: any, index: any) => {
    //   let obj: any = {};
    //   obj["id"] = element.node.id;
    //   obj["email"] = element.node.userId.username;
    //   obj["name"] =
    //     element.node.userId.firstName + " " + element.node.userId.lastName;
    //   obj["phone"] = !element.node.mobileNumber
    //     ? "-"
    //     : element.node.mobileNumber;
    //   obj["first_name"] = element.node.userId.firstName;
    //   obj["last_name"] = element.node.userId.lastName;
    //   obj["created_on"] = moment(element.node.userId.dateJoined).format(
    //     "MM/DD/YYYY hh:mm a"
    //   );
    //   if (partnerdata) {
    //     obj["partner_id"] = partnerdata.partner_id;
    //   }
    //   obj["userID"] = element.node.userId.id;
    //   arr.push(obj);
    // });
    // setNewData(arr);
      const arr: any = [];
    if (data && data.length > 0) {
      data.forEach((element: any, i: any) => {
        if(element.activeStatus == 3){
            const obj: any = {};
            obj["active_status"] = element.activeStatus == 3 ? "Deleted" : element.activeStatus ;
            obj["partner_pgId"] = element.pg360PartnerId ? element.pg360PartnerId : "";
            obj["user_pgId"] = element.pg360UserId ? element.pg360UserId : "";
            // obj["created_at"] = moment(element.contact_id.created_at).format(timeFormat );
            obj["partner_name"] = element.partnerName ? element.partnerName : "";
            obj["first_name"] = element.firstName ? element.firstName : "";
            obj["last_name"] = element.lastName ? element.lastName : "";
            obj["user_name"] = obj["first_name"] + obj["last_name"];
            obj["user_role"] = element.pgUserRole ? element.pgUserRole : "";
            obj['email'] = element.emailId ? element.emailId : "";
            arr.push(obj);
        }
      });
    }
    setNewData(arr)
    setSearchData(arr)
  };

  const [
    getpartnerUserDataforCompuser,
    { data: PartnerIDDataforCompuser, loading: loadPartnerIDforCompuser },
  ] = useLazyQuery(GET_PARTNER_USER, {
    onCompleted: (data: any) => {
      setShowBackdrop(false);
      if (data.getPartnerUserDetails.edges.length >= 0) {
        setPartnerID(data.getPartnerUserDetails.edges[0].node.partnerId);
      }
      createTableDataObject(data.getPartnerUserDetails.edges);
    },
    onError: (err:any)=>{
      sentry_error_catch(err,setShowBackdrop,setFormState)
    },
    fetchPolicy: "cache-and-network",
  });

  const [
    getpartnerIDbyUserID,
    { data: PartnerIDData, loading: loadPartnerID },
  ] = useLazyQuery(GET_PARTNER_ID_USER, {
    onCompleted: (data: any) => {
      setShowBackdrop(false);
      if (data.getPartnerUserDetails.edges[0]) {
        setPartnerID(data.getPartnerUserDetails.edges[0].node.partnerId);
        getpartnerUserDataforCompuser({
          variables: {
            orderBy: "user_id__first_name",
            partner: data.getPartnerUserDetails.edges[0].node.partnerId,
            userType: "Partner",
          },
        });
      }
    },
    fetchPolicy: "cache-and-network",
  });

  const [
    getpartnerUserData,
    { data: PartneruserData, loading: loadPartneruser },
  ] = useLazyQuery(GET_PARTNER_USER, {
    onCompleted: (data: any) => {
      setShowBackdrop(false);
      // if(data.getPartnerUserDetails.edges.length > 0) {
      createTableDataObject(data.getPartnerUserDetails.edges);
      // }
    },
    fetchPolicy: "cache-and-network",
  });
  // useEffect(() => {
  //   if (param && param !== null && param.formState) {
  //     setFormState(param.formState);
  //   }
  //   if (param && param !== null && param.partner_id) {
  //     getpartnerUserData({
  //       variables: {
  //         partner: param.partner_id,
  //         userType: "Partner",
  //         orderBy: "user_id__first_name",
  //       },
  //     });
  //   } else if (param && param.propData) {
  //     getpartnerUserData({
  //       variables: {
  //         partner: param.propData.partner_id,
  //         userType: "Partner",
  //         orderBy: "user_id__first_name",
  //       },
  //     });
  //   } else {
  //     getpartnerIDbyUserID({
  //       variables: {
  //         userId: user.isSuperuser == true ? param?.email : user.username,
  //       },
  //     });
  //   }
  // }, [param]);

  // useEffect(() => {
  //   if (param && param !== null && param.formState) {
  //     setFormState(param.formState);
  //   }
  //   if (param && param !== null && param.partner_id) {
  //     getpartnerUserData({
  //       variables: {
  //         partner: param.partner_id,
  //         userType: "Partner",
  //         orderBy: "user_id__first_name",
  //       },
  //     });
  //   } else if (param && param.propData) {
  //     getpartnerUserData({
  //       variables: {
  //         partner: param.propData.partner_id,
  //         userType: "Partner",
  //         orderBy: "user_id__first_name",
  //       },
  //     });
  //   } else {
  //     getpartnerIDbyUserID({
  //       variables: {
  //         // userId: user.isSuperuser == true ? param.email : user.username,
  //         userId:
  //           param != null && param.hasOwnProperty("email")
  //             ? param.email
  //             : user.username,
  //       },
  //     });
  //   }
  // }, [userDeleted]);

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  // useEffect(() => {
  //   if (
  //     formState.isDelete === true ||
  //     formState.isFailed === true ||
  //     formState.isSuccess === true ||
  //     formState.isUpdate === true
  //   ) {
  //     setTimeout(function () {
  //       handleAlertClose();
  //     }, ALERT_MESSAGE_TIMER);
  //   }
  // }, [formState]);

  // if (showBackdrop) return <SimpleBackdrop />;

  function convertDate(inputFormat: any) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  let propuserData = param;
  const handleClickEdit = (rowData: any) => {
    let PartnerUserData = { rowData, propuserData };
    if (user && !user.isSuperuser) {
      history(routeConstant.PARTNER_USER_FORM_EDIT + rowData.id, {
        state: { PartnerUserData },
      });
    }
    if (user && user.isSuperuser) {
      history(routeConstant.PARTNER_USER_FORM_EDIT + rowData.id, rowData);
    }
  };
  const handleClickDelete = () => {
    // SetUserDeleted(false);
    setOpenDeleteUserDialogBox(false);
    setShowBackdrop(true);
    console.log(rowData)
    deleteUser({
      variables: {
        pg360UserId:parseInt(rowData.user_pgId),
      }
    })
      .then((res: any) => {
        setShowBackdrop(false);
        console.log(res)
        if (res.data.deleteUser.status == "User is Deleted") {
          SetUserDeleted(true);
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: true,
            isFailed: false,
            errMessage: msgConstant.PARTNER_USER_DELETE_SUCCESS,
          }));
          getDeleteStatusUser({
            variables: {
              token: session,
              sort: "id:desc"
            }
          })
        }
        if (res.data.deleteUser.status !== "User is Deleted") {
          setShowBackdrop(false);
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: `${msgConstant.PARTNER_USER_DELETE_FAILED} ${rowData.user_name} `,
          }));
        }
      })
      .catch((err:any) => {
        sentry_error_catch(err,setShowBackdrop,setFormState)
      });
  };

  const onRowClick = (rowData: any,key: any) => {
    if (key === "edit") {
      handleClickEdit(rowData);
    }
    if (key === "delete") {
      // handleClickDelete(rowData);
    }
    if(key === "deleteuser"){
      setOpenDeleteUserDialogBox(true);
      setRowData(rowData);
    }
  };
  const handleUserFilterChange = (event:any) => {
    setUserFilter(event.target.value)
   }
   const searchResetHandler = (event:any) => {
     setUserFilter("")
     handleAlertClose();
     setSearchData(newData)
   }
   const searchHandler = (event:any) => {
    if(userFilter.trim().length > 0){
       const search:any = []
       if(newData && newData.length > 0){
        newData.forEach((element:any) => {
          if(element["user_name"] && element["user_name"].includes(userFilter)){
            search.push(element)
          }
        });
        setSearchData(search)
      } 
    }else{
      setFormState((formState) => ({
        ...formState,
        isDelete: false,
        isSuccess: false,
        isFailed: true,
        errMessage: msgConstant.SEARCH_USER_NAME_EMPTY
      }))
    }
   }
   const closeDialogBox = () => {
    setOpenDeleteUserDialogBox(false);
    setRowData({});
   }
   const enableBackdrop = () => {
    if (showBackdrop) {
      return true;
    }else {
      return false;
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      {showBackdrop ? <SimpleBackdrop /> : null}
      <DialogBox
        open={openDeleteUserDialogBox}
        handleOk={handleClickDelete}
        handleCancel={closeDialogBox}
        buttonOk={"Yes"}
        buttonCancel={"No"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.MainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}
      >
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            Please Confirm
          </Typography>
        </div>
        <div className={styles.DialogBoxContext}>
          <p>Are you sure you want to Delete the <b>{rowData?.user_name}</b> User ?</p>
        </div>
      </DialogBox>
      <Typography component="h5" variant="h1">
        {/* {!openEdit ? (
          "Users"
        ) : (
          <div>
            {rowData ? "Edit User: " : "Add User "}
            {rowData ? rowData.name : null}
          </div>
        )} */}
        Delete Users List
      </Typography>
      <Grid container>
          <Grid item xs={12} md={10} className={styles.FilterWrap}>
            <span style={{ width: "250px" }}>
              <Grid item md={12} xs={12}>
                <Input
                  id="user-id"
                  type="search"
                  name="filterUser"
                  label="Search User Name"
                  variant="outlined"
                  value={userFilter}
                  onChange={handleUserFilterChange}
                  // error = {error}
                  style={{borderColor: error? "red !important":""}}
                />
              </Grid>
            </span>
            <div className={styles.FilterWrap1}>
              <span>
                <Button id="search" onClick={searchHandler}>
                  Search <SearchIcon />
                </Button>
              </span>
            </div>
            <div className={styles.FilterWrap2} onClick={searchResetHandler}>
              <span>
                <Button id="reset" color="secondary">
                  RESET <ClearIcon />
                </Button>
              </span>
            </div>
          </Grid>
          <Grid item xs={12} md={2} className={styles.backToListButton}>
            <div className={styles.ButtonGroup1}>
              <div className={styles.FilterInputgotolist}>
                {userRole === "SuperUser" ? (
                  <Button
                    id="back-to-list-btn"
                    className={styles.BackToButton}
                    variant={"contained"}
                    onClick={() => {
                      history(routeConstant.ADMIN_DASHBOARD);
                    }}
                    color="secondary"
                    data-testid="cancel-button"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/icons/svg-icon/back-list.svg"
                      }
                      alt="user icon"
                    />
                    &nbsp; Back to List
                  </Button>
                ) : null}
              </div>
            </div>
          </Grid>
        </Grid>
        {formState.isSuccess ? (
            <Alert
              id="success-alert"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              User <strong>{formState.errMessage}</strong> {SUCCESS}
            </Alert>
          ) : null}
          {formState.isUpdate ? (
            <Alert
              id="success-alert2"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              User <strong>{formState.errMessage}</strong> {UPDATE}
            </Alert>
          ) : null}
          {formState.isFailed ? (
            <Alert
              id="error-alert"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {/* {FAILED} */}
              {formState.errMessage}
            </Alert>
          ) : null}
          {formState.isDelete ? (
            <Alert
              id="success-alert3"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <strong>{formState.errMessage}</strong>
              {/* {DELETE} */}
            </Alert>
          ) : null}
        <Paper className={styles.paper}>
          <div className={styles.AdminScrollTable}>
          {searchData.length !== 0 ? 
            <MaterialTable
              columns={userColumn}
              data={searchData}
              pageSize={10}
              onButtonClick={onRowClick}
            
            /> :!enableBackdrop() ? (
            <Typography component="h5" variant="h3">
              You don't have any Partner User for Deletions
            </Typography>
          ) : null}
          </div>
        </Paper>
      {/* </Grid> */}
    </React.Fragment>
  );
};

export default PartnerUser;
