import React, { useState, useEffect } from "react";
import styles from "./Partner.module.css";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { Button } from "../../../../components/UI/Form/Button/Button";
import CssBaseline from "@mui/material/CssBaseline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Alert from "../../../../components/UI/Alert/Alert";
import { useNavigate, useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";
import * as routeConstant from "../../../../common/RouteConstants";
import Paper from "@mui/material/Paper";
import MaterialTable from "../../../../components/UI/Table/MaterialTable";
import {
  SUCCESS,
  UPDATE,
  DELETE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../../common/MessageConstants";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { GET_PARTNER } from "../../../../graphql/queries/Partners";
import moment from "moment";
import PeopleIcon from "@mui/icons-material/People";
import DeleteIcon from "@mui/icons-material/Delete";
import { DELETE_PARTNER } from "../../../../graphql/mutations/RaPartner";
import { sentry_error_catch } from "../../../../common/sentry_error_catch";

export const Partner: React.FC = (props: any) => {
  const history = useNavigate();
  const location = useLocation();
  const [newData, setNewData] = useState<any>([]);
  const [param, setParam] = useState<any>();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [showBackdrop, setShowBackdrop] = useState<boolean>(false);

  //table
  const column = [
    { title: "Name", key: "name" },
    { title: "Email", key: "email" },
    { title: "Phone", key: "phone" },
    { title: "Created On", key: "created_on" },
    {
      title: "",
      key: "edit",
      icon: () => (
        <img
          className={styles.EditIcon}
          src={process.env.PUBLIC_URL + "/icons/svg-icon/edit.svg"}
          alt="edit icon"
        />
      ),
      tooltip: "Edit",
    },
    {
      title: "",
      key: "addPartnerUser",
      icon: () => <AddCircleIcon className={styles.CircleIcon} />,
      tooltip: "Add Partner User",
    },
    {
      title: "",
      key: "client",
      icon: () => <PeopleIcon className={styles.CircleIcon} />,
      tooltip: "Clients",
    },
    {
      title: "",
      key: "delete",
      icon: <DeleteIcon />,
      tooltip: "Delete",
    },
  ];

  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  const [deletePartner] = useMutation(DELETE_PARTNER);

  const {
    data: Org,
    error: iError,
    loading: loadOrg,
    refetch,
  } = useQuery(GET_PARTNER, {
    variables: {
      orderBy: "-created_date",
    },
    onCompleted: (data: any) => {
      createTableDataObject(data.getPartner.edges);
    },
    onError: (err: any) => {
      sentry_error_catch(err,setShowBackdrop,setFormState)
      history(routeConstant.ADMIN_DASHBOARD, { state: { param } });
    },
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    setParam(param);
    if (param && param !== null && param.formState) {
      setFormState(param.formState);
    }
  }, []);

  useEffect(() => {
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
  }, [formState]);

  function convertDate(inputFormat: any) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  // const getDateAndTime = (utcDate: any) => {
  //   if (utcDate === "" || utcDate === null) {
  //     return null;
  //   } else {
  //     var dateFormat: any = new Date(utcDate);
  //     var hours = dateFormat.getHours();
  //     var minutes = dateFormat.getMinutes();
  //     var ampm = hours >= 12 ? 'PM' : 'AM';
  //     hours = hours % 12;
  //     hours = hours ? hours : 12; // the hour '0' should be '12'
  //     minutes = minutes < 10 ? '0' + minutes : minutes;
  //     var strTime = hours + ':' + minutes + ' ' + ampm;
  //     var dateAndTime = convertDate(new Date(utcDate)) + " " + strTime;
  //     return dateAndTime;
  //   }
  // };

  const createTableDataObject = (data: any) => {
    let arr: any = [];
    data.map((element: any, index: any) => {
      let obj: any = {};
      obj["id"] = element.node.id;
      obj["partner_id"] = element.node.partnerName;
      obj["name"] = element.node.partnerName;
      obj["email"] = element.node.emailId;
      obj["phone"] = element.node.mobileNumber;
      obj["address"] = element.node.address;
      obj["created_on"] = element.node.createdDate
        ? moment(element.node.createdDate).format("MM/DD/YYYY hh:mm a")
        : "-";
      arr.push(obj);
    });
    setNewData(arr);
  };

  // const handleClickOpen = () => {
  //   history(routeConstant.PARTNER_FORM_ADD);
  // };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleClickAdd = (rowData: any) => {
    let viewdata: any = rowData;
    history(routeConstant.PARTNER_USER, {state: {viewdata}});
  };

  const handleClickClient = (rowData: any) => {
    history(routeConstant.CLIENT, {state: {rowData}});
  };

  const handleClickEdit = (rowData: any) => {
    if (
      user &&
      user.user &&
      user.user.role &&
      user.user.role.name === "Company User"
    ) {
      history(routeConstant.PARTNER_FORM_EDIT + rowData.partner_id, {state: {rowData}});
    }
    if (user && user.isSuperuser) {
      history(routeConstant.PARTNER_FORM_EDIT + rowData.partner_id, {state: {rowData}});
    }
  };
  const handleClickDelete = (rowData: any) => {
    setShowBackdrop(true);
    deletePartner({
      variables: {
        id: rowData.partner_id,
      },
    })
      .then((res: any) => {
        setShowBackdrop(false);
        refetch();
        if (res.data.deletePartner.status === "Partner is deleted") {
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: true,
            isFailed: false,
            errMessage: "  " + rowData.name + "  ",
          }));
        }
        if (res.data.deletePartner.status === "Partner is not deleted") {
          setShowBackdrop(false);
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: " Failed to Delete Partner  " + rowData.name + " ",
          }));
        }
      })
      .catch((err:any) => {
        sentry_error_catch(err,setShowBackdrop,setFormState)
      });
  };

  const onRowClick = (rowData:any, key:any) =>{
    if(key === "edit"){
      handleClickEdit(rowData);
    }
    if(key === "client"){
      handleClickClient(rowData);
    }
    if(key === "delete"){
      handleClickDelete(rowData);
    }
    if(key === "addPartnerUser"){
      handleClickAdd(rowData);
    }
  }

  if (loadOrg || showBackdrop) {
    return <SimpleBackdrop />;
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        Partner
      </Typography>
      <Grid className={styles.TableWrap}>
        {/* <Grid container>
          <Grid item xs={12} className={styles.FilterAddWrap}>
            <div className={styles.FilterInput}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleClickOpen}
              >
                <AddCircleIcon className={styles.EditIcon} />
                  &nbsp; Partner
                </Button>
            </div>
          </Grid>
        </Grid> */}
        <Paper className={styles.paper}>
          {formState.isSuccess ? (
            <Alert
              id="success-alert"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Partner <strong>{formState.errMessage}</strong> {SUCCESS}
            </Alert>
          ) : null}
          {formState.isUpdate ? (
            <Alert
              severity="success"
              id="success-alert2"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Partner <strong>{formState.errMessage}</strong> {UPDATE}
            </Alert>
          ) : null}
          {formState.isFailed ? (
            <Alert
              id="error-alert"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {FAILED}
              <strong>{formState.errMessage}</strong>
            </Alert>
          ) : null}
          {formState.isDelete ? (
            <Alert
              id="success-alert3"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <strong>{formState.errMessage}</strong>
              {DELETE}
            </Alert>
          ) : null}
          <MaterialTable
            columns={column}
            data={newData}
            pageSize={25}
            onButtonClick={onRowClick}
            // actions={[
            //   {
            //     icon: () => <img className={styles.EditIcon}
            //       src={
            //         process.env.PUBLIC_URL + "/icons/svg-icon/edit.svg"
            //       }
            //       alt="edit icon"
            //     />,
            //     tooltip: "Edit",
            //     onClick: (event: any, rowData: any) => {
            //       handleClickEdit(rowData, event);
            //     },
            //   },
            //   {
            //     icon: () => <AddCircleIcon className={styles.CircleIcon} />,
            //     tooltip: "Add Partner User",
            //     onClick: (event: any, rowData: any) => {
            //       handleClickAdd(rowData);
            //     },
            //   },
            //   {
            //     icon: () => <PeopleIcon className={styles.CircleIcon} />,
            //     tooltip: "Clients",
            //     onClick: (event: any, rowData: any) => {
            //       handleClickClient(rowData);
            //     },
            //   },
            //   {
            //     icon: () => <DeleteIcon />,
            //     tooltip: "Delete",
            //     onClick: (event: any, rowData: any) => {
            //       handleClickDelete(event, rowData);
            //     },
            //   },
            // ]}
            // options={{
            //   headerStyle: {
            //     backgroundColor: "#fef9f5",
            //     color: "#002F60",
            //   },
            //   actionsColumnIndex: -1,
            //   paging: true,
            //   sorting: true,
            //   search: false,
            //   filter: true,
            //   draggable: false,
            //   thirdSortClick: false,
            //   pageSize: 25,
            //   pageSizeOptions: [25, 50, 75, 100] // rows selection options
            // }}
          />
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default Partner;
