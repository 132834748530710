import React, { useEffect, useState } from "react";
import styles from "./ClientForm.module.css";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { Button } from "../../../components/UI/Form/Button/Button";
import CssBaseline from "@mui/material/CssBaseline";
import { AddEditForm } from "../../../components/UI/AddEditForm/AddEditForm";
import Input from "../../../components/UI/Form/Input/Input";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Alert from "../../../components/UI/Alert/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import Loading from "../../../components/UI/Layout/Loading/Loading";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_CLIENT,
  UPDATE_CLIENT,
} from "../../../graphql/mutations/Clients";
import GetAppIcon from "@mui/icons-material/GetApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import * as routeConstant from "../../../common/RouteConstants";
import { useNavigate, useLocation } from "react-router-dom";
import logout from "../../Auth/Logout/Logout";
import { GET_CLIENT } from "../../../graphql/queries/Client";
import {
  SUCCESS,
  UPDATE,
  DELETE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../common/MessageConstants";
import * as validations from "../../../common/validateRegex";
import Checkbox from "@mui/material/Checkbox";
import { sentry_error_catch } from "../../../common/sentry_error_catch";

export const Client: React.FC = (props: any) => {
  const history = useNavigate();
  const location = useLocation();
  const [param, setParam] = useState<any>(
    location.state[Object.keys(location.state)[0]]
  );
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loader, setLoader] = useState(false);
  const [createFlag, setCreateFlag] = useState(false);
  const [rowData, setRowData] = useState(false);
  const partner = JSON.parse(localStorage.getItem("partnerData") || "{}");
  const [emailUpdates, setEmailUpdates] = React.useState({
    checkedB: false,
  });

  const [getClients, { loading: ipLoading }] = useLazyQuery(GET_CLIENT, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setRowData(true);
      setEmail(data.getClient.edges[0].node.emailId);
      setName(data.getClient.edges[0].node.clientName);
      setPhoneNumber(data.getClient.edges[0].node.mobileNumber);
      setEmailUpdates({
        ...emailUpdates,
        ["checkedB"]: !data.getClient.edges[0].node.mailSend,
      });
    },
    onError: (err:any)=>{
      sentry_error_catch(err,setLoader,setFormState)
    }
  });

  useEffect(() => {
    if (param) {
      getClients({
        variables: {
          orderBy: "client_name",
          clientName: param.name,
        },
      });
      setRowData(true);
      setEmail(param.email != "-" ? param.email : "");
      setName(param.name ? param.name : "");
      setPhoneNumber(param.phone != "-" ? param.phone : "");
    }
  }, []);

  const [isError, setIsError] = useState<any>({
    address: "",
    email: "",
    phoneNumber: "",
  });

  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  const [createClient] = useMutation(CREATE_CLIENT);
  const [updateClient] = useMutation(UPDATE_CLIENT);

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  useEffect(() => {
    if (
      formState.isDelete === true ||
      formState.isFailed === true ||
      formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, ALERT_MESSAGE_TIMER);
    }
    if (formState.isSuccess === true || formState.isUpdate === true) {
      if (param != null) {
        param.formState = formState;
      }
      if (param === null || param === undefined) {
        let data = {
          from: "client-form",
          formState: formState,
        };
        setParam(data);
        // param = [];
        // param.from = "client-form";
        // param.formState = formState;
      }
    }
  }, [formState]);
  if (ipLoading || loader) return <Loading />;

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("event.target.name", event.target.name);
    setEmailUpdates({
      ...emailUpdates,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "Name") {
      setName(event.target.value);
      let err = event.target.value === "" || null ? "Required" : "";
      setIsError((error: any) => ({
        ...error,
        name: err,
      }));
    }
    if (event.target.name === "email") {
      setEmail(event.target.value);

      if (
        event.target.value &&
        !validations.EMAIL_REGEX.test(event.target.value)
      ) {
        let errors = "Please enter valid email address.";
        setIsError((error: any) => ({
          ...error,
          email: errors,
        }));
      } else {
        setIsError({ error: null });
      }
    }
    if (event.target.name === "phoneNumber") {
      setPhoneNumber(event.target.value);
    }
  };

  const handleInputErrors = () => {
    let foundErrors = false;
    if (!name) {
      let err = "Name is Required";
      setIsError((error: any) => ({
        ...error,
        name: err,
      }));
      foundErrors = true;
    }

    if (email && !validations.EMAIL_REGEX.test(email)) {
      let errors = "Please enter valid email address.";
      setIsError((error: any) => ({
        ...error,
        email: errors,
      }));
      foundErrors = true;
    }

    return foundErrors;
  };

  const handleSubmit = () => {
    if (!handleInputErrors()) {
      if (props.location.pathname.includes("/client-form/edit")) {
        updateIntoClient();
      } else {
        insertIntoClient();
      }
    }
  };

  const insertIntoClient = () => {
    setLoader(true);
    createClient({
      variables: {
        input: {
          partnerId: partner.partnerId,
          clientName: name,
          mobileNumber: phoneNumber,
          emailId: email,
          mailSend: !emailUpdates.checkedB,
        },
      },
    })
      .then((res: any) => {
        setLoader(false);
        setFormState((formState) => ({
          ...formState,
          isSuccess: true,
          isUpdate: false,
          isDelete: false,
          isFailed: false,
          errMessage: " " + name + " ",
        }));
        backToList();
      })
      .catch((err:any) => {
        setLoader(false);
        let error = err.message;
        if (error.includes("duplicate key value violates unique constraint")) {
          error = " Client Name Already Exists ";
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: error,
          }));
        } else {
          sentry_error_catch(err,setLoader,setFormState)
        }
        
      });
  };

  const updateIntoClient = () => {
    if (param) {
      setLoader(true);
    }
    updateClient({
      variables: {
        id: param.clientId,
        ClientInput: {
          clientName: name,
          mobileNumber: phoneNumber,
          emailId: email,
          mailSend: !emailUpdates.checkedB,
        },
      },
    })
      .then((res: any) => {
        setLoader(false);
        setFormState((formState) => ({
          ...formState,
          isSuccess: true,
          isUpdate: false,
          isDelete: false,
          isFailed: false,
          errMessage: " " + name + " ",
        }));
        backToList();
      })
      .catch((err) => {
        setLoader(false);
        let error = err.message;
        if (error.includes("duplicate key value violates unique constraint")) {
          error = " Client Name Already Exists ";
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: error,
          }));
        } else {
          sentry_error_catch(err,setLoader,setFormState)
        }
      });
  };

  const backToList = () => {
    history(routeConstant.CLIENT, param);
    setIsError({ error: null });
    setRowData(false);
    setName("");
    setEmail("");
    setPhoneNumber("");
    setCreateFlag(false);
  };

  if (createFlag) return <Loading />;

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        {rowData ? "Edit Client " : "Add Client"}
      </Typography>

      <AddEditForm handleOk={handleSubmit} handleCancel={backToList}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {formState.isFailed ? (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {FAILED}
                {formState.errMessage}
              </Alert>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              type="text"
              label="Name*"
              name="Name"
              value={name}
              onChange={handleChange}
              error={isError.name}
              helperText={isError.name}
            >
              Name
            </Input>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className={rowData ? styles.disfield : styles.test}
          >
            <Input
              type="text"
              label="Email"
              name="email"
              value={email}
              disabled={rowData}
              onChange={handleChange}
              error={isError.email}
              helperText={isError.email}
            >
              E-mail
            </Input>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              type="text"
              label="Phone Number"
              name="phoneNumber"
              value={phoneNumber}
              onChange={handleChange}
              error={isError.phoneNumber}
              helperText={isError.phoneNumber}
            >
              Phone Number
            </Input>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              className={styles.CheckboxLabel}
              control={
                <Checkbox
                  checked={emailUpdates.checkedB}
                  onChange={handleCheckBoxChange}
                  color="primary"
                  name="checkedB"
                  // value={emailUpdates}
                />
              }
              label="Do Not Email"
            />
          </Grid>
        </Grid>
      </AddEditForm>
    </React.Fragment>
  );
};

export default Client;
