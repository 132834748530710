import { gql } from "@apollo/client";

export const PENTEST_SCAN_POLICY = gql`
  query penTestScanPolicy {
    getPentestScanPolicy {
      edges {
        node {
          policyType
        }
      }
    }
  }
`;

export const CREATE_PENTEST_TARGET = gql`
  mutation createPenTest($TargetInput: TargetInput!) {
    createPenTest(input: $TargetInput) {
      targetField {
        id
        targetName
        partner {
          id
        }
        client {
          id
        }
        scanPolicyType
        host
      }
      status
    }
  }
`;

export const GET_SCAN_SHEDULE_DATA = gql`query getScansheduldata($interval: String, $scanName: String, $host: String, $scanStart: Int, $scanType: String, $noOfScans: Int,$scanRunDateQuarterlyMonth: String) {
  getScansheduldata(interval:$interval,scanName:$scanName,host:$host,scanStart:$scanStart,noOfScans:$noOfScans,scanType:$scanType,scanRunDateQuarterlyMonth:$scanRunDateQuarterlyMonth) {
    host
    scanName
    scanType
    scanStart
   }
 }`