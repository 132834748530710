import React, { useEffect, useState } from "react";
import * as routeConstants from "../../../common/RouteConstants";
import classes from "./DeleteClient.module.css";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import { Button } from "../../../components/UI/Form/Button/Button";
import { DialogBox } from "../../../components/UI/DialogBox/DialogBox";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Alert from "../../../components/UI/Alert/Alert";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import Input from "../../../components/UI/Form/Input/Input";
import ClearIcon from "@mui/icons-material//Clear";
import SearchIcon from "@mui/icons-material/Search";
import { useLazyQuery,useMutation } from '@apollo/client';
import { DELETE_CLIENT, DELETE_PARTNER, GET_DELETE_STATUS_CLIENT} from "../../../graphql/mutations/AdminUI";
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { sentry_error_catch } from "../../../common/sentry_error_catch";
import * as msgConstant from "../../../common/MessageConstants";

export const DeleteClient = () => {
  const [showBackDrop, setShowBackDrop] = useState(true);
  const history = useNavigate();
  const timeFormat = "MM/DD/YYYY hh:mm a";
  const session = Cookies.getJSON("ob_session");
  const moment = require("moment-timezone");
  const deleteIcon = "/icons/svg-icon/delete.svg";
  const [newData, setNewData] = useState<any>([]);
  const [clientFilter,setClientFilter] = useState<any>("");
  const [searchData,setSearchData] = useState<any>([]);
  const [error,setError] = useState<any>(false);
  const [openDeleteClientDialogBox,setOpenDeleteClientDialogBox] = useState<any>(false);
  const [rowData,setRowData] = useState<any>({});
  const [formState, setFormState] = useState({
    isDelete: false,
    isSuccess: false,
    isFailed: false,
    errMessage: "",
  })

//   query for getting all partners.
  const [clientDelete] = useMutation(DELETE_CLIENT);
  const [getDeleteStatusClient] = useMutation(GET_DELETE_STATUS_CLIENT, {
    onCompleted: (data) => {
      setShowBackDrop(false)
      createTableDataObject(data.DeleteClientAdminPage.targetField);
    },
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  })

  const enableBackdrop = () => {
    if (showBackDrop) {
      return true;
    }else {
      return false;
    }
  }

  // runs after every initial render. 
  useEffect(() => {
    getDeleteStatusClient({
      variables: {
        token: session,
        sort: "id:desc"
      }
    })
  }, [])

  // column array for display table
  const column: any = [
    { title: "Partner Name", key: "partner_name" },
    { title: "Client Name", key: "client_name" },
    { title: "Email", key: "email" },
    { title: "Pg Client Id",key:"client_pgId"},
    { title: "Pg Partner Id",key:"partner_pgId"},
    { title: "Active Status", key: "active_status" },
    {
      icon: ( <img
          className={classes.EditIcon}
          src={process.env.PUBLIC_URL + deleteIcon}
          alt="delete icon"
      />
      ),
      tooltip: "Delete Client",
      key: "deleteclient",
      title: "",
      displayIcon: (rowData: any) => true,
    }
  ]
  // function run after action buttion is clicked.
  const onRowClick:any = (rowData: any,val: any) => {
    if (val === 'deleteclient') {
      setOpenDeleteClientDialogBox(true);
      setRowData(rowData);
    }
  }
  // function for data to be displayed.
  const createTableDataObject = (orgPartData: any) => {
    const arr: any = [];
    if (orgPartData && orgPartData.length > 0) {
      orgPartData.forEach((element: any, i: any) => {
        if(element.activeStatus == 3){
           const obj: any = {};
           obj["active_status"] = element.activeStatus == 3 ? "Deleted" : element.activeStatus ;
           obj["partner_pgId"] = element.pg360PartnerId ? element.pg360PartnerId : "";
           obj["client_pgId"] = element.pg360ClientId ? element.pg360ClientId : "";
           // obj["created_at"] = moment(element.contact_id.created_at).format(timeFormat );
           obj["partner_name"] = element.partnerName ? element.partnerName : "";
           obj["client_name"] = element.clientName ? element.clientName : "";
           obj['email'] = element.email ? element.email : "";
           arr.push(obj);
        }
      });
    }
    setNewData(arr)
    setSearchData(arr)
  }
  const backToList = () => {
    history(routeConstants.ADMIN_DASHBOARD);
  }
  const handlePartnerFilterChange = (event:any) => {
   setClientFilter(event.target.value)
  }
  const searchResetHandler = (event:any) => {
    setClientFilter("")
    setFormState((formState) => ({
      ...formState,
      isDelete: false,
      isSuccess: false,
      isFailed: false,
      errMessage: ""
    }))
    setSearchData(newData)
  }
  const searchHandler = (event:any) => {
   if(clientFilter.trim().length > 0){
      const search:any = []
      if(newData && newData.length > 0){
        newData.forEach((element:any) => {
          if(element["client_name"] && element["client_name"].includes(clientFilter)){
            search.push(element)
          }
        });
        setSearchData(search)
      }
   }else{
    setFormState((formState) => ({
      ...formState,
      isDelete: false,
      isSuccess: false,
      isFailed: true,
      errMessage: msgConstant.SEARCH_CLIENT_NAME_EMPTY,
    }))
   }
  }
  const confirmDelete = () => {
    setOpenDeleteClientDialogBox(false);
    setShowBackDrop(true);
    if(rowData && rowData.client_pgId){
      clientDelete({
        variables: {
          pg360ClientId:parseInt(rowData.client_pgId),
        }
      }).then((data:any) => {
        setShowBackDrop(false);
        if(data.data.deleteClient.status === "Client is deleted"){
          setFormState((formState) => ({
            ...formState,
            isDelete: false,
            isSuccess: true,
            isFailed: false,
            errMessage: `${rowData.client_name} ${msgConstant.CLIENT_DELETE_SUCCESS}`
          }))
          getDeleteStatusClient({
            variables: {
                token: session,
                sort: "id:desc"
            }
          })
        }else if(data.data.deleteClient.status === "Client is not deleted"){
          setFormState((formState) => ({
            ...formState,
            isDelete: false,
            isSuccess: false,
            isFailed: true,
            errMessage: `${msgConstant.CLIENT_DELETE_FAILED} ${rowData.client_name} `
          }))
        }else{
          setFormState((formState) => ({
            ...formState,
            isDelete: false,
            isSuccess: false,
            isFailed: true,
            errMessage: "Something Went Wrong!"
          }))
        }
      }).catch((err:any) => {
        sentry_error_catch(err,setShowBackDrop,setFormState)
      })
    }
  }
  const closeDialogBox = () => {
   setOpenDeleteClientDialogBox(false);
   setRowData({});
  }
  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isDelete: false,
      isSuccess: false,
      isFailed: false,
      errMessage: ""
    }))
   }

  return <React.Fragment>
    <CssBaseline />
    {showBackDrop ? <SimpleBackdrop /> : null}
    <DialogBox
      open={openDeleteClientDialogBox}
      handleOk={confirmDelete}
      handleCancel={closeDialogBox}
      buttonOk={"Yes"}
      buttonCancel={"No"}
      classes={{
        root: classes.MainOfficeDialogRoot,
        container: classes.MainOfficeDialogboxContainer,
        paper: classes.MainOfficeDialogboxPaper,
        scrollPaper: classes.MainOfficeScrollPaper,
      }}
    >
      <div className={classes.DialogBoxTitle}>
        <Typography component="h1" variant="h1">
          Please Confirm
        </Typography>
      </div>
      <div className={classes.DialogBoxContext}>
        <p>Are you sure you want to Delete the <b>{rowData.client_name}</b> Client ?</p>
      </div>
    </DialogBox>
    <Typography component="h5" variant="h1">
      Delete Clients List
    </Typography>
    <Grid container>
      <Grid item xs={12} sm={10} className={classes.FilterWrap}>
        <span style={{ width: "250px" }}>
          <Grid item md={12} xs={12}>
            <Input
              id="client-id"
              type="search"
              name="filterClient"
              label="Search Client Name"
              variant="outlined"
              value={clientFilter}
              onChange={handlePartnerFilterChange}
              // error = {error}
              style={{borderColor: error? "red !important":""}}
            />
          </Grid>
        </span>
        <div className={classes.FilterWrap1}>
          <span>
            <Button id="search" onClick={searchHandler}>
              Search <SearchIcon />
            </Button>
          </span>
        </div>
        <div className={classes.FilterWrap2} onClick={searchResetHandler}>
          <span>
            <Button id="reset" color="secondary">
              RESET <ClearIcon />
            </Button>
          </span>
        </div>
      </Grid>
      <Grid item xs={12} sm={2} className={classes.FilterAddWrap}>
        <div className={classes.ButtonGroup2}>
          <Button
            id="cancel-button"
            className={classes.BackToButton}
            variant={"contained"}
            onClick={backToList}
            color="secondary"
          >
            <img
              src={`${process.env.PUBLIC_URL}/icons/svg-icon/back-list.svg`}
              alt="user icon"
            />
            &nbsp; Back to List
          </Button>
        </div>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={12} sm={12}>
        {formState.isSuccess ? (
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <strong>{formState.errMessage}</strong>
          </Alert>
        ) : null}
        {formState.isFailed ? (
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <strong>{formState.errMessage}</strong>
          </Alert>
        ) : null}
      </Grid>
    </Grid>
    <Grid>
      <Paper className={classes.paper}>
        <div className={classes.ScrollTable}>
          {searchData.length !== 0 ? (
            <MaterialTable
              data={searchData}
              columns={column}
              pageSize={10}
              onButtonClick={onRowClick}
            />
          ) : !enableBackdrop() ? (
            <Typography component="h5" variant="h3">
              You don't have any Client for Deletions
            </Typography>
          ) : null}
        </div>
      </Paper>
    </Grid>
  </React.Fragment>
}

export default DeleteClient;
