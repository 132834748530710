import React, { useState, useEffect, useRef } from "react";
import Linux_Network from "../Linux_Network/Linux_Network";
import styles from "./LoginCredential.module.css";
import { Typography, Grid, Tooltip, Paper } from "@mui/material";
import Windows_Network from "../Windows_Network/Windows_Network";
import CssBaseLine from "@mui/material/CssBaseline";
import Cookies from "js-cookie";
import logout from "../../Auth/Logout/Logout";
import { useMutation, useLazyQuery } from "@apollo/client";
import stepper from "../common/raStepperList.json";
import { setActiveFormStep } from "../../../services/Data";
import { setRaStepper } from "../common/SetRaStepper";
import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { CREATE_TARGET, UPDATE_TARGET, DELETE_TARGET } from "../../../graphql/mutations/Target";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import { useNavigate, useLocation } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { Button } from "../../../components/UI/Form/Button/Button";
import * as routeConstant from "../../../common/RouteConstants";
import RaStepper from "../component/RaStepper/RaStepper";
import * as Sentry from "@sentry/react";
import { useErrorBoundary } from "react-error-boundary";


const LoginCredential = () => {
  const { showBoundary } = useErrorBoundary();
  let data = {};
  const history = useNavigate();
  const location = useLocation();
  const client = useApolloClient();
  const [targetConnection, setTargetConnection] = useState({
    linux_test_connection: false,
    windows_test_connection: false
  })
  // const [targetDetails, setTargetDetails] = useState({});
  const linuxRef = useRef<any>(null);
  const windowsRef = useRef<any>(null);
  const [deleteTarget] = useMutation(DELETE_TARGET);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [windowLoginToolTip, setWindowLoginToolTip] = useState(false);
  const [loginToolTip, setLoginToolTip] = useState(false);
  const targetId = JSON.parse(localStorage.getItem("targetId") || "{}");
  const [param, setParam] = useState<any>(
    location?.state ? location.state[Object.keys(location.state)[0]] : null
  );
  const [params, setParams] = useState<any>({});
  const [linuxCredential, setLinuxCredential] = useState(false);
  const [windowsCredential, setWindowsCredential] = useState(false);
  const [certCheckBox, setCertCheckBox] = useState();
  const targetInfo = param ? param.targetInfo : undefined;
  const clientInfo = param ? param.clientInfo : undefined;
  const [targetDetails, setTargetsDetail] = useState({});
  // let targetDetails = {}
  const setTargetsDetails: any = (obj: any) => {
    if (obj?.linuxTargetDetails) {
      obj["linuxTargetDetails"]["linuxTestConnection"] = targetConnection.linux_test_connection;
      setTargetsDetail((targetDetails) => {
        return {
        linuxDetails: obj,
        ...targetDetails
      }})
    } else if (obj?.windowsTargetDetails) {
      obj["windowsTargetDetails"]["windowsTestConnection"] = targetConnection.windows_test_connection;
      setTargetsDetail((targetDetails) => {return {
        windowsDetails: obj,
        ...targetDetails
      }})
    }
    console.log("targetDetails--message", targetDetails);
  }

  const updateTestConnection: any = (connectionObj: any) => {
    setTargetConnection((targetConnection) => {
      return {
        ...targetConnection,
        ...connectionObj
      }
    })
  }

  useEffect(() => {
    if (location?.state) {
      setParam(location.state[Object.keys(location.state)[0]]);
    }
  }, [location]);

  const checkBoxChangeHandler = (event: any) => {
    if (event.target.name === "linux_check") {
      setLinuxCredential(event.target.checked);
    }
    if (event.target.name === "windows_login_check") {
      setWindowsCredential(event.target.checked);
    }
  }
  useEffect(() => {
    setActiveFormStep(2);
    setRaStepper(client, stepper.LoginCredentail.name, stepper.LoginCredentail.value, param);
    if (
      (param != undefined &&
      param.editLinuxData &&
      param.editLinuxData === true) || 
      (param != undefined &&
      param.editWindowsData &&
      param.editWindowsData === true)
    ) {
      console.log("setSubmit")
      // setSubmitDisabled(false);
      setTargetConnection((targetConnection) => {
        return {
          ...targetConnection,
          linux_test_connection: param.editLinuxData,
          windows_test_connection: param.editWindowsData
        }
      });
    }
  },[])

  useEffect(() => {
    if (param) {
      setCertCheckBox(param.checkboxSelected);
      setLinuxCredential(param.linuxLoginSelected);
      setWindowsCredential(param.windowLoginSelected);
    }
  }, [param]);

  useEffect(() => {
    if(targetConnection.linux_test_connection || targetConnection.windows_test_connection){
      setSubmitDisabled(!(targetConnection.linux_test_connection || targetConnection.windows_test_connection));
    }
  }, [targetConnection])

  const removeDataFromLocalStorage = () => {
    localStorage.removeItem("name");
    localStorage.removeItem("targetId");
    localStorage.removeItem("ipRange");
    localStorage.removeItem("ipAddress");
    localStorage.removeItem("re-runTargetName");
    localStorage.removeItem("userName");
    localStorage.removeItem("password");
    localStorage.removeItem("vpnUserName");
    localStorage.removeItem("vpnPassword");
    localStorage.removeItem("vpnFilePath");
    localStorage.removeItem("WinTargetName");
    localStorage.removeItem("LinuxTargetName");
    localStorage.removeItem("subnetMask");
    localStorage.removeItem("LinuxTargetId");
    localStorage.removeItem("winTargetId");
    localStorage.removeItem("updatetargettimestamp");
    localStorage.removeItem("WinIpAddress");
    localStorage.removeItem("initialSubnetMask");
    localStorage.removeItem("initialTargetIpRange");
    localStorage.removeItem("TargetNameTimestamp")
  }
  const handleCancel = () => {
    try{
    if (Cookies.getJSON("ob_session")) {
      let userData = JSON.parse(Cookies.getJSON("ob_user"));
      deleteTarget({
        variables: {
          id: Number(targetId),
          firstName: userData.data.getUserDetails.edges[0].node.firstName,
          lastName: userData.data.getUserDetails.edges[0].node.lastName,
        },
      })
        .then((res: any) => {
          let data = {};
          data = { refetchData: true, clientInfo: clientInfo };
          if (param.previousPage === "client") {
            history(routeConstant.CLIENT, { state: { data } });
            removeDataFromLocalStorage()
            localStorage.removeItem("customClientUrl");
          } else {
            history(routeConstant.RA_REPORT_LISTING, { state: { data } });
            removeDataFromLocalStorage()
          }
        })
        .catch((err:any) => {
          Sentry.captureException(err)
          let data = {};
          data = { refetchData: true, clientInfo: clientInfo };
          if (param.previousPage == "client") {
            history(routeConstant.CLIENT, { state: { data } });
            removeDataFromLocalStorage()
          } else {
            history(routeConstant.RA_REPORT_LISTING, { state: { data } });
            removeDataFromLocalStorage()
          }
        });
    } else {
      logout();
    }}catch(error){
      showBoundary(error)
    }
  };
  const handleBack = () => {
    if (Cookies.getJSON("ob_session")) {
      try {
        setRaStepper(
          client,
          stepper.VPNConnect.name,
          stepper.VPNConnect.value,
          param
        );
        setActiveFormStep(1);
        let data = {
          editData: true,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          LinuxNetwork:
            param && param.LinuxNetwork ? param.LinuxNetwork : false,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : true,
          clientInfo: clientInfo,
          targetInfo: targetInfo,
          editWindowsData:
            param && param.editWindowsData ? param.editWindowsData : false,
          previousPage: param?.previousPage,
          checkboxSelected: certCheckBox,
          linuxLoginSelected: linuxCredential,
          windowLoginSelected: windowsCredential,
          scanName : param.scanName ?  param.scanName : '',
          targetIpRange : param.targetIpRange ? param.targetIpRange : '',
          subnetMask : param.subnetMask ? param.subnetMask : '',
        };
        history(routeConstant.VPN_CONNECT, { state: { data } });
      } catch (err:any){
        setRaStepper(client, stepper.VPNConnect.name, stepper.VPNConnect.value, param);
        setActiveFormStep(1);
        let data = {
          editData: true,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          LinuxNetwork:
            param && param.LinuxNetwork ? param.LinuxNetwork : false,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : true,
          clientInfo: clientInfo,
          targetInfo: targetInfo,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          previousPage: param?.previousPage,
          checkboxSelected: certCheckBox,
          linuxLoginSelected: linuxCredential,
          windowLoginSelected: windowsCredential,
          scanName : param.scanName ?  param.scanName : '',
          targetIpRange : param.targetIpRange ? param.targetIpRange : '',
          subnetMask : param.subnetMask ? param.subnetMask : '',
        };
        history(routeConstant.VPN_CONNECT, { state: { data } });
      }
    } else {
      logout();
    }
  }
  const handleSkip = () => {
   try{ 
    setTimeout(() => {
      if (param) {
        data = {
          LinuxNetwork: param && param.LinuxNetwork ? param.LinuxNetwork : true,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : false,
          editData: param && param.editData ? param.editData : false,
          clientInfo: param && param.clientInfo,
          targetInfo: targetInfo,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          previousPage: param?.previousPage,
          checkboxSelected: certCheckBox,
          linuxLoginSelected: linuxCredential,
          windowLoginSelected: windowsCredential,
          scanName : param.scanName ?  param.scanName : '',
          targetIpRange : param.targetIpRange ? param.targetIpRange : '',
          subnetMask : param.subnetMask ? param.subnetMask : '',
        };
      }
      history(routeConstant.TASK_DETAILS, { state: { data } });
    }, 500);
  }catch(error){
      showBoundary(error)
    }
  }

  useEffect(() => {
    let timeout:any;
    if(Object.keys(targetDetails).length > 0){
      timeout = setTimeout(() => {
      console.log("InsideTIMEOUTtargetDetails", targetDetails);
        history(routeConstant.TASK_DETAILS, { state: { targetDetails } });
      }, 1000)
    }
    console.log("timeout", timeout);
    return () => {
      console.log("clearTimeout", timeout);
      clearTimeout(timeout);
    }
  },[targetDetails])

  const handleNext = () => {
    try{
    if (targetConnection.linux_test_connection) {
      linuxRef.current.handleSubmitDialogBox();
    } if (targetConnection.windows_test_connection) {
      windowsRef.current.handleSubmitDialogBox();
    }}catch(error){
      showBoundary(error)
    }
  }

  const setLoginSubmitDisabled = (disabled:any) => {
    setSubmitDisabled(disabled);
  }

  return (
    <React.Fragment>
      <CssBaseLine />
      <Typography component="h5" variant="h1">
        Advanced Vulnerability Assessment for{" "}
        {param !== undefined && param.clientInfo !== undefined
          ? param.clientInfo.name
          : null}
      </Typography>
      <RaStepper />
      <Grid item xs={12} className={styles.ActionButtons}>
        <Grid item xs={12} md={3} classes={{ item: styles.GuestCientButton }}>
          {/* <Button
            variant={"contained"}
            onClick={handleCancel}
            id="cancel-btn"
            color="primary"
            data-testid="cancel-button"
          >
            cancel
          </Button> */}
          <div className={styles.crossBtn}>
            <Button
              id="cancel-button"
              variant="contained"
              color="primary"
              onClick={handleCancel}
            >
              {"X"}
            </Button>
            <span className={styles.cancleTooltip}>Cancel</span>
          </div>
        </Grid>
      </Grid>
      {/* {backdrop ? <SimpleBackdrop /> : null} */}
      <Grid container spacing={3}>
        <Grid item xs={2.5} md={2.5}>
          <div style={{ paddingLeft: "7px" }}>
            <FormControlLabel
              control={
                <Checkbox name="windows_login_check" color="primary" checked={windowsCredential} onChange={checkBoxChangeHandler} />
              }
              classes={{ root: styles['MuiFormControlLabel-root'] }}
              label="Windows Network"
            />
            <span style={{ cursor: "pointer" }}>
              <Tooltip
                open={windowLoginToolTip}
                onClose={() => {
                  setWindowLoginToolTip(false)
                }}
                placement="top"
                onOpen={() => {
                  setWindowLoginToolTip(true)
                }}
                title={
                  <React.Fragment>
                    <p>
                      <b>Note:</b>
                      <b>
                        {"Select the checkbox to enter data for Windows Scan."}
                      </b>{" "}
                    </p>
                    {/* {"It's very engaging. Right?"} */}
                  </React.Fragment>
                }
              >
                <ContactSupportIcon className={styles.CircleIcon} />
              </Tooltip>
            </span>
          </div>
        </Grid>
        <Grid item xs={3} md={3}>
          <div style={{ marginLeft: "-27px" }}>
            <FormControlLabel
              control={
                <Checkbox name="linux_check" color="primary" checked={linuxCredential} onChange={checkBoxChangeHandler} />
              }
              classes={{ root: styles['MuiFormControlLabel-root'] }}
              label="Linux Network"
            />
            <span style={{ cursor: "pointer" }}>
              <Tooltip
                open={loginToolTip}
                onClose={() => {
                  setLoginToolTip(false)
                }}
                placement="top"
                onOpen={() => {
                  setLoginToolTip(true)
                }}
                title={
                  <React.Fragment>
                    <p>
                      <b>Note:</b>
                      <b>
                        {"Select the checkbox to enter data for Linux Scan."}
                      </b>{" "}
                    </p>
                    {/* {"It's very engaging. Right?"} */}
                  </React.Fragment>
                }
              >
                <ContactSupportIcon className={styles.CircleIcon} />
              </Tooltip>
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        {windowsCredential &&
          <Grid item xs={6}>
            <Paper className={styles.paper}>
              <Windows_Network
                updateConnection={updateTestConnection}
                sendDetails={setTargetsDetails}
                ref={windowsRef}
                linuxLoginSelected={linuxCredential}
                windowLoginSelected={windowsCredential} 
                ChangeLoginSubmitDisabled={setLoginSubmitDisabled} 
              />
            </Paper>
          </Grid>
        }
        {linuxCredential &&
          <Grid item xs={6}>
            <Paper className={styles.paper}>
              <Linux_Network
                updateConnection={updateTestConnection}
                sendDetails={setTargetsDetails}
                ref={linuxRef}
                linuxLoginSelected={linuxCredential}
                windowLoginSelected={windowsCredential}
                ChangeLoginSubmitDisabled={setLoginSubmitDisabled}
              />
            </Paper>
          </Grid>
        }
      </Grid>
      <Grid item xs={12} className={styles.ActionButtons}>
        <Button
          className={styles.borderLess}
          variant={"contained"}
          id="back-btn"
          onClick={handleBack}
          color="primary"
          data-testid="cancel-button"
        >
          back
        </Button>
        <Button
          onClick={handleNext}
          id="next-btn"
          color="primary"
          variant={"contained"}
          data-testid="ok-button"
          disabled={submitDisabled}
        >
          next
        </Button>
        {param != undefined && (!param.editLinuxData || !param.editWindowsData) ? (
          <Button
            className={styles.borderLess}
            variant={"contained"}
            id="skip-btn"
            onClick={handleSkip}
            color="primary"
            data-testid="cancel-button"
          >
            skip
          </Button>
        ) : null}
      </Grid>
    </React.Fragment>
  )
}
export default LoginCredential;