const envVariables = process.env;
export const RA_URI = envVariables.REACT_APP_RA_URI;
export const RA_REPORT_DOWNLOAD = envVariables.REACT_APP_REPORT_DOWNLOAD;
export const RA_TARGET_VPNTEST = envVariables.REACT_APP_TARGET_VPNTEST;
export const OB_URI = envVariables.REACT_APP_OB_URI;
export const PG_URL = envVariables.REACT_APP_PG_URL;
export const PG_PRICING_API_KEY:any = envVariables.REACT_APP_PG_PRICING_API_KEY;
export const PG_DEDUCTION_API_KEY:any = envVariables.REACT_APP_PG_DEDUCTION_API_KEY;
export const OR_PROJECT_KEY:any = envVariables.REACT_APP_OPENREPLAY_PROJECT_KEY
export const OR_INGESTPOINT:any = envVariables.REACT_APP_OPENREPLAY_INGESTPOINT
export const OR_ENABLE:any = envVariables.REACT_APP_OPENREPLAY_ENABLE === 'true'
export const PARTNER_SUPORT_EMAIL = envVariables.REACT_APP_PARTNER_SUPPORT_EMAIL